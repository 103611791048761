import { createContext, useCallback, useMemo, useState } from 'react';

import { Layout, notification } from 'antd';
import cx from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import 'import-jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import AuthService from '../../../api/auth/service';
import { useEventListener } from '../../../hooks/event-listener';
import { useAuth } from '../../../hooks/useAuth';
import Drawers from '../../containers/Drawers';
import Sidebar from '../../containers/Sidebar';
import ErrorFallback from '../../elements/ErrorFallback';
import AppHeader from './components/AppHeader';
import { RoutePath } from './types';

// views
import AccountSettingsView from '../AccountSettingsView/AccountSettingsView';
import ClientsView from '../ClientsView/ClientsView';
import ClientView from '../ClientView/ClientView';
import CustomFieldsView from '../CustomFieldsView';
import DashboardView from '../DashboardView';
import ForgotPasswordView from '../ForgotPasswordView';
import LogInView from '../LogInView';
import ManualView from '../ManualView';
import MasterLibrarianListView from '../MasterLibrarianListView';
import MetaDataView from '../MetaDataView';
import OnlineVirtualResearchDetailsView from '../OnlineVirtualResearchDetailsView';
import OnlineVirtualResearchView from '../OnlineVirtualResearchView';
import OVRFileManagement from '../OVRFileManagmentView';
import PageNotFoundView from '../PageNotFoundView';
import PlanogramView from '../PlanogramView/PlanogramView';
import POGCheckerView from '../POGCheckerView';
import CurrentImageView from '../ProductDetailsView/CurrentImageView';
import ProductDetailsView from '../ProductDetailsView/ProductDetailsView';
import ProductLibraryView from '../ProductLibraryView';
import ProductListView from '../ProductListView';
import RolesView from '../RolesView';
import StatusView from '../StatusView';
import StoreBuilderDetailsView from '../StoreBuilderDetailsView';
import StoreBuilderView from '../StoreBuilderView';
import SystemConfigurationsView from '../SystemConfigurationsView';
import UploadModelsView from '../UploadModelsView';
import UserView from '../UserDetailsView';
import UsersView from '../UsersView';

import './AppRoot.less';

const { Content } = Layout;

export const SIDEBAR_POSITION_KEY = 'SIDEBAR_POSITION';

export const AppLayoutContext = createContext<
  Partial<{ sidebarCollapsed: boolean }>
>({});

const AuthRoute = ({
  component: Component,
  ...props
}: {
  [key: string]: any;
}) => {
  const { isAuthenticated } = useAuth();

  window.scrollTo(0, 0);

  return (
    <Route
      {...props}
      render={(props: { [key: string]: any }) => {
        if (isAuthenticated) {
          return <Component {...props} {...props.componentProps} />;
        }
        return <Redirect to={RoutePath.LogIn} />;
      }}
    />
  );
};

const AppRoot = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    localStorage.getItem(SIDEBAR_POSITION_KEY) === 'true' || false
  );
  const { isAuthenticated } = useAuth();

  const reactRouterLocation = useLocation();

  const isPublicView = [RoutePath.LogIn, RoutePath.ForgotPassword].includes(
    reactRouterLocation.pathname as RoutePath
  );

  useEventListener('offline', () => {
    notification.close('error-offline-notification');
    notification.close('online-notification');

    notification['error']({
      message: 'No internet connection detected.',
      description:
        'This site does not have offline support. To continue using the site, please reconnect to the internet and refresh the page.',
      duration: 0,
      key: 'error-offline-notification',
    });
  });

  useEventListener('online', () => {
    notification.close('error-offline-notification');
    notification.close('online-notification');

    notification['success']({
      message: 'Internet connection detected.',
      description: 'You are now back online!',
      duration: 3,
      key: 'online-notification',
    });
  });

  const updateSidebarPosition = useCallback(() => {
    setSidebarCollapsed((prev) => {
      const newValue = !prev;
      localStorage.setItem(SIDEBAR_POSITION_KEY, `${newValue}`);
      return newValue;
    });
  }, []);

  const siteContentClass = useMemo(
    () =>
      cx(
        'site-content',
        { 'full-screen': !isAuthenticated || isPublicView },
        { collapsed: sidebarCollapsed }
      ),
    [isAuthenticated, isPublicView, sidebarCollapsed]
  );

  const handleErrorReset = useCallback(() => {
    AuthService().logout();
  }, []);

  const contextValue = useMemo(
    () => ({ sidebarCollapsed }),
    [sidebarCollapsed]
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={handleErrorReset}>
      <>
        <Layout className="app-layout">
          <AppLayoutContext.Provider value={contextValue}>
            {isAuthenticated && !isPublicView && (
              <Sidebar onToggle={updateSidebarPosition} />
            )}

            <Layout className="site-content-wrapper">
              {isAuthenticated && !isPublicView && (
                <AppHeader onToggleSidebar={updateSidebarPosition} />
              )}

              <Content className={siteContentClass}>
                <Switch>
                  <Route exact path="/">
                    {isAuthenticated ? (
                      <Redirect to={RoutePath.Dashboard} />
                    ) : (
                      <Redirect to={RoutePath.LogIn} />
                    )}
                  </Route>
                  <Route exact path={RoutePath.LogIn} component={LogInView} />
                  <Route
                    exact
                    path={RoutePath.ForgotPassword}
                    component={ForgotPasswordView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.Dashboard}
                    component={DashboardView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.StoreBuilder}
                    component={StoreBuilderView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.StoreBuilderDetails}
                    component={StoreBuilderDetailsView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.OVR}
                    component={OnlineVirtualResearchView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.OVRDetails}
                    component={OnlineVirtualResearchDetailsView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.OvrFileManagement}
                    component={OVRFileManagement}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.ProductLibrary}
                    component={ProductLibraryView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.Planogram}
                    component={PlanogramView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.ProductList}
                    component={ProductListView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.MasterLibrarianList}
                    component={MasterLibrarianListView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.ProductDetails}
                    component={ProductDetailsView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.ProductDetailsImage}
                    component={CurrentImageView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.UploadModels}
                    component={UploadModelsView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.POGChecker}
                    component={POGCheckerView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.MetaData}
                    component={MetaDataView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.Status}
                    component={StatusView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.Clients}
                    component={ClientsView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.CustomFields}
                    component={CustomFieldsView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.Manual}
                    component={ManualView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.Users}
                    component={UsersView}
                  />
                  <AuthRoute exact path={RoutePath.User} component={UserView} />
                  <AuthRoute
                    exact
                    path={RoutePath.AccountSettings}
                    component={AccountSettingsView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.Client}
                    component={ClientView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.SystemConfigurations}
                    component={SystemConfigurationsView}
                  />
                  <AuthRoute
                    exact
                    path={RoutePath.Roles}
                    component={RolesView}
                  />
                  <Route component={PageNotFoundView} />
                </Switch>
              </Content>
            </Layout>
          </AppLayoutContext.Provider>
        </Layout>
        <Drawers />
      </>
    </ErrorBoundary>
  );
};

export default AppRoot;
