import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { BackTop, Badge, Empty, Input, Space, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './SystemConfigurationsView.less';

import { PaginationModel } from '../../../api/base';
import { ListSystemConfigurationsParams } from '../../../hooks/system-configuration';
import { useAuth } from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';
import { SystemConfigurationModel } from '../../../models/system-configuration';
import {
  fetchSystemConfigurations,
  getAllSystemsConfigurations,
  getFetchingSystemConfigurations,
  getPagination,
  getSearchingSystemConfigurationsByName,
  getSystemConfigurations,
  getTotalCount,
  loadMoreSystemConfigurations,
  searchSystemConfigurationsByName,
} from '../../../store/features/systemConfigurations/systemConfigurationsSlice';
import { RootState, useAppDispatch } from '../../../store/index';
import { UserPermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import Button from '../../elements/Button';
import NoPermission from '../../elements/NoPermission';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import SystemConfigurationTableList from './components/SystemConfigurationTableList';

const { Title } = Typography;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const SystemConfigurationsView = () => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const systemConfigurations = useSelector<RootState>(
    getSystemConfigurations
  ) as SystemConfigurationModel[];
  const fetchingSystemConfigurations = useSelector<RootState>(
    getFetchingSystemConfigurations
  ) as boolean;
  const totalCount = useSelector<RootState>(getTotalCount) as number;
  const pagination = useSelector<RootState>(getPagination) as PaginationModel;
  const searchingSystemConfigurations = useSelector<RootState>(
    getSearchingSystemConfigurationsByName
  ) as boolean;
  const allSystemConfigurations = useSelector<RootState>(
    getAllSystemsConfigurations
  ) as SystemConfigurationModel[];

  const { hasPermission } = useAuth();

  const hasNoSearchResultsFound = useMemo(
    () =>
      !fetchingSystemConfigurations &&
      !!searchQuery &&
      systemConfigurations.length === 0,
    [fetchingSystemConfigurations, searchQuery, systemConfigurations.length]
  );

  const hasSystemConfigurations = !(
    !fetchingSystemConfigurations && allSystemConfigurations.length === 0
  );

  const hasMoreSystemConfigurations =
    hasSystemConfigurations &&
    pagination?.offset! + pagination?.offset! < totalCount!;

  const isPermittedToLoadMore =
    !fetchingSystemConfigurations &&
    !searchingSystemConfigurations &&
    !searchQuery &&
    hasMoreSystemConfigurations;

  const listSystemConfigurationsParams = useMemo(
    (): ListSystemConfigurationsParams => ({
      _limit: 20,
      _order_by: 'updated_at:desc',
      _offset: 0,
      _batch: 1,
    }),
    []
  );

  const canCreateSystemConfiguration = useMemo(
    () => hasPermission(UserPermissions.SystemConfigurationCreate),
    [hasPermission]
  );

  useEffect(() => {
    if (hasPermission(UserPermissions.SystemConfigurationView)) {
      dispatch(
        fetchSystemConfigurations({
          params: listSystemConfigurationsParams,
        })
      );
    }
  }, [dispatch, listSystemConfigurationsParams, hasPermission]);

  const createNewSystemConfigurationButton = useMemo(
    (): JSX.Element => (
      <Link to={DrawerHashRoute.SystemConfigurationForm}>
        <Button type="primary" data-cy="create-new-system-configuration-btn">
          Create New System Configuration
          <PlusOutlined />
        </Button>
      </Link>
    ),
    []
  );

  useEffect(() => {
    dispatch(searchSystemConfigurationsByName(debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const headerSectionRight = useMemo(
    (): JSX.Element => (
      <Space direction="horizontal" size="middle">
        <Input
          allowClear={!searchingSystemConfigurations}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          placeholder="Search for a configuration..."
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          suffix={
            searchingSystemConfigurations && <Spin indicator={antSpinIcon} />
          }
        />
        {canCreateSystemConfiguration && createNewSystemConfigurationButton}
      </Space>
    ),
    [
      createNewSystemConfigurationButton,
      searchQuery,
      searchingSystemConfigurations,
      canCreateSystemConfiguration,
      updateSearchQuery,
    ]
  );

  if (!hasPermission(UserPermissions.SystemConfigurationView)) {
    return <NoPermission />;
  }

  return (
    <ViewWrapper
      headerTitle={
        <Space direction="horizontal">
          <Title level={3}>System Configurations</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
          />
        </Space>
      }
      headerSectionRight={hasSystemConfigurations && headerSectionRight}
    >
      {hasSystemConfigurations ? (
        hasNoSearchResultsFound ? (
          <NoResultsFound
            searchQuery={searchQuery}
            onClear={() => updateSearchQuery('')}
          />
        ) : (
          <SystemConfigurationTableList
            loading={fetchingSystemConfigurations}
            systemConfigurations={systemConfigurations}
            onLoadMore={() => dispatch(loadMoreSystemConfigurations())}
            hasMore={isPermittedToLoadMore}
          />
        )
      ) : (
        <Empty
          description="Currently no system configurations."
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
          {canCreateSystemConfiguration && createNewSystemConfigurationButton}
        </Empty>
      )}

      <BackTop />
    </ViewWrapper>
  );
};

export default SystemConfigurationsView;
