import { Button, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useDebounce from '../../../../../hooks/useDebounce';
import { TagModel } from '../../../../../models/tag';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateTags,
  searchTagsByName,
} from '../../../../../store/features/metaData/metaDataSlice';
import { UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';
import SearchBar from '../../../../elements/SearchBar/SearchBar';
import { RoutePath } from '../../../AppRoot/types';

import { SortOrder } from 'antd/es/table/interface';

import { ProductStatusEnum } from '../../../../../models/product-library';
import { formatDate } from '../../../../../util/time-from-now';

import { useAuth } from '../../../../../hooks/useAuth';
import { fetchTags } from '../../../../../store/features/metaData/metaDataSlice';

const { Text } = Typography;

const TagsTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const { allTags, fetchingTags, searchingTags, count, currentPage, pageSize } =
    useAppSelector((state) => state.metaData);

  const { hasPermission } = useAuth();

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingTags && !!searchQuery && allTags.length === 0,
    [fetchingTags, searchQuery, allTags.length]
  );

  const hasTags = !(!fetchingTags && allTags.length === 0);

  const columns: ColumnsType<TagModel> = useMemo(
    () =>
      getColumns(history, hasPermission).map((column) => ({
        ...column,
        defaultSortOrder: column.defaultSortOrder as SortOrder | undefined,
      })),
    [history, hasPermission]
  );

  useEffect(() => {
    dispatch(fetchTags());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      searchTagsByName({
        query: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      dispatch(
        paginateTags({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      );
    },
    [debouncedSearchQuery, dispatch]
  );

  const paginationSettings = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current: currentPage,
    pageSize: pageSize,
  };

  return (
    <div>
      <SearchBar
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        isSearching={searchingTags}
        canCreate={hasPermission(UserPermissions.MetaDataCreate)}
        createLink={DrawerHashRoute.TagForm}
        createButtonText="Add New Tag"
        placeholder="Search Tags"
      />
      {hasTags && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={allTags}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={paginationSettings}
          loading={fetchingTags || searchingTags}
        />
      )}
    </div>
  );
};

const getColumns = (
  history: any,
  hasPermission: (permission: UserPermissions | UserPermissions[]) => boolean
) => [
  {
    title: 'Tag',
    dataIndex: 'name',
    width: '35%',
    sorter: (a: TagModel, b: TagModel) => (a.name > b.name ? 1 : -1),
  },
  {
    title: '# in Processing',
    dataIndex: 'in_processing',
    defaultSortOrder: 'descend',
    sorter: (a: TagModel, b: TagModel) =>
      (a.in_processing || 0) - (b.in_processing || 0),
  },
  {
    title: '# in Library',
    dataIndex: 'in_library',
    defaultSortOrder: 'descend',
    sorter: (a: TagModel, b: TagModel) =>
      (a.in_library || 0) - (b.in_library || 0),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    defaultSortOrder: 'descend',
    sorter: (a: TagModel, b: TagModel) =>
      a.status && b.status ? (a.status > b.status ? 1 : -1) : 0,
    render: (status: ProductStatusEnum) => (
      <Text
        type={
          status === ProductStatusEnum.needs_approval ? 'danger' : undefined
        }
      >
        {status === ProductStatusEnum.approved ? status : 'Needs Approval'}
      </Text>
    ),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    defaultSortOrder: 'descend',
    sorter: (a: TagModel, b: TagModel) =>
      new Date(a.created_at ?? 0).getTime() -
      new Date(b.created_at ?? 0).getTime(),
    render: (createdAt: string) => formatDate(createdAt),
  },
  {
    title: 'Actions',
    width: 100,
    render: (tag: TagModel) => {
      return (
        <Space direction="horizontal" size="middle">
          {hasPermission(UserPermissions.MetaDataEdit) && (
            <Button
              type="link"
              className="link-button"
              onClick={() =>
                history.push({
                  hash: DrawerHashRoute.TagForm,
                  state: { data: tag },
                })
              }
            >
              Edit
            </Button>
          )}
          <Button
            className="link-button"
            type="link"
            onClick={() =>
              history.push({
                state: { tag },
                pathname: RoutePath.ProductLibrary,
              })
            }
          >
            View in Library
          </Button>
        </Space>
      );
    },
  },
];

export default TagsTab;
