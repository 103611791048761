import React, {
  memo,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import './AppHeader.less';

import { MenuOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Badge, Dropdown, Layout, Menu, Tooltip, Typography } from 'antd';
import cx from 'classnames';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { ReactComponent as AllMarketsFlagIcon } from '../../../../../assets/svgs/all-markets-flag-icon.svg';
import { ReactComponent as AmericaFlagIcon } from '../../../../../assets/svgs/america-flag-icon.svg';
import { ReactComponent as CanadaFlagIcon } from '../../../../../assets/svgs/canada-flag-icon.svg';
import { ReactComponent as MasterListIcon } from '../../../../../assets/svgs/master-list-icon.svg';
import { ReactComponent as ProductListIcon } from '../../../../../assets/svgs/product-list-icon.svg';
import ReadySetIcon from '../../../../../assets/svgs/readyset-icon.svg';
import { useAuth } from '../../../../../hooks/useAuth';
import { useAppSelector } from '../../../../../store';
import { fetchCustomField } from '../../../../../store/features/customFields/customFieldsSlice';
import { fetchMasterLibrarianListCount } from '../../../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import { setSelectedMarket } from '../../../../../store/features/productLibrary/productLibrarySlice';
import { fetchProductListCount } from '../../../../../store/features/productList/productListSlice';
import { useAppDispatch } from '../../../../../store/index';
import {
  isTabletScreenSizeOrSmaller,
  propsAreEqual,
  UserPermissions,
} from '../../../../../util';
import Button from '../../../../elements/Button';
import { AppLayoutContext } from '../../AppRoot';
import { RoutePath } from '../../types';

const { Header } = Layout;
const { Title } = Typography;
interface AppHeaderProps extends RouteComponentProps {
  onToggleSidebar: () => void;
}

const PRODUCT_RELATED_ROUTES = [
  'Product Library',
  'Product List',
  'Master Librarian List',
  'Upload product model(s)',
  'POG Checker',
  'Product Library Meta Data',
  'Replacement Data',
  'Status',
] as const;

const MARKET_ICONS: Record<number, ReactElement> = {
  74: <AllMarketsFlagIcon />,
  66: <AmericaFlagIcon />,
  230: <CanadaFlagIcon />,
};

const getMarketIcon = (marketId: number, markets: any[] = []) => {
  if (MARKET_ICONS[marketId]) {
    return MARKET_ICONS[marketId];
  }

  const market = markets.find((m) => m.id === marketId);
  if (market?.media_files?.[0]?.media_url) {
    return (
      <img
        src={market.media_files[0].media_url}
        alt={market.name}
        style={{ width: '34px', height: '24px', objectFit: 'contain' }}
      />
    );
  }

  return <AllMarketsFlagIcon />;
};

const getHeaderTitleFromPath = (pathname: string): string => {
  const accountSettingsPathPrefix = '/account-settings/';

  const routeTitleMap: Record<string, string> = {
    [RoutePath.Dashboard]: 'Home',
    [RoutePath.OVR]: 'Online Virtual Research',
    [RoutePath.StoreBuilder]: 'Front End Builder',
    [RoutePath.Users]: 'Users',
    [RoutePath.User]: 'User Information',
    [RoutePath.Clients]: 'Clients',
    [RoutePath.Client]: 'Client',
    [RoutePath.CustomFields]: 'Custom Fields',
    [RoutePath.Manual]: 'Manual / Videos',
    [RoutePath.SystemConfigurations]: 'System Configurations',
    [RoutePath.OvrFileManagement]: 'OVR File Management',
    [RoutePath.ProductLibrary]: 'Product Library',
    [RoutePath.ProductList]: 'Product List',
    [RoutePath.MasterLibrarianList]: 'Master Librarian List',
    [RoutePath.UploadModels]: 'Upload product model(s)',
    [RoutePath.POGChecker]: 'POG Checker',
    [RoutePath.MetaData]: 'Product Library Meta Data',
    [RoutePath.ReplacementData]: 'Replacement Data',
    [RoutePath.Roles]: 'Roles',
    [RoutePath.Status]: 'Product Library Status',
  };

  if (pathname.startsWith(accountSettingsPathPrefix)) {
    return 'Account Settings';
  }

  return (
    Object.entries(routeTitleMap).find(([route]) =>
      pathname.includes(route)
    )?.[1] ?? ''
  );
};

const AppHeader = ({ location, onToggleSidebar }: AppHeaderProps) => {
  const dispatch = useAppDispatch();
  const { selectedMarket } = useAppSelector((state) => state.productLibrary);
  const { customField: markets } = useAppSelector(
    (state) => state.customFields
  );
  const { count: productListCount } = useAppSelector(
    (state) => state.productList
  );
  const { totalCount: masterLibrarianListCount } = useAppSelector(
    (state) => state.masterLibrarianList
  );

  const { sidebarCollapsed } = useContext(AppLayoutContext);

  const { hasPermission } = useAuth();

  const isTablet = useMemo(() => isTabletScreenSizeOrSmaller(), []);

  const headerTitle = useMemo(
    () => getHeaderTitleFromPath(location.pathname),
    [location.pathname]
  );

  const canViewUnapproved = hasPermission(UserPermissions.ModelViewUnapproved);

  const siteHeaderClass = useMemo(
    () => cx('site-header', { collapsed: sidebarCollapsed }),
    [sidebarCollapsed]
  );

  const showProductControls = useMemo(
    () => PRODUCT_RELATED_ROUTES.includes(headerTitle as any),
    [headerTitle]
  );

  const handleMarketSelect: MenuProps['onClick'] = useCallback(
    async (e: { key: string }) => {
      const marketId = parseInt(e?.key, 10);
      await dispatch(setSelectedMarket(marketId));
    },
    [dispatch]
  );

  const getMarketDisplayName = useCallback((option: any) => {
    if (option.id === 74) {
      return 'All Markets';
    }
    return option?.name;
  }, []);

  const marketDropdownMenu = useMemo(
    () => (
      <Menu onClick={handleMarketSelect}>
        <Menu.ItemGroup title="Market Views">
          <Menu.Item key="74">
            <div className="market-options">
              {getMarketIcon(74, markets?.options)}
              All Markets (Combined View)
            </div>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Individual Markets">
          {markets?.options
            .filter((option) => option.id !== 74 && option.name !== 'Undefined')
            .map((option) => (
              <Menu.Item key={option?.id}>
                <div className="market-options">
                  {getMarketIcon(option.id!, markets?.options)}
                  {getMarketDisplayName(option)}
                </div>
              </Menu.Item>
            ))}
        </Menu.ItemGroup>
      </Menu>
    ),
    [handleMarketSelect, markets, getMarketDisplayName]
  );

  const isProductRelatedRoute = useMemo(
    () =>
      [
        RoutePath.ProductLibrary,
        RoutePath.ProductList,
        RoutePath.UploadModels,
      ].some((route) => location.pathname.includes(route)),
    [location.pathname]
  );

  useEffect(() => {
    if (!hasPermission(UserPermissions.CustomFieldsView)) return;
    dispatch(fetchCustomField('market'));
  }, [dispatch, hasPermission, location.pathname]);

  useEffect(() => {
    if (!isProductRelatedRoute || !canViewUnapproved) return;
    dispatch(fetchMasterLibrarianListCount());
  }, [dispatch, isProductRelatedRoute, canViewUnapproved]);

  useEffect(() => {
    if (!isProductRelatedRoute) return;
    dispatch(fetchProductListCount());
  }, [dispatch, isProductRelatedRoute]);

  const renderProductControls = () => (
    <div className="header-section-right-btn-container">
      {canViewUnapproved && (
        <Tooltip title="Master Librarian List">
          <Badge
            count={masterLibrarianListCount}
            overflowCount={99999}
            offset={[10, 30]}
            color="#EA088B"
          >
            <Link to="/master-librarian-list">
              <Button
                icon={<MasterListIcon />}
                className="header-section-right-button"
              />
            </Link>
          </Badge>
        </Tooltip>
      )}
      <Tooltip title="Product List">
        <Badge
          count={productListCount}
          overflowCount={99999}
          offset={[10, 30]}
          color="#8E24AA"
        >
          <Link to="/product-list">
            <Button
              icon={<ProductListIcon />}
              className="header-section-right-button"
            />
          </Link>
        </Badge>
      </Tooltip>
      <Dropdown
        placement="bottomRight"
        overlay={marketDropdownMenu}
        trigger={['click']}
      >
        <Button
          icon={React.cloneElement(
            getMarketIcon(selectedMarket, markets?.options),
            {
              style: { width: '34px', height: '24px' },
            }
          )}
          className="market-button header-section-right-button"
        />
      </Dropdown>
    </div>
  );

  if (location.pathname.includes('/planogram')) {
    return null;
  }

  return (
    <Header className={siteHeaderClass}>
      <div className="header-wrapper">
        <div className="header-wrapper__section-left">
          {isTablet && (
            <img
              src={ReadySetIcon}
              alt="ReadySet icon"
              className="readyset-icon"
            />
          )}
          <Title level={4} data-cy="view-header-title">
            {headerTitle}
          </Title>
        </div>
        <div className="header-wrapper__section-right">
          {showProductControls && renderProductControls()}
          {isTablet && (
            <MenuOutlined
              className="toggle-menu-icon"
              onClick={onToggleSidebar}
            />
          )}
        </div>
      </div>
    </Header>
  );
};

export default memo(withRouter(AppHeader), propsAreEqual);
