import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Drawer } from 'antd';
import { History } from 'history';
import { withRouter } from 'react-router-dom';
import './Drawers.less';

import { useLockBodyScroll } from '../../../hooks/useBlockScroll';
import {
  isMobileScreenSize,
  isTabletScreenSizeOrSmaller,
  propsAreEqual,
} from '../../../util';
import BrandForm from '../forms/BrandForm';
import BulkEditProductsForm from '../forms/BulkEditProductsForm';
import CategoryForm from '../forms/CategoryForm';
import ClientForm from '../forms/ClientForm';
import CountUnitForm from '../forms/CountUnitForm';
import CustomFieldForm from '../forms/CustomFieldForm';
import CustomFileForm from '../forms/CustomFileForm';
import EmailNotificationForm from '../forms/EmailNotificationForm/EmailNotificationForm';
import FormatForm from '../forms/FormatForm';
import ManufacturerForm from '../forms/ManufacturerForm';
import MarketForm from '../forms/MarketForm';
import OVRForm from '../forms/OVRForm';
import ParentCompanyForm from '../forms/ParentCompanyForm';
import RSGFileForm from '../forms/RSGFileForm';
import RoleForm from '../forms/RoleForm';
import SizeUnitForm from '../forms/SizeUnitForm';
import StoreBuildForm from '../forms/StoreBuildForm';
import SystemConfigurationForm from '../forms/SystemConfigurationForm';
import TagForm from '../forms/TagForm';
import UserForm from '../forms/UserForm';
import { DrawerHashRoute } from './types';
interface DrawersProps {
  history: History;
}

const Drawers: FC<DrawersProps> = (props: DrawersProps) => {
  const { history } = props;

  const { lockBodyScroll, unlockBodyScroll } = useLockBodyScroll();

  const drawerContentEl = useRef(null);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const isMobile = useMemo(() => isMobileScreenSize(), []);
  const isTablet = useMemo(() => isTabletScreenSizeOrSmaller(), []);

  useEffect(() => {
    const hasDrawerContent = !!getDrawerContent();

    if (hasDrawerContent) {
      lockBodyScroll();
      setShowDrawer(true);
      return;
    }

    unlockBodyScroll();
    setShowDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.hash]);

  const getWidth = useCallback((): string => {
    // Adding extra width for RoleForm
    if (history.location.hash === DrawerHashRoute.RoleForm) {
      switch (true) {
        case isMobile:
          return '100%';
        case isTablet:
          return '85%';
        default:
          return '50%';
      }
    }

    // Default widths for other forms
    switch (true) {
      case isMobile:
        return '100%';
      case isTablet:
        return '70%';
      default:
        return '40%';
    }
  }, [isMobile, isTablet, history.location.hash]);

  const getDrawerContent = useCallback((): JSX.Element | null => {
    switch (history.location.hash) {
      case DrawerHashRoute.BuildForm:
        return <StoreBuildForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.OVRForm:
        return <OVRForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.UserForm:
        return <UserForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.CustomFieldForm:
        return <CustomFieldForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.SystemConfigurationForm:
        return <SystemConfigurationForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.CustomFileForm:
        return <CustomFileForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.RSGFileForm:
        return <RSGFileForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.BulkEditProductsForm:
        return <BulkEditProductsForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.RoleForm:
        return <RoleForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.CategoryForm:
        return <CategoryForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.TagForm:
        return <TagForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.FormatForm:
        return <FormatForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.MarketForm:
        return <MarketForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.BrandForm:
        return <BrandForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.ManufacturerForm:
        return <ManufacturerForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.ParentCompanyForm:
        return <ParentCompanyForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.CountUnitForm:
        return <CountUnitForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.SizeUnitForm:
        return <SizeUnitForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.ClientForm:
        return <ClientForm forwardedRef={drawerContentEl} />;
      case DrawerHashRoute.EmailNotificationForm:
        return <EmailNotificationForm forwardedRef={drawerContentEl} />;
      default:
        return null;
    }
  }, [history.location.hash]);

  const focusOnFirstInput = useCallback(() => {
    const forms = document.querySelectorAll('form');
    forms[forms.length - 1]?.querySelector('input')?.focus();
  }, []);

  return (
    <Drawer
      afterVisibleChange={focusOnFirstInput}
      closable={false}
      className="drawer"
      width={getWidth()}
      onClose={() => history.goBack()}
      placement="right"
      visible={showDrawer}
    >
      {getDrawerContent()}
    </Drawer>
  );
};

export default withRouter(memo<DrawersProps>(Drawers, propsAreEqual));
