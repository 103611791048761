import React, { memo, useCallback, useMemo, useState } from 'react';

import { Badge, Button, ConfigProvider, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Link, withRouter } from 'react-router-dom';

import { ListProductLibraryParams } from '../../../../hooks/product-library';
import { useProductListOperations } from '../../../../hooks/product-library/useProductListOperations';
import { useAuth } from '../../../../hooks/useAuth';
import { ListProductLibraryModel } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  fetchProductList,
  paginateProductList,
  setSelectedProducts,
} from '../../../../store/features/productList/productListSlice';
import { propsAreEqual } from '../../../../util';
import { DrawerHashRoute } from '../../../containers/Drawers/types';
import { customizeRenderEmpty } from '../../../elements/CustomizeEmptyRender/customizeRenderEmpty';
import './ProductListTable.less';

const { Title } = Typography;

interface ProductListTableProps {
  dataSource: ListProductLibraryModel[];
  headerTitle: string;
  columns: ColumnsType<ListProductLibraryModel>;
  noDataDescription?: string;
  tableHeight?: string;
  hasFooterDeleteButton?: boolean;
  hasFooterBulkEditButton?: boolean;
}

const ProductListTable = ({
  dataSource,
  headerTitle,
  columns,
  noDataDescription,
  tableHeight,
  hasFooterDeleteButton,
  hasFooterBulkEditButton,
}: ProductListTableProps) => {
  const [selectedProductRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    []
  );

  const dispatch = useAppDispatch();

  const {
    fetchingProductListItems,
    count,
    selectedProducts,
    searchQuery,
    currentPage,
    pageSize,
  } = useAppSelector((state) => state.productList);

  const { isRemovingProducts, handleRemoveMultipleProducts } =
    useProductListOperations();

  const { userRoles } = useAuth();
  const isClientUser = userRoles.some((role) => role.name === 'client');

  const hasSelected = selectedProductRowKeys.length > 0;

  const header = useMemo(
    (): JSX.Element | null =>
      headerTitle ? (
        <div className="products-table-header">
          <Title level={3}>{headerTitle}</Title>
          <Badge size="default" count={count} className="total-count-badge" />
        </div>
      ) : null,
    [headerTitle, count]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const handleRemoveFromProductList = async (
    selectedProducts: ListProductLibraryModel[]
  ) => {
    await handleRemoveMultipleProducts(selectedProducts);
    setSelectedRowKeys([]);

    const params: ListProductLibraryParams = {
      _limit: pageSize,
      _offset: (currentPage - 1) * pageSize,
      _order_by: 'updated_at:desc',
    };

    if (searchQuery) {
      params.q = searchQuery.toLowerCase();
    }

    dispatch(fetchProductList({ params }));
  };

  const handleOnChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: ListProductLibraryModel[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedProducts(selectedRows));
  };

  const rowSelection: TableRowSelection<ListProductLibraryModel> = {
    selectedRowKeys: selectedProductRowKeys,
    onChange: handleOnChange,
    preserveSelectedRowKeys: true,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const tableFooter = () => (
    <div className="table-footer-wrapper">
      <div className="table-footer-left">
        <span>
          {hasSelected
            ? `${selectedProductRowKeys.length} ${
                selectedProductRowKeys.length === 1 ? 'Item' : 'Items'
              } selected: `
            : ''}
        </span>
        <Space size="middle">
          {hasFooterDeleteButton && (
            <Button
              type="primary"
              onClick={() => handleRemoveFromProductList(selectedProducts)}
              loading={isRemovingProducts}
              style={{ minWidth: '140px' }}
            >
              {isRemovingProducts ? '' : 'Remove from List'}
            </Button>
          )}

          {hasFooterBulkEditButton && !isClientUser && (
            <Link to={DrawerHashRoute.BulkEditProductsForm}>
              <Button type="primary">Bulk Edit</Button>
            </Link>
          )}
        </Space>
      </div>
      <div className="table-footer-right">
        <Button
          type="link"
          onClick={handleClearSelection}
          className="link-button"
        >
          Clear Selection
        </Button>
      </div>
    </div>
  );

  const footerContent = hasSelected ? tableFooter : undefined;

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      dispatch(
        paginateProductList({
          page,
          pageSize,
          queryStr: searchQuery,
        })
      );
    },
    [dispatch, searchQuery]
  );

  const paginationSettings = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current: currentPage,
    pageSize: pageSize,
  };

  const loading = useMemo(
    () => fetchingProductListItems || isRemovingProducts,
    [fetchingProductListItems, isRemovingProducts]
  );

  return (
    <div className="products-table-list-wrapper">
      {header}
      <ConfigProvider
        renderEmpty={() => customizeRenderEmpty(noDataDescription)}
      >
        <Table
          className="products-table-list"
          footer={footerContent}
          scroll={{
            y: tableHeight || 240,
            scrollToFirstRowOnChange: false,
          }}
          rowSelection={rowSelection}
          loading={loading}
          rowKey={(product: ListProductLibraryModel) =>
            `${product.uuid}-row-key`
          }
          pagination={paginationSettings}
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: customizeRenderEmpty(noDataDescription) }}
        />
      </ConfigProvider>
    </div>
  );
};

export default withRouter<any, any>(memo(ProductListTable, propsAreEqual));
