import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { ApiError, handleError, PaginationModel } from '../../../api/base';
import BrandService from '../../../api/brand';
import { ListProductLibraryParams } from '../../../hooks/product-library';
import { BrandModel } from '../../../models/brand';
import { convertParamsToQuery } from '../../../util';

export interface BrandState {
  value: BrandModel[] | [];
  allBrands: BrandModel[];
  allBrandsValue: BrandModel[];
  fetchingBrands: boolean;
  searchingBrands: boolean;
  deletingBrand: boolean;
  savingBrand: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  totalCount: number;
  pagination: PaginationModel | null;
  current: number;
  currentPage: number;
  pageSize: number;
}
interface UpdateBrandProps {
  brandId: string;
  brand: BrandModel;
}
interface CreateBrandProps {
  brand: BrandModel;
}

const initialState: BrandState = {
  value: [],
  allBrands: [],
  allBrandsValue: [],
  fetchingBrands: false,
  searchingBrands: false,
  deletingBrand: false,
  savingBrand: false,
  error: { code: null, message: null },
  totalCount: 0,
  current: 1,
  pagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
  currentPage: 1,
  pageSize: 10,
};

export interface BrandsParams {
  _limit?: number;
  _order_by?: string;
  _offset?: number;
  _columns?: string;
  name?: string;
  q?: string;
  status?: string;
}

export const brandsParams = {
  params: {
    _limit: 20,
    _order_by: 'updated_at:desc',
    _offset: 0,
  },
};

export const createBrand = createAsyncThunk(
  'brand/createBrand',
  async ({ brand }: CreateBrandProps, { dispatch, rejectWithValue }) => {
    try {
      const resp = await BrandService().createBrand(brand);

      await dispatch(fetchBrands(brandsParams));
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateBrand = createAsyncThunk(
  'brand/updateBrand',
  async (
    { brandId, brand }: UpdateBrandProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await BrandService().updateBrand(brandId, brand);

      await dispatch(fetchBrands(brandsParams));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBrand = createAsyncThunk(
  'brand/deleteBrand',
  async ({ brandId }: { brandId: string }, { dispatch, rejectWithValue }) => {
    try {
      const resp = await BrandService().deleteBrand(brandId);

      await dispatch(fetchBrands(brandsParams));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBrands = createAsyncThunk(
  'brand/fetchBrands',
  async (
    {
      params,
      searching = false,
    }: {
      params: BrandsParams;
      searching?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);
      const resp = await BrandService().listBrands(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchBrandsByName = createAsyncThunk(
  'brand/searchBrandsByName',
  async (query: string, { getState, rejectWithValue }) => {
    try {
      const {
        brand: { pagination },
      } = getState() as RootState;

      let params: BrandsParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _columns: 'manufacturers.*',
        _order_by: 'updated_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await BrandService().listBrands(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateBrands = createAsyncThunk(
  'brand/paginateBrands',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: BrandsParams = {
        _limit: limit,
        _offset: nextOffset,
        _columns: 'manufacturers.*',
        _order_by: 'updated_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          q: `${queryStr.toLowerCase()}`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await BrandService().listBrands(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreBrands = createAsyncThunk(
  'brand/loadMoreBrands',
  async (
    { hasMoreBrands }: { hasMoreBrands?: boolean },
    { getState, rejectWithValue }
  ) => {
    try {
      if (!hasMoreBrands) return;

      const {
        brand: { pagination },
      } = getState() as RootState;

      const limit = pagination?.limit!;
      const offset = pagination?.offset!;
      const nextOffset = limit + offset;

      const nextParams: ListProductLibraryParams = {
        _limit: limit,
        _offset: nextOffset,
        _columns: 'manufacturers.*',
        _order_by: 'name:asc',
        status: 'approved',
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await BrandService().listBrands(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // CREATE BRAND
      .addCase(createBrand.pending, (state) => {
        state.savingBrand = true;
      })
      .addCase(createBrand.fulfilled, (state) => {
        state.savingBrand = false;
      })
      .addCase(createBrand.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingBrand = false;
      })

      // UPDATE BRAND
      .addCase(updateBrand.pending, (state) => {
        state.savingBrand = true;
      })
      .addCase(updateBrand.fulfilled, (state) => {
        state.savingBrand = false;
      })
      .addCase(updateBrand.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingBrand = false;
      })

      // DELETE BRAND
      .addCase(deleteBrand.pending, (state) => {
        state.deletingBrand = true;
      })
      .addCase(deleteBrand.fulfilled, (state) => {
        state.deletingBrand = false;
      })
      .addCase(deleteBrand.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.deletingBrand = false;
      })

      // FETCH BRANDS
      .addCase(fetchBrands.pending, (state) => {
        state.fetchingBrands = true;
      })
      .addCase(fetchBrands.fulfilled, (state, action: any) => {
        state.value = action.payload.data;
        state.fetchingBrands = false;
        state.totalCount = action.payload.pagination.count;
        state.current = action.payload.current;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingBrands = false;
      })

      // SEARCH BRANDS BY NAME
      .addCase(searchBrandsByName.pending, (state) => {
        state.searchingBrands = true;
      })
      .addCase(searchBrandsByName.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingBrands = false;
        state.searchingBrands = false;
        state.value = action.payload.data;
        state.allBrandsValue = action.payload.data;
        state.pagination = action.payload.pagination;
        state.current = 1;
      })
      .addCase(searchBrandsByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingBrands = false;
        state.searchingBrands = false;
      })

      // LOAD MORE BRANDS
      .addCase(loadMoreBrands.pending, (state) => {
        state.fetchingBrands = true;
      })
      .addCase(loadMoreBrands.fulfilled, (state, action) => {
        state.fetchingBrands = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...state.value, ...action.payload.data];
          state.fetchingBrands = false;
        }
      })
      .addCase(loadMoreBrands.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingBrands = false;
      })

      // PAGINATE BRANDS
      .addCase(paginateBrands.pending, (state) => {
        state.fetchingBrands = true;
      })
      .addCase(paginateBrands.fulfilled, (state, action) => {
        state.fetchingBrands = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...action.payload.data];
          state.allBrandsValue = [...action.payload.data];
          state.fetchingBrands = false;
          state.current = action.payload.current;
          state.currentPage = action.meta.arg.page;
          state.pageSize = action.meta.arg.pageSize;
        }
      })
      .addCase(paginateBrands.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingBrands = false;
      });
  },
});

export default brandSlice.reducer;
