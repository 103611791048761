import { FC, memo, useCallback, useEffect, useState } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Select, Space, message } from 'antd';
import { History, Location } from 'history';
import { Link, withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import CustomFieldService from '../../../../api/custom-field';
import FiltersService from '../../../../api/filters';
import { CustomFieldOption } from '../../../../models/custom-field';

import {
  ListProductLibraryModel,
  ProductCategoryModel,
} from '../../../../models/product-library/index';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { bulkCreateCustomFieldOptions } from '../../../../store/features/customFields/customFieldsSlice';
import {
  bulkUpdateProducts as bulkUpdateMasterLibrarianProducts,
  bulkUpdateProducts,
} from '../../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import { bulkUpdateProducts as bulkUpdateProductListProducts } from '../../../../store/features/productList/productListSlice';
import { capitalizeFirstWord, propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import MultiLevelSelect from '../../../elements/MultiLevelSelect';
import { RoutePath } from '../../../views/AppRoot/types';
import { BulkEditProductsFormLocationState } from './types';

import { useFormData } from '../../../../hooks/editable-information-form';
import { BrandModel } from '../../../../models/brand';
import {
  fetchBrands,
  loadMoreBrands,
} from '../../../../store/features/brand/brandSlice';
import { SelectDataItem } from '../../../elements/MultiLevelSelect/types';
import { createDropdownVisibleChangeHandler } from '../EditableInformationForm/withDataFetching';
import './BulkEditProductsForm.less';

const { Option } = Select;
interface BulkEditProductsFormProps {
  history: History;
  location: Location<BulkEditProductsFormLocationState>;
}

interface FormProps {
  brand_id: { key: string; label: string; value: string };
  category: string;
  count: string;
  count_unit: string;
  format: string[];
  market: string;
  size: string;
  size_unit: string;
  tag_ids?: string[];
}

interface CreateNewTagsProps {
  name: string;
  option_key?: string;
  visible?: 1;
  status?: string;
  parent_id?: string | null;
}

const BulkEditProductsForm: FC<BulkEditProductsFormProps> = (props) => {
  const { history, location } = props;

  const [categories, setCategories] = useState<string[]>([]);
  const [categoryFilters, setCategoryFilters] = useState<SelectDataItem[]>([]);
  const [fetchingFilters, setFetchingFilters] = useState<boolean>(false);
  const [markets, setMarkets] = useState<CustomFieldOption[]>([]);
  const [countUnits, setCountUnits] = useState<CustomFieldOption[]>([]);
  const [sizeUnits, setSizeUnits] = useState<CustomFieldOption[]>([]);
  const [formats, setFormats] = useState<CustomFieldOption[]>([]);
  const [tags, setTags] = useState<CustomFieldOption[]>([]);
  // const [brands, setBrands] = useState<ProductBrandModel[]>([]);
  const [form] = Form.useForm();

  const { value: brands, fetchingBrands } = useAppSelector(
    (state) => state.brand
  );
  const { selectedProducts } = useAppSelector((state) => state.productList);
  const { savingProductList: savingMasterLibrarianList } = useAppSelector(
    (state) => state.masterLibrarianList
  );
  const { savingProductList: savingProductList } = useAppSelector(
    (state) => state.productList
  );

  const isSavingProductList = savingMasterLibrarianList || savingProductList;

  const dispatch = useAppDispatch();
  const { hasMoreBrands } = useFormData();

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const fetchFormInputData = async () => {
    setFetchingFilters(true);
    // const fetchBrands = ProductListService().getBrands();
    const fetchCategoryFilters = FiltersService().getFilters();
    const fetchFormats = CustomFieldService().getCustomField('format');
    const fetchMarkets = CustomFieldService().getCustomField('market');
    const fetchTags = CustomFieldService().getCustomField('tags');
    const fetchCountUnits = CustomFieldService().getCustomField('count_unit');
    const fetchSizeUnits = CustomFieldService().getCustomField('size_unit');

    try {
      const repsonses = await Promise.all([
        // fetchBrands,
        fetchCategoryFilters,
        fetchFormats,
        fetchMarkets,
        fetchTags,
        fetchCountUnits,
        fetchSizeUnits,
      ]);
      setFetchingFilters(false);
      const [
        // brandsResp
        categoryFiltersResp,
        formatResp,
        marketResp,
        tagResp,
        countUnitResp,
        sizeUnitResp,
      ] = repsonses;
      // setBrands(brandsResp.data);
      setCategoryFilters(categoryFiltersResp.data.categories || []);
      setFormats(formatResp.data.options);
      setMarkets(marketResp.data.options);
      setTags(tagResp.data.options);
      setCountUnits(countUnitResp.data.options);
      setSizeUnits(sizeUnitResp.data.options);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchFormInputData();
  }, []);

  const handleCategorySelect = useCallback(
    (category: SelectDataItem | 'All' = 'All') => {
      if (category !== 'All') {
        setCategories((prevCategories) => [...prevCategories, category.key]);
      }
    },
    []
  );

  const checkIfTagExists = useCallback(
    (submittedTag: string) => {
      return tags.some((tag) => tag.option_key === submittedTag);
    },
    [tags]
  );

  const handleTagsToCreate = useCallback(
    async (
      tag_ids: string[],
      checkIfTagExists: (submittedTag: string) => boolean
    ) => {
      const newTags = tag_ids.filter((tag) => {
        return !checkIfTagExists(tag);
      });

      const formattedNewTags: CreateNewTagsProps[] = newTags.map((tag) => {
        return { name: tag };
      });

      await dispatch(
        bulkCreateCustomFieldOptions({
          customFieldKey: 'tags',
          bulkCustomFieldOptions: formattedNewTags,
        })
      );
    },
    [dispatch]
  );

  const handleTagsToDelete = (tags: CustomFieldOption[], tag_ids: string[]) => {
    const tagsToDelete = tags.filter(
      (tag) => !tag_ids.includes(tag.option_key)
    );
    const formattedTagsToDelete = tagsToDelete.map((tag) => tag.option_key);
    return formattedTagsToDelete;
  };

  const getCategoryIdFromList = (
    selectedProductCategoryList: ProductCategoryModel[] | undefined
  ) => {
    return selectedProductCategoryList?.map((category) => category.uuid) || [];
  };

  const saveProducts = useCallback(
    async (values: FormProps) => {
      const {
        brand_id,
        format,
        market,
        count,
        count_unit,
        size,
        size_unit,
        tag_ids: tags_set,
      } = values;

      let updatedInformation = {};
      let fields = {};

      fields = {
        format,
        market,
        old_size: size,
        old_count: count,
        count_unit,
        size_unit,
      };

      const uuids = selectedProducts.map((product) => product.uuid);

      if (tags_set) {
        await handleTagsToCreate(tags_set, checkIfTagExists);
        const tags_delete = handleTagsToDelete(tags, tags_set);
        await dispatch(bulkUpdateProducts({ uuids, tags_delete, tags_set }));
      }

      const selectedProductsCategories = selectedProducts.map(
        (product: ListProductLibraryModel) => product.categories
      );
      const formattedCategoriesDelete = selectedProductsCategories.map(
        (selectedProductCategoryList) => {
          return getCategoryIdFromList(selectedProductCategoryList);
        }
      );

      const categories_delete: string[] = [];
      formattedCategoriesDelete.forEach((list) =>
        categories_delete.push(...list)
      );

      const formattedBrandId = brand_id ? [brand_id.value] : [];

      updatedInformation = {
        uuids,
        brand_id: formattedBrandId,
        categories_delete,
        categories_set: categories,
        fields,
      };

      const bulkUpdateAction = location.pathname.includes('master-librarian')
        ? bulkUpdateMasterLibrarianProducts
        : bulkUpdateProductListProducts;

      const res = await dispatch(bulkUpdateAction(updatedInformation));

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Products saved.');
        history.goBack();
      }
    },
    [
      categories,
      checkIfTagExists,
      dispatch,
      handleTagsToCreate,
      history,
      onError,
      selectedProducts,
      tags,
      location.pathname,
    ]
  );

  const onSubmit = useCallback(
    (values: FormProps) => {
      form.validateFields().then(() => saveProducts(values));
    },
    [form, saveProducts]
  );

  const handleBrandScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      dispatch(
        loadMoreBrands({
          hasMoreBrands,
        })
      );
    }
  };

  const handleBrandsDropdownChange = createDropdownVisibleChangeHandler(() =>
    dispatch(
      fetchBrands({
        params: {
          _limit: 20,
          _order_by: 'updated_at:desc',
          _offset: 0,
          status: 'approved',
        },
      })
    )
  );

  return (
    <FormWrapper title="Bulk Edit Products" onClose={() => history.goBack()}>
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={{ categories: [''], brand_id: [] }}
        onFinish={onSubmit}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.List
            name="categories"
            rules={[
              {
                validator: async (_, categories) => {
                  if (!categories || categories.length < 1) {
                    return Promise.reject(
                      new Error('Add at least one category')
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <div>
                {fields.map((field) => (
                  <Form.Item required={false} key={field.key}>
                    <div className="form-item-categories-wrapper">
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        className="form-item-categories"
                      >
                        <MultiLevelSelect
                          data={categoryFilters}
                          loading={fetchingFilters}
                          onChange={handleCategorySelect}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </div>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: '60%',
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add another category
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </div>
            )}
          </Form.List>

          <Space size="small">
            <Form.Item
              label="Brand"
              name="brand_id"
              className="form-item-select"
            >
              <Select
                id="brand-select"
                labelInValue
                className="edit-form-selection"
                placeholder="Select a brand"
                onPopupScroll={handleBrandScroll}
                onDropdownVisibleChange={handleBrandsDropdownChange}
                loading={fetchingBrands}
                notFoundContent={
                  fetchingBrands ? <span>Loading...</span> : null
                }
              >
                {brands &&
                  [...brands]
                    .sort((a: BrandModel, b: BrandModel) =>
                      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    )
                    .map((brand: BrandModel) => {
                      return (
                        <Option key={brand.uuid} value={brand.uuid}>
                          {capitalizeFirstWord(brand.name)}
                        </Option>
                      );
                    })}
              </Select>
            </Form.Item>
            <Link to={RoutePath.MetaData}>
              <Button type="link" className="link-button">
                Add new brand
              </Button>
            </Link>
          </Space>
          <Space size="small">
            <Form.Item
              label="Market"
              name="market"
              className="form-item-select"
            >
              <Select placeholder="Select a market">
                {[...markets]
                  .sort((a, b) =>
                    a.name === 'Undefined'
                      ? -1
                      : b.name === 'Undefined'
                      ? 1
                      : a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map((market) => {
                    return (
                      <Option key={market.option_key} value={market.id}>
                        {market.name === 'Undefined'
                          ? 'All Markets'
                          : capitalizeFirstWord(market.name)}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Link to={RoutePath.MetaData}>
              <Button type="link" className="link-button">
                Add new market
              </Button>
            </Link>
          </Space>
          <Space size="small">
            <Form.Item
              label="Format"
              name="format"
              className="form-item-select"
            >
              <Select placeholder="Select a format">
                {[...formats]
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map((format) => {
                    return (
                      <Option key={format.option_key} value={format.id}>
                        {capitalizeFirstWord(format.name)}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Link to={RoutePath.MetaData}>
              <Button type="link" className="link-button">
                Add new format
              </Button>
            </Link>
          </Space>
        </Space>
        <Space direction="vertical">
          <Space size="small" align="end">
            <Form.Item label="Count" name="count">
              <Input
                data-cy="editable-information-form-input"
                placeholder="Count"
              />
            </Form.Item>
            <Form.Item label="Count Unit of Measurement" name="count_unit">
              <Select placeholder="Select a unit">
                {[...countUnits]
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map((countUnit) => {
                    return (
                      <Option key={countUnit.option_key} value={countUnit.id}>
                        {capitalizeFirstWord(countUnit.name)}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Space>
          <Space size="small" align="end">
            <Form.Item label="Size" name="size">
              <Input
                data-cy="editable-information-form-input"
                placeholder="Size"
              />
            </Form.Item>
            <Form.Item label="Size Unit of Measurement" name="size_unit">
              <Select placeholder="Select a unit">
                {[...sizeUnits]
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map((sizeUnit) => {
                    return (
                      <Option key={sizeUnit.option_key} value={sizeUnit.id}>
                        {capitalizeFirstWord(sizeUnit.name)}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Space>
        </Space>

        <Form.Item
          label="Tags (type to create new tags)"
          name="tag_ids"
          className="form-item-tags"
        >
          <Select
            mode="tags"
            allowClear
            data-cy="editable-information-form-input"
            placeholder="Please select tag(s)"
          >
            {tags.map((tag) => {
              return (
                <Option key={tag.option_key} value={tag.option_key}>
                  {capitalizeFirstWord(tag.name)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item className="form-buttons-wrapper">
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              loading={isSavingProductList}
              data-cy="bulk-edit-products-form-submit-btn"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              Edit
            </Button>
            <Button onClick={() => history.goBack()} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(BulkEditProductsForm, propsAreEqual));
