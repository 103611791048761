import { FC, MouseEvent, memo, useCallback, useMemo, useState } from 'react';

import { ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Tag, Tooltip, Typography, message } from 'antd';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import './OVRProjectItem.less';

import { useAuth } from '../../../../../hooks/useAuth';
import {
  DATConfiguration,
  ListOvrProjectModel,
} from '../../../../../models/online-virtual-research';
import { useAppDispatch } from '../../../../../store';
import { deleteOvrProject } from '../../../../../store/features/ovrProjects/ovrProjectsSlice';
import {
  UserPermissions,
  formatDate,
  propsAreEqual,
} from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import ExportRespondentDataModal from '../ExportRespondentDataModal';

const { Text, Title } = Typography;
interface OVRProjectItemProps {
  project: ListOvrProjectModel;
}

const OVRProjectItem: FC<OVRProjectItemProps> = (props) => {
  const { project } = props;
  const { name, updated_at, description, uuid, createdByUser, dat_config } =
    project;

  const [
    exportRespondentDataModalVisible,
    setExportRespondentDataModalVisible,
  ] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuth();

  const handleExportData = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setExportRespondentDataModalVisible(true);
  }, []);

  const handleDeleteProject = useCallback(async () => {
    message.loading(
      { content: 'Deleting project...', key: 'deleting-project-message' },
      0
    );

    await dispatch(deleteOvrProject(project?.uuid));
    message.destroy('deleting-project-message');
    message.success(`Project '${project?.name}' deleted.`);
  }, [project, dispatch]);

  const handleConfirmDeleteProject = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete project '${project?.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover it.',
        okText: 'Delete',
        onOk: async () => {
          await handleDeleteProject();
        },
      });
    },
    [handleDeleteProject, project?.name]
  );

  const moreMenuIconClass = useMemo(
    () => cx('more-menu-icon', { active: isMenuOpen }),
    [isMenuOpen]
  );

  const menu = useMemo(
    (): JSX.Element => (
      <Menu onClick={() => setIsMenuOpen(false)}>
        {hasPermission(UserPermissions.OvrProjectsDataExport) ? (
          <Menu.Item key="0">
            <div onClick={handleExportData}>Export Respondent Data</div>
          </Menu.Item>
        ) : null}
        {hasPermission(UserPermissions.OvrProjectsEdit) ? (
          <Menu.Item key="1">
            <Link
              to={{
                hash: DrawerHashRoute.OVRForm,
                state: { data: project },
              }}
            >
              Edit
            </Link>
          </Menu.Item>
        ) : null}
        {hasPermission(UserPermissions.OvrProjectsDelete) ? (
          <Menu.Item key="2">
            <div onClick={handleConfirmDeleteProject}>Delete</div>
          </Menu.Item>
        ) : null}
      </Menu>
    ),
    [handleConfirmDeleteProject, handleExportData, hasPermission, project]
  );

  const getDATRequirementColor = (datConfig: DATConfiguration): string => {
    switch (datConfig) {
      case DATConfiguration.Optional:
        return 'blue';
      case DATConfiguration.Disabled:
        return 'red';
      case DATConfiguration.Required:
        return 'green';
    }
  };

  return (
    <>
      <Link to={`/online-virtual-research/${uuid}`}>
        <div className="ovr-grid-item">
          <div className="ovr-grid-item-section-top">
            <div className="ovr-grid-item-top-section-left">
              <div className="ovr-item-title">
                <Title level={5} ellipsis>
                  {name}
                </Title>
              </div>
              <div className="ovr-item-subtitle">
                {!!description ? (
                  <Tooltip title={description} mouseEnterDelay={1}>
                    <Text style={{ width: '100%' }} ellipsis type="secondary">
                      {description}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text type="secondary" style={{ fontStyle: 'italic' }}>
                    No description.
                  </Text>
                )}
              </div>
            </div>
            <div className="ovr-grid-item-top-section-right">
              <Dropdown
                overlay={menu}
                trigger={['click']}
                visible={isMenuOpen}
                onVisibleChange={(value) => setIsMenuOpen(value)}
              >
                <Tooltip title="More" mouseEnterDelay={1}>
                  <MoreOutlined className={moreMenuIconClass} />
                </Tooltip>
              </Dropdown>
            </div>
          </div>
          <div className="ovr-grid-item-section-bottom">
            <div className="ovr-grid-item-detail-wrapper">
              <Text strong>Last Updated</Text>
              <Text>{formatDate(updated_at)}</Text>
            </div>
            <div className="ovr-grid-item-detail-wrapper">
              <Text strong>Dynamic Attention Tracking</Text>
              <Tag
                style={{ width: '62px', textAlign: 'center' }}
                color={getDATRequirementColor(dat_config)}
              >
                {dat_config}
              </Tag>
            </div>
            <div className="ovr-grid-item-detail-wrapper">
              <Text strong>Created By</Text>
              <Text>{createdByUser?.name}</Text>
            </div>
          </div>
        </div>
      </Link>

      <ExportRespondentDataModal
        visible={exportRespondentDataModalVisible}
        project={project}
        onClose={() => setExportRespondentDataModalVisible(false)}
      />
    </>
  );
};

export default memo(OVRProjectItem, propsAreEqual);
