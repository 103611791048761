import { FC, memo, useCallback, useMemo } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Switch,
  Typography,
} from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { useAuth } from '../../../../hooks/useAuth';
import { ProductStatusEnum } from '../../../../models/product-library';
import { initialNewTagModel, TagModel } from '../../../../models/tag';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createCustomFieldOption,
  deleteCustomFieldOption,
  fetchTags,
  updateCustomFieldOption,
} from '../../../../store/features/metaData/metaDataSlice';
import { propsAreEqual, UserPermissions } from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';
import './TagForm.less';
import { TagFormLocationState } from './types';

interface TagFormProps {
  history: History;
  location: Location<TagFormLocationState>;
}

interface TagProps {
  name: string;
  status: string;
}

const TagForm: FC<TagFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const { deletingCustomFieldOption, savingCustomFieldOption } = useAppSelector(
    (state) => state.metaData
  );
  const [form] = Form.useForm();

  const tagLocationData = location.state?.data;
  const isNewTag = useMemo(() => !tagLocationData, [tagLocationData]);
  const fields = useMemo(
    () => Object.keys(isNewTag ? initialNewTagModel : tagLocationData),
    [isNewTag, tagLocationData]
  );

  const { hasPermission } = useAuth();

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const saveTag = useCallback(
    async (tag: TagModel) => {
      const { name, statusCheckValue } = tag;

      let status: string;
      let newTag: TagProps = { name: '', status: '' };

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newTag = {
        name,
        status,
      };

      const res: any = isNewTag
        ? await dispatch(
            createCustomFieldOption({
              customFieldKey: 'tags',
              customFieldOption: newTag,
            })
          )
        : await dispatch(
            updateCustomFieldOption({
              customFieldKey:
                tagLocationData.custom_field_id as unknown as string,
              optionId: tagLocationData.id,
              customFieldOption: newTag,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Tag saved.');
        await dispatch(fetchTags());
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewTag,
      tagLocationData?.custom_field_id,
      tagLocationData?.id,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: TagModel) => {
      form.validateFields(fields).then(() => saveTag(values));
    },
    [fields, form, saveTag]
  );

  const getInitialValues = () => {
    if (isNewTag) {
      return { ...initialNewTagModel };
    }
    const { status } = tagLocationData;
    return {
      ...tagLocationData,
      statusCheckValue: status === ProductStatusEnum.approved,
    };
  };

  const handleDeleteTag = useCallback(async () => {
    message.loading(
      {
        content: 'Deleting tag...',
        key: 'deleting-tag-message',
      },
      0
    );

    const res: any = await dispatch(
      deleteCustomFieldOption({
        customFieldKey: 'tags',
        optionId: tagLocationData.id,
      })
    );
    if (res.error) {
      onError({ error: res.payload.error });
    } else {
      message.destroy('deleting-tag-message');
      message.success(`Tag '${tagLocationData.name}' deleted.`);
      await dispatch(fetchTags());
      history.goBack();
    }
  }, [dispatch, tagLocationData?.id, tagLocationData?.name, onError, history]);

  const handleConfirmDeleteTag = useCallback(
    (e: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the Tag "${tagLocationData.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will no longer be able to add this Tag to products',
        okType: 'danger',
        okText: 'Delete',
        onOk: handleDeleteTag,
      });
    },
    [handleDeleteTag, tagLocationData?.name]
  );

  return (
    <FormWrapper
      title={`${isNewTag ? 'Add' : 'Edit'} Tag`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Tag"
          name="name"
          rules={[
            {
              required: true,
              message: 'Tag name is required.',
            },
          ]}
        >
          <Input data-cy="tag-form-input" placeholder="Tag name" />
        </Form.Item>
        <Form.Item label="Status">
          <div className="tag-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue" valuePropName="checked">
              <Switch
                disabled={!hasPermission(UserPermissions.MetaDataApprove)}
              />
            </Form.Item>
          </div>
        </Form.Item>
        {!isNewTag ? (
          <Form.Item label="Details">
            <div className="tag-form-details">
              <Typography>Number of models in library</Typography>
              <Typography>{tagLocationData.in_library}</Typography>
            </div>
            <div className="tag-form-details">
              <Typography>
                Number of models in processing / master librarian list
              </Typography>
              <Typography>{tagLocationData.in_processing}</Typography>
            </div>
            <Button
              className="link-button"
              type="link"
              onClick={() => {
                history.push({
                  state: { tag: tagLocationData },
                  pathname: RoutePath.ProductLibrary,
                });
              }}
            >
              View in Library
            </Button>
          </Form.Item>
        ) : null}
        <Form.Item>
          {isNewTag ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingCustomFieldOption}
                data-cy="tag-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={!hasPermission(UserPermissions.MetaDataCreate)}
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!tagLocationData.in_use ? (
                <Button
                  loading={deletingCustomFieldOption}
                  danger
                  type="primary"
                  disabled={!hasPermission(UserPermissions.MetaDataDelete)}
                  onClick={handleConfirmDeleteTag}
                >
                  Delete
                </Button>
              ) : null}
              <Button
                loading={savingCustomFieldOption}
                type="primary"
                htmlType="submit"
                disabled={!hasPermission(UserPermissions.MetaDataEdit)}
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(TagForm, propsAreEqual));
