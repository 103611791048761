import { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';

import { ApiError, handleError } from '../../../../../api/base';
import { ListProductLibraryModel } from '../../../../../models/product-library';
import { fetchCustomFieldOption } from '../../../../../store/features/customFields/customFieldsSlice';
import { useAppDispatch } from '../../../../../store/index';
import { renderField } from '../Row';

import './PackageInfo.less';

const { Title } = Typography;
const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

interface PackageInfoProps {
  productItem: ListProductLibraryModel | undefined;
}

const PackageInfo = ({ productItem }: PackageInfoProps) => {
  const dispatch = useAppDispatch();
  const [countUnit, setCountUnit] = useState<string>('');
  const [sizeUnit, setSizeUnit] = useState<string>('');
  const [fetchingValues, setFetchingValues] = useState<boolean>(false);
  const [formatName, setFormatName] = useState<string>('');

  useEffect(() => {
    const getUnitName = async (
      fieldKey: string,
      setState: (name: string) => void
    ) => {
      const field = productItem?.fields.find(
        (field) => field.field_key === fieldKey
      );
      if (!field) return;

      try {
        setFetchingValues(true);
        const option = await dispatch(
          fetchCustomFieldOption({
            customFieldKey: fieldKey,
            optionId: field.value as number,
          })
        );
        setState(option?.payload?.name ?? '');
      } catch (error) {
        handleError(error as ApiError);
      } finally {
        setFetchingValues(false);
      }
    };

    const hasCount = productItem?.fields.some(
      (field) => field.field_key === 'old_count' && field.value
    );

    if (hasCount) {
      getUnitName('count_unit', setCountUnit);
    }

    getUnitName('size_unit', setSizeUnit);
    getUnitName('format', setFormatName);
  }, [dispatch, productItem?.fields]);

  return (
    <div className="package-info">
      <Title className="title" level={4}>
        Package Information
      </Title>
      {!fetchingValues ? (
        <>
          <div className="row">
            <div className="row-item">
              <p className="row-label">Format</p>
              {formatName}
            </div>
          </div>
          {productItem &&
            renderField(productItem, 'old_count', undefined, countUnit)}
          {productItem &&
            renderField(productItem, 'old_size', undefined, sizeUnit)}
          {productItem && renderField(productItem, 'old_width')}
          {productItem && renderField(productItem, 'old_height')}
          {productItem && renderField(productItem, 'old_depth')}
        </>
      ) : (
        <div className="loading-container">
          <p>Loading package information...</p>
          <Spin indicator={antSpinIcon} />
        </div>
      )}
    </div>
  );
};

export default PackageInfo;
