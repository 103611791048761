import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, handleError, PaginationModel } from '../../../api/base';
import ProductLibraryService from '../../../api/product-library';
import { ListProductLibraryParams } from '../../../hooks/product-library';
import { ListProductLibraryModel } from '../../../models/product-library';
import { convertParamsToQuery } from '../../../util';
import { RootState } from '../../index';

interface ProductLibraryError {
  code: number | null;
  message: string | null;
}

export interface ProductLibraryState {
  value: ListProductLibraryModel[];
  allProductLibraryItems: ListProductLibraryModel[];
  fetchingProductLibraryItems: boolean;
  searchingProductLibrary: boolean;
  savingProductList: boolean;
  error: ProductLibraryError;
  pagination: PaginationModel | null;
  totalCount: number;
  searchQuery: string;
  deletingProduct: boolean;
  currentPage: number;
  pageSize: number;
  count: number;
}

const initialState: ProductLibraryState = {
  value: [],
  allProductLibraryItems: [],
  fetchingProductLibraryItems: false,
  searchingProductLibrary: false,
  savingProductList: false,
  error: { code: null, message: null },
  totalCount: 0,
  pagination: null,
  searchQuery: '',
  deletingProduct: false,
  currentPage: 1,
  pageSize: 10,
  count: 0,
};

export const fetchProductLibraryItems = createAsyncThunk(
  'masterLibrarianList/fetchProductLibraryItems',
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        masterLibrarianList: { pagination },
      } = getState() as RootState;

      const params: ListProductLibraryParams = {
        _limit: pagination?.limit,
        _offset: 0,
        _order_by: 'updated_at:desc',
        status: 'needs_approval',
      };

      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProducts = createAsyncThunk(
  'masterLibrarianList/fetchProducts',
  async (query: string, { getState, rejectWithValue }) => {
    try {
      const {
        masterLibrarianList: { pagination },
        productLibrary: { pageSize, currentPage },
      } = getState() as RootState;

      let params: ListProductLibraryParams = {
        _limit: pageSize || pagination?.limit || 10,
        _offset: currentPage ? (currentPage - 1) * (pageSize || 10) : 0,
        _order_by: 'updated_at:desc',
        status: 'needs_approval',
      };

      if (query && query.length) {
        params = {
          ...params,
          q: `${query.toLowerCase()}`,
        };
      }

      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateProductLibrary = createAsyncThunk(
  'masterLibrarianList/paginateProductLibrary',
  async (
    {
      page,
      pageSize,
      status,
    }: { page: number; pageSize: number; status?: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;
      const {
        masterLibrarianList: { searchQuery },
      } = getState() as RootState;

      let nextParams: ListProductLibraryParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
        status,
      };

      if (searchQuery) {
        nextParams = {
          ...nextParams,
          q: searchQuery,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await ProductLibraryService().searchProductLibrary(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMasterLibrarianListCount = createAsyncThunk(
  'masterLibrarianList/fetchMasterLibrarianListCount',
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        masterLibrarianList: { pagination },
      } = getState() as RootState;

      const params: ListProductLibraryParams = {
        _limit: pagination?.limit,
        _offset: 0,
        _order_by: 'updated_at:desc',
        status: 'needs_approval',
      };

      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return resp.pagination.count;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// endpoint for bulk delete is not available in the API
// export const bulkDeleteModelsMasterList = createAsyncThunk(
//   'masterLibrarianList/bulkDeleteModelsMasterList',
//   async (uuids: string[], { dispatch, rejectWithValue }) => {
//     const modelsToDelete = {
//       uuids,
//     };
//     try {
//       await ProductLibraryService().bulkDelete(modelsToDelete);
//       await dispatch(searchProductsByNameMasterLibrarianList({ query: '' }));
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const bulkUpdateProducts = createAsyncThunk(
  'masterLibrarianList/bulkUpdateProducts',
  async (updatedInformation: any, { dispatch, rejectWithValue }) => {
    try {
      await ProductLibraryService().bulkEdit(updatedInformation);
      await dispatch(fetchProductLibraryItems());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// bulk delete products
export const deleteProductFromMasterLibrarianList = createAsyncThunk(
  'masterLibrarianList/deleteProductFromMasterLibrarianList',
  async (productId: string, { rejectWithValue }) => {
    try {
      await ProductLibraryService().deleteProductItem(productId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const masterLibrarianListSlice = createSlice({
  name: 'masterLibrarianList',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
      state.pagination = {
        offset: 0,
        limit: state.pagination?.limit ?? 10,
        count: state.pagination?.count ?? 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductLibraryItems.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(fetchProductLibraryItems.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingProductLibraryItems = false;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchProductLibraryItems.rejected, (state, action) => {
        state.fetchingProductLibraryItems = false;
        if (action.error.message) {
          state.error.message = action.error.message;
        }
        handleError(action.error as ApiError);
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
      })

      .addCase(fetchProducts.pending, (state) => {
        state.fetchingProductLibraryItems = true;
        state.searchingProductLibrary = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.fetchingProductLibraryItems = false;
        if (action.error.message) {
          state.error.message = action.error.message;
        }
        handleError(action.error as ApiError);
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
      });

    builder
      .addCase(fetchMasterLibrarianListCount.pending, (state) => {
        state.fetchingProductLibraryItems = true;
        state.searchingProductLibrary = true;
      })
      .addCase(fetchMasterLibrarianListCount.fulfilled, (state, action) => {
        state.totalCount = action.payload;
      })
      .addCase(fetchMasterLibrarianListCount.rejected, (state, action) => {
        state.fetchingProductLibraryItems = false;
        if (action.error.message) {
          state.error.message = action.error.message;
        }
        handleError(action.error as ApiError);
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
      });

    builder
      .addCase(paginateProductLibrary.fulfilled, (state, action) => {
        state.fetchingProductLibraryItems = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = action.payload.data;
          state.currentPage = action.meta.arg.page;
          state.pageSize = action.meta.arg.pageSize;
          state.fetchingProductLibraryItems = false;
          state.count = action.payload.pagination.count;
        }
      })
      .addCase(paginateProductLibrary.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(paginateProductLibrary.rejected, (state, action) => {
        state.fetchingProductLibraryItems = false;
        if (action.error.message) {
          state.error.message = action.error.message;
        }
        handleError(action.error as ApiError);
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(bulkUpdateProducts.pending, (state) => {
        state.savingProductList = true;
      })
      .addCase(bulkUpdateProducts.fulfilled, (state) => {
        state.savingProductList = false;
      })
      .addCase(bulkUpdateProducts.rejected, (state, action) => {
        state.savingProductList = false;
        if (action.error.message) {
          state.error.message = action.error.message;
        }
        handleError(action.error as ApiError);
      });

    builder
      .addCase(deleteProductFromMasterLibrarianList.pending, (state) => {
        state.deletingProduct = true;
      })
      .addCase(deleteProductFromMasterLibrarianList.fulfilled, (state) => {
        state.deletingProduct = false;
      })
      .addCase(deleteProductFromMasterLibrarianList.rejected, (state) => {
        state.deletingProduct = false;
      });
  },
});

export const { setSearchQuery } = masterLibrarianListSlice.actions;

export const getMasterListTotalCount = (state: RootState) =>
  state.masterLibrarianList.totalCount;

export default masterLibrarianListSlice.reducer;
