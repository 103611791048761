import { useCallback, useEffect, useMemo, useState } from 'react';

import { BackTop } from 'antd';
import { Location } from 'history';
import './ProductLibraryView.less';

import FiltersService from '../../../api/filters';
import { ListProductLibraryParams } from '../../../hooks/product-library';
import {
  filterProducts,
  setSearchQuery,
  setSelectedCategory,
  setSelectedFilters,
} from '../../../store/features/productLibrary/productLibrarySlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { SelectDataItem } from '../../elements/MultiLevelSelect/types';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import HeroImage from './components/HeroImage/HeroImage';
import ProductLibraryItemsList from './components/ProductLibraryItemsList';
import { ProductLibraryListViewType } from './components/ProductLibraryItemsList/ProductLibraryItemsList';
import ProductsFilters from './components/ProductsFilters';
import {
  FiltersType,
  SelectedFilters,
} from './components/ProductsFilters/ProductsFilters';
import SearchBar from './components/SearchBar/SearchBar';
import { ProductLibraryViewLocationState } from './types';

export const productLibraryListViewTypeKey = 'product-library-list-view-type';

interface ProductLibraryViewProps {
  location: Location<ProductLibraryViewLocationState>;
}

const ProductLibraryView = ({ location }: ProductLibraryViewProps) => {
  const dispatch = useAppDispatch();

  const [categoryFilters, setCategoryFilters] = useState<SelectDataItem[]>([]);
  const [filters, setFilters] = useState<FiltersType>({});
  const [fetchingFilters, setFetchingFilters] = useState<boolean>(false);
  const [viewType, setViewType] = useState<ProductLibraryListViewType>(
    (localStorage.getItem(
      productLibraryListViewTypeKey
    ) as ProductLibraryListViewType) || ProductLibraryListViewType.Grid
  );

  const {
    value: productLibrary,
    fetchingProductLibraryItems,
    totalCount,
    pagination,
    searchingProductLibrary,
    allProductLibraryItems,
    searchQuery,
    selectedCategory,
    selectedFilters,
    currentPage,
    selectedMarket,
    pageSize,
  } = useAppSelector((state) => state.productLibrary);

  const hasNoSearchResultsFound =
    !fetchingProductLibraryItems &&
    !!searchQuery &&
    productLibrary.length === 0 &&
    totalCount === 0;

  const hasProductLibraryItems = !(
    !fetchingProductLibraryItems && allProductLibraryItems.length === 0
  );

  const hasMoreProductLibraryItems =
    hasProductLibraryItems &&
    pagination?.offset! + pagination?.offset! <= totalCount!;

  const isPermittedToLoadMore =
    !fetchingProductLibraryItems &&
    !searchingProductLibrary &&
    hasMoreProductLibraryItems;

  const selected: string | undefined = useMemo(() => {
    if (!location.state?.category) return undefined;
    const { key } = location.state.category;
    return key;
  }, [location.state?.category]);

  const selectedFilter: SelectedFilters | undefined = useMemo(() => {
    const tagId = location.state?.tag?.id as unknown as string;
    const formatId = location.state?.format?.id as unknown as string;
    const marketId = location.state?.market?.id as unknown as string;
    const brandId = location.state?.brand?.uuid;
    const manufacturerId = location.state?.manufacturer?.uuid;
    const parentcoId = location.state?.parentco?.uuid;

    return {
      tags: tagId ? [tagId] : [],
      format: formatId ? [formatId] : [],
      market: marketId ? [marketId] : [],
      brands: brandId ? [brandId] : [],
      manufacturers: manufacturerId ? [manufacturerId] : [],
      parentco: parentcoId ? [parentcoId] : [],
    };
  }, [location.state]);

  const fetchFilters = useCallback(async () => {
    setFetchingFilters(true);
    const res = await FiltersService().getFilters();
    const { categories, ...restFilters } = res.data;
    setCategoryFilters(categories || []);
    setFilters(restFilters);
    setFetchingFilters(false);
  }, []);

  const buildFilterParams = useCallback((): ListProductLibraryParams => {
    let params: ListProductLibraryParams = {
      _limit: pageSize,
      _offset: (currentPage! - 1) * pageSize!,
      _order_by: 'updated_at:desc',
      status: 'approved',
      ['market[]']: selectedMarket,
    };

    if (selectedCategory && selectedCategory !== 'All') {
      params.category = selectedCategory.key;
    }

    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((filterGroup) => {
        const filterKey = filterGroup as keyof FiltersType;
        if (selectedFilters[filterKey]?.length) {
          params = {
            ...params,
            [`${filterGroup}`]: selectedFilters[filterKey],
          };
        }
      });
    }

    return params;
  }, [
    currentPage,
    pageSize,
    selectedCategory,
    selectedFilters,
    selectedMarket,
  ]);

  const updateSearchQuery = useCallback(
    (query: string) => {
      dispatch(setSearchQuery(query));
    },
    [dispatch]
  );

  const handleSelectCategoryFilter = useCallback(
    (data: SelectDataItem | 'All' | undefined) => {
      dispatch(setSelectedCategory(data));
    },
    [dispatch]
  );

  const handleFiltersSelected = useCallback(
    (data: SelectedFilters) => {
      dispatch(setSelectedFilters(data));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  useEffect(() => {
    dispatch(
      filterProducts({
        params: {
          ...buildFilterParams(),
          _offset: 0,
        },
        query: searchQuery,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    searchQuery,
    selectedMarket,
    selectedCategory,
    selectedFilters,
  ]);

  return (
    <ViewWrapper>
      <div className="product-library__search-bar-container">
        <SearchBar
          searchQuery={searchQuery}
          searchingProducts={fetchingProductLibraryItems}
          updateSearchQuery={updateSearchQuery}
          viewType={viewType}
          setViewType={setViewType}
          productLibraryListViewTypeKey={productLibraryListViewTypeKey}
          categoryFilters={categoryFilters}
          loading={fetchingFilters}
          onSelectCategoryFilter={handleSelectCategoryFilter}
          selected={selected}
        />
      </div>
      <div className="product-library__content">
        <div className="product-library__content-sidebar">
          <ProductsFilters
            selectedFilter={selectedFilter}
            filters={filters}
            loading={fetchingFilters}
            onFiltersSelected={handleFiltersSelected}
          />
        </div>
        <div className="product-library__content-main">
          <HeroImage />
          <div className="product-library__items-list-container">
            {hasNoSearchResultsFound ? (
              <NoResultsFound
                searchQuery={searchQuery}
                onClear={() => updateSearchQuery('')}
              />
            ) : (
              <ProductLibraryItemsList
                viewType={viewType}
                loading={fetchingProductLibraryItems}
                productLibrary={productLibrary}
                hasMore={isPermittedToLoadMore}
                buildFilterParams={buildFilterParams}
              />
            )}
          </div>
        </div>
      </div>

      <BackTop />
    </ViewWrapper>
  );
};

export default ProductLibraryView;
