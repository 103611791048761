import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { SearchOutlined } from '@ant-design/icons';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Input,
  Progress,
  Space,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import useDebounce from '../../../hooks/useDebounce';

import { ApiError, handleError } from '../../../api/base';
import uploadIcon from '../../../assets/svgs/upload.svg';
import { useAuth } from '../../../hooks/useAuth';
import { ProductModel } from '../../../models/upload';
import { useAppDispatch, useAppSelector } from '../../../store';
import { fetchMasterLibrarianListCount } from '../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import {
  categorizeModel,
  clearUploadData,
  resetExistingProducts,
  resetNotExistingProducts,
  reuploadModel,
  setExistingProducts,
  setReplacedProducts,
  setUploadedProducts,
} from '../../../store/features/uploadModel/uploadModel';
import { UserPermissions } from '../../../util';
import NoPermission from '../../elements/NoPermission';
import ViewWrapper from '../../elements/ViewWrapper';
import UploadModelsTable from './UploadModelsTable';

import './UploadModelsView.less';
import { uploadModelColumns } from './uploadModelColumns';

import { getDirPath, pairJsonAndC4dFiles, uploadFilesToS3 } from './helpers';

const { Dragger } = Upload;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const UploadModelsView = () => {
  const dispatch = useAppDispatch();
  const { uploadedProducts, existingProducts, replacedProducts } =
    useAppSelector((state) => state.uploadModel);
  const { hasPermission } = useAuth();

  // tabs
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [userChangedTab, setUserChangedTab] = useState(false);

  const [replaceExistingChecked, setReplaceExistingChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // const [selectedModelsFilesList, setSelectedModelsFilesList] = useState([]);

  // categorize models
  const [isCategorizingModels, setIsCategorizingModels] = useState(false);
  const [modelCategorizationCount, setModelCategorizationCount] = useState(1);

  // const [existingProducts, setExistingProducts] = useState<ProductModel[]>([]);

  // uploading models progress
  const [isUploadingModels, setIsUploadingModels] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(1);
  const [totalFiles, setTotalFiles] = useState(0);
  const [totalModels, setTotalModels] = useState(1);

  // replacing models progress
  const [isReplacingModels, setIsReplacingModels] = useState(false);
  const [currentReplaceFileIndex, setCurrentReplaceFileIndex] = useState(1);
  const [totalReplaceFiles, setTotalReplaceFiles] = useState(0);
  const [totalReplaceModels, setTotalReplaceModels] = useState(1);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);

  // const [replacedProducts, setReplacedProducts] = useState<
  //   ListProductLibraryModel[]
  // >([]);

  const jsonFiles = useMemo(
    () => fileList.filter((file) => file.name.endsWith('.json')),
    [fileList]
  );

  const c4dFiles = useMemo(
    () => fileList.filter((file) => file.name.endsWith('.c4d')),
    [fileList]
  );

  const needsReplacing = useMemo(() => {
    return existingProducts.length > 0;
  }, [existingProducts.length]);

  const showResult = useMemo(() => {
    return (
      replacedProducts.length > 0 ||
      existingProducts.length > 0 ||
      uploadedProducts.length > 0
    );
  }, [
    uploadedProducts.length,
    existingProducts.length,
    replacedProducts.length,
  ]);

  const isProcessingDisabled = useMemo(() => {
    return (
      isCategorizingModels ||
      isUploadingModels ||
      isReplacingModels ||
      replacedProducts.length > 0 ||
      uploadedProducts.length > 0 ||
      existingProducts.length > 0
    );
  }, [
    isCategorizingModels,
    isUploadingModels,
    isReplacingModels,
    replacedProducts.length,
    uploadedProducts.length,
    existingProducts.length,
  ]);

  const onCheckboxChange = async (e: CheckboxChangeEvent) => {
    await handleClear();
    setReplaceExistingChecked(e.target.checked);
  };

  const handleFileSelectionChange: UploadProps['onChange'] = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleClear = useCallback(async () => {
    await dispatch(clearUploadData());
    await dispatch(setReplacedProducts([]));
    await dispatch(setUploadedProducts([]));
    await dispatch(resetExistingProducts());

    setFileList([]);
    setCurrentFileIndex(1);
    setTotalFiles(0);
    setTotalModels(1);
    setCurrentReplaceFileIndex(1);
    setTotalReplaceFiles(0);
    setTotalReplaceModels(1);
    setIsUploadingModels(false);
    setIsReplacingModels(false);
    setReplaceExistingChecked(false);
    setIsCategorizingModels(false);
    setModelCategorizationCount(1);
    setActiveTabKey('1');
    setUserChangedTab(false);
    setSearchQuery('');
  }, [dispatch]);

  const handleUpload = async () => {
    try {
      setIsCheckboxDisabled(true);
      dispatch(resetExistingProducts());
      dispatch(resetNotExistingProducts());
      setIsCategorizingModels(true);
      setTotalModels(jsonFiles.length);
      setTotalFiles(0);

      const modelsFormData = await pairJsonAndC4dFiles(jsonFiles, c4dFiles);

      let totalNewModels: any[] = [];
      let newModels: any;
      let totalModels: any[] = [];
      let existingModels: any;

      // Categorize each model as existing or new
      for (const [key, value] of modelsFormData.entries()) {
        if (value instanceof File) {
          const modelFormData = new FormData();
          modelFormData.append(key, value);

          const categorizeModelResponse = await dispatch(
            categorizeModel(modelFormData)
          ).unwrap();

          newModels = Object.keys(categorizeModelResponse.not_exists_items);
          totalNewModels = [...new Set([...totalNewModels, ...newModels])];

          existingModels = Object.keys(categorizeModelResponse.exists_items);

          if (!replaceExistingChecked) {
            const existingItems = Object.values(
              categorizeModelResponse.exists_items
            ).map((item) => (item as unknown as any).item);

            await dispatch(setExistingProducts(existingItems));
          }

          totalModels = [
            ...new Set([...totalModels, ...newModels, ...existingModels]),
          ];

          setModelCategorizationCount((prevCount) => prevCount + 1);
        }
      }

      setIsCategorizingModels(false);

      console.log('total models ', totalModels);
      console.log('total new models ', totalNewModels);

      if (replaceExistingChecked) {
        console.log('replaced checked totalModels ', totalModels);
        // loop through total models.
        if (totalModels.length > 0) {
          console.log('replaced checked looping through all models');
          setIsReplacingModels(true);
          const totalModelsFilesList = fileList.filter((file) =>
            totalModels.some((upc) => getDirPath(file).includes(upc))
          );

          setTotalReplaceFiles(totalModelsFilesList.length);
          setTotalReplaceModels(totalModels.length);

          for (const modelUpc of totalModels) {
            const modelInfoJsonFile = jsonFiles.find((infoFile) =>
              getDirPath(infoFile).includes(modelUpc)
            );

            if (modelInfoJsonFile) {
              const modelC4DFile = c4dFiles.filter((c4dFile) =>
                getDirPath(c4dFile).includes(modelUpc)
              );

              if (modelC4DFile.length > 0) {
                const modelsWithC4DFile = await pairJsonAndC4dFiles(
                  [modelInfoJsonFile],
                  modelC4DFile
                );

                const uploadModelToServerResult = await uploadModelToServer(
                  modelsWithC4DFile
                );

                let replacedModelsToUpload: [string, any][] = [];
                let addedModelsToUpload: [string, any][] = [];

                replacedModelsToUpload = Object.entries(
                  uploadModelToServerResult?.replacedAndAddedModels?.replaced
                );

                addedModelsToUpload = Object.entries({
                  ...uploadModelToServerResult?.replacedAndAddedModels?.added,
                });

                // console.log('addedModelsToUpload ', addedModelsToUpload);

                if (replacedModelsToUpload.length > 0) {
                  const s3UploadReplacedModelFilesResult =
                    (await uploadToS3AndGetImages(
                      replacedModelsToUpload,
                      totalModelsFilesList,
                      'replaced'
                    )) as ProductModel;

                  if (s3UploadReplacedModelFilesResult) {
                    await dispatch(
                      setReplacedProducts([
                        s3UploadReplacedModelFilesResult as ProductModel,
                      ])
                    );
                  }
                }

                if (addedModelsToUpload.length > 0) {
                  const s3UploadAddedModelFilesResult =
                    (await uploadToS3AndGetImages(
                      addedModelsToUpload,
                      totalModelsFilesList,
                      'added'
                    )) as ProductModel;

                  if (s3UploadAddedModelFilesResult) {
                    await dispatch(
                      setUploadedProducts([
                        s3UploadAddedModelFilesResult as ProductModel,
                      ])
                    );
                  }
                }
              } else {
                console.log(`No C4D files found for new model ${modelUpc}.`);
              }
            } else {
              console.log(`No JSON file found for new model ${modelUpc}.`);
            }
          }
        } else {
          console.log('No new models found.');
        }
      } else {
        console.log('replaced NOT checked');

        if (totalNewModels.length > 0) {
          setIsUploadingModels(true);
          console.log('replaced NOT checked looping through new models');

          const newModelsFilesList = fileList.filter((file) =>
            totalNewModels.some((upc) => getDirPath(file).includes(upc))
          );

          setTotalFiles(newModelsFilesList.length);
          setTotalModels(totalNewModels.length);

          for (const newModelUpc of totalNewModels) {
            const newModelInfoJsonFile = jsonFiles.find((infoFile) =>
              getDirPath(infoFile).includes(newModelUpc)
            );

            if (newModelInfoJsonFile) {
              const newModelC4DFile = c4dFiles.filter((c4dFile) =>
                getDirPath(c4dFile).includes(newModelUpc)
              );

              if (newModelC4DFile.length > 0) {
                const newModelsWithC4DFile = await pairJsonAndC4dFiles(
                  [newModelInfoJsonFile],
                  newModelC4DFile
                );

                const processModelResult = await uploadModelToServer(
                  newModelsWithC4DFile
                );

                let addedModelsToUpload: [string, any][] = [];
                addedModelsToUpload = Object.entries({
                  ...processModelResult?.replacedAndAddedModels?.added,
                });

                // const s3UploadModelFilesResult = (await uploadModelFilesToS3(
                //   processModelResult,
                //   newModelsFilesList
                // )) as ProductModel;

                const s3UploadAddedModelFilesResult =
                  (await uploadToS3AndGetImages(
                    addedModelsToUpload,
                    newModelsFilesList,
                    'added'
                  )) as ProductModel;

                if (s3UploadAddedModelFilesResult) {
                  await dispatch(
                    setUploadedProducts([
                      s3UploadAddedModelFilesResult as ProductModel,
                    ])
                  );
                }

                // if (s3UploadModelFilesResult) {
                //   await dispatch(
                //     setUploadedProducts([
                //       s3UploadModelFilesResult as ProductModel,
                //     ])
                //   );
                // }
              } else {
                console.log(`No C4D files found for new model ${newModelUpc}.`);
              }
            } else {
              console.log(`No JSON file found for new model ${newModelUpc}.`);
            }
          }
        } else {
          console.log('No new models found.');
        }
      }
    } catch (error) {
      console.error('Upload process failed:', error);
      handleError(error as ApiError);
    } finally {
      setIsUploadingModels(false);
      setIsReplacingModels(false);
      setTotalModels(1);
      setCurrentFileIndex(1);
      setCurrentReplaceFileIndex(1);
      setTotalFiles(0);
      setTotalReplaceFiles(0);
      setModelCategorizationCount(1);
      setIsCheckboxDisabled(false);
    }
  };

  const uploadModelToServer = async (modelToBeUploaded: FormData) => {
    try {
      const uploadModelResponse = await dispatch(
        reuploadModel(modelToBeUploaded)
      ).unwrap();

      return {
        replacedAndAddedModels: uploadModelResponse.data,
        c4dFiles,
      };
    } catch (error) {
      handleError(error as ApiError);
    } finally {
      // setIsReplacingModels(false);
      // setCurrentFileIndex(1);
      // setCurrentReplaceFileIndex(1);
    }
  };

  const uploadToS3AndGetImages = async (
    modelsToUpload: any,
    modelsFilesList: any,
    uploadType: 'replaced' | 'both' | 'added' = 'added'
  ) => {
    let modelWithPreviewImage = {};

    // Combine replaced and uploaded models for uploading
    // const modelsToUpload = Object.entries({
    //   ...categorizedModels?.replacedAndAddedModels?.replaced,
    //   ...categorizedModels?.replacedAndAddedModels?.added,
    // });

    // Iterating over each model to upload
    for (const [fileName, modelDetails] of modelsToUpload) {
      const matchingC4DFile = c4dFiles.find((file) => file.name === fileName);
      if (!matchingC4DFile) continue;

      const uploadDirectory = getDirPath(matchingC4DFile);
      if (!uploadDirectory) continue;

      // Uploading each model to aws s3 and updating the current model count
      const previewImageUrls = await uploadFilesToS3(
        (modelDetails as unknown as any).uuid,
        uploadDirectory,
        modelsFilesList,
        dispatch,
        uploadType,
        setCurrentFileIndex,
        setCurrentReplaceFileIndex
      );

      // Find the preview image URL for this model
      const modelPreviewImage = previewImageUrls.find(
        (p) => p.fileName === (modelDetails as unknown as any).upc + '_1.png'
      );

      modelWithPreviewImage = {
        ...(modelDetails as object),
        image_url: modelPreviewImage ? modelPreviewImage?.url : '',
      };
    }

    return modelWithPreviewImage;
  };

  const handleReplaceExistingProducts = async (selectedProducts: any) => {
    try {
      setIsCheckboxDisabled(true);
      setIsReplacingModels(true);

      const upcsOfProductsToReplace = selectedProducts.map(
        (product: any) => product.upc
      );

      const selectedModelsFilesList = fileList.filter((file) =>
        upcsOfProductsToReplace.some((upc: string) =>
          getDirPath(file).includes(upc)
        )
      );

      setTotalReplaceFiles(selectedModelsFilesList.length);
      setTotalReplaceModels(selectedProducts.length);

      for (const product of selectedProducts) {
        const modelUpc = product.upc;
        const replaceModelInfoJsonFile = jsonFiles.find((infoFile) =>
          getDirPath(infoFile).includes(modelUpc)
        );

        if (replaceModelInfoJsonFile) {
          const replaceModelC4DFile = c4dFiles.filter((c4dFile) =>
            getDirPath(c4dFile).includes(modelUpc)
          );

          if (replaceModelC4DFile.length > 0) {
            const replaceModelsWithC4DFile = await pairJsonAndC4dFiles(
              [replaceModelInfoJsonFile],
              replaceModelC4DFile
            );

            const uploadModelToServerResult = await uploadModelToServer(
              replaceModelsWithC4DFile
            );

            let selectedModelsToUpload: [string, any][] = [];
            selectedModelsToUpload = Object.entries({
              ...uploadModelToServerResult?.replacedAndAddedModels?.replaced,
              ...uploadModelToServerResult?.replacedAndAddedModels?.added,
            });

            if (selectedModelsToUpload.length > 0) {
              const s3UploadModelFilesResult = await uploadToS3AndGetImages(
                selectedModelsToUpload,
                selectedModelsFilesList,
                'replaced'
              );

              if (s3UploadModelFilesResult) {
                await dispatch(setReplacedProducts([s3UploadModelFilesResult]));
                await dispatch(resetExistingProducts());
                setActiveTabKey('1');
              }
            }
          }
        }
      }
    } catch (error) {
      handleError(error as ApiError);
    } finally {
      setIsReplacingModels(false);
      setTotalReplaceModels(1);
      setCurrentReplaceFileIndex(1);
      setTotalReplaceFiles(0);
      setIsCheckboxDisabled(false);
    }
  };

  useEffect(() => {
    if (uploadedProducts.length > 0 || replacedProducts.length > 0) {
      dispatch(fetchMasterLibrarianListCount());
    }

    if (
      uploadedProducts.length === 0 &&
      existingProducts.length === 0 &&
      replacedProducts.length === 0
    ) {
      handleClear();
    }
  }, [
    dispatch,
    uploadedProducts.length,
    replacedProducts.length,
    existingProducts.length,
    handleClear,
  ]);

  useEffect(() => {
    if (!userChangedTab) {
      if (uploadedProducts.length > 0) {
        setActiveTabKey('2');
      } else if (replacedProducts.length > 0) {
        setActiveTabKey('1');
      } else if (existingProducts.length > 0) {
        setActiveTabKey('0');
      }
    }
    return () => setUserChangedTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uploadedProducts.length,
    existingProducts.length,
    replacedProducts.length,
  ]);

  const handleTabChange = (key: SetStateAction<string>) => {
    setActiveTabKey(key);
    setUserChangedTab(true);
  };

  const filteredUploadedProducts = useMemo(() => {
    return uploadedProducts.filter((product) =>
      Object.values(product).some((value) =>
        String(value).toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
    );
  }, [uploadedProducts, debouncedSearchQuery]);

  const filteredExistingProducts = useMemo(() => {
    return existingProducts.filter((product) =>
      Object.values(product).some((value) =>
        String(value).toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
    );
  }, [existingProducts, debouncedSearchQuery]);

  const filteredReplacedProducts = useMemo(() => {
    return replacedProducts.filter((product) =>
      Object.values(product).some((value) =>
        String(value).toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      )
    );
  }, [replacedProducts, debouncedSearchQuery]);

  // console.log('uploadedProducts ', uploadedProducts);
  // console.log('existingProducts ', existingProducts);
  // console.log('replacedProducts ', replacedProducts);

  // console.log('file list ', fileList);
  return (
    <ViewWrapper>
      {hasPermission(UserPermissions.ModelUpload) ? (
        <div className="upload-model-form-wrapper">
          <Title level={4}>Upload File(s)</Title>
          <div className="sub-text-wrapper">
            <Text>Upload model(s) to the product library here</Text>
            <Checkbox
              disabled={isProcessingDisabled || isCheckboxDisabled}
              onChange={onCheckboxChange}
              checked={replaceExistingChecked}
            >
              Replace existing product model(s)
            </Checkbox>
          </div>

          <Dragger
            key={`key-${replaceExistingChecked}`}
            onChange={handleFileSelectionChange}
            beforeUpload={() => false}
            directory
            progress={{
              strokeColor: '#8E24AA',
              trailColor: '#C4C4C4',
            }}
            showUploadList={false}
            fileList={fileList}
            disabled={isProcessingDisabled}
            multiple
          >
            <Space size={22} direction="vertical">
              <img src={uploadIcon} alt="" />
              <div className="dragger-input-text">
                <Title level={4}>
                  Drop folders here or click to select files.
                </Title>
              </div>
            </Space>
          </Dragger>

          <Button
            className="upload-btn"
            type="primary"
            disabled={fileList.length === 0 || isProcessingDisabled}
            onClick={handleUpload}
            size="large"
          >
            Upload
          </Button>

          {isCategorizingModels && (
            <Text style={{ marginTop: '1rem' }}>
              <Text strong>Processing model</Text> {modelCategorizationCount} of{' '}
              {totalModels}...
            </Text>
          )}

          {isUploadingModels || isReplacingModels ? (
            <div className="progress-bar-wrapper">
              <Space size="small">
                {isUploadingModels ? (
                  <>
                    {existingProducts.length > 0 ? (
                      <Text strong>
                        Found {existingProducts.length} existing{' '}
                        {existingProducts.length === 1 ? 'model' : 'models'}.
                        Uploading new models...
                      </Text>
                    ) : (
                      <Text strong>Uploading models..</Text>
                    )}
                    <span>
                      {currentFileIndex} of {totalFiles} files |{' '}
                      {uploadedProducts.length === 0
                        ? '1'
                        : uploadedProducts.length}{' '}
                      of {totalModels} {totalModels === 1 ? 'model' : 'models'}
                    </span>
                  </>
                ) : isReplacingModels ? (
                  <>
                    <Text strong>
                      {replaceExistingChecked ? 'Uploading...' : 'Replacing...'}
                    </Text>
                    <span>
                      {currentReplaceFileIndex} of {totalReplaceFiles} files |{' '}
                      {replacedProducts.length === 0
                        ? '1'
                        : replacedProducts.length}{' '}
                      of {totalReplaceModels}{' '}
                      {replacedProducts.length === 1 ? 'model' : 'models'}
                    </span>
                  </>
                ) : null}
              </Space>

              {isUploadingModels ? (
                <Progress
                  status="active"
                  percent={
                    totalFiles > 0
                      ? Math.round((currentFileIndex / totalFiles) * 100)
                      : 0
                  }
                  strokeColor="#8E24AA"
                  trailColor="#C4C4C4"
                />
              ) : isReplacingModels ? (
                <Progress
                  status="active"
                  percent={
                    totalReplaceFiles > 0
                      ? Math.round(
                          (currentReplaceFileIndex / totalReplaceFiles) * 100
                        )
                      : 0
                  }
                  strokeColor="#8E24AA"
                  trailColor="#C4C4C4"
                />
              ) : null}
            </div>
          ) : showResult && !isCategorizingModels ? (
            <div className="products-table-wrapper">
              {needsReplacing ? (
                <Alert
                  message={`${existingProducts.length} Existing items were found.`}
                  type="error"
                  showIcon
                  closable
                  style={{ margin: '1rem 0' }}
                />
              ) : null}
              <Button
                danger
                type="primary"
                style={{ position: 'absolute', right: '17.5px', zIndex: 1 }}
                onClick={handleClear}
              >
                Clear
              </Button>

              {/* TABS */}
              <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                {existingProducts.length > 0 && !isUploadingModels ? (
                  // EXISITING ITEMS
                  <TabPane
                    tab={
                      <Space>
                        Existing Items
                        <Badge
                          count={existingProducts.length}
                          showZero
                          overflowCount={999999}
                        />
                      </Space>
                    }
                    key="0"
                  >
                    <div style={{ maxWidth: '312px', marginTop: '8px' }}>
                      <Input
                        width="312px"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search for a model..."
                        prefix={
                          <SearchOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        }
                      />
                    </div>
                    <UploadModelsTable
                      totalCount={filteredExistingProducts.length}
                      dataSource={filteredExistingProducts}
                      columns={uploadModelColumns}
                      headerTitle="Existing Products"
                      hasFooterReplaceExistingButton
                      hasFooterDeleteButton
                      hasFooterAddButton
                      replaceExistingProducts={handleReplaceExistingProducts}
                      tableHeight="calc(100vh - 250px)"
                    />
                  </TabPane>
                ) : (
                  // REPLACED ITEMS
                  <TabPane
                    tab={
                      <Space>
                        Replaced Items
                        <Badge
                          count={replacedProducts.length}
                          showZero
                          overflowCount={999999}
                        />
                      </Space>
                    }
                    key="1"
                  >
                    <div style={{ maxWidth: '312px', marginTop: '8px' }}>
                      <Input
                        width="312px"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search for a model..."
                        prefix={
                          <SearchOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        }
                      />
                    </div>
                    <UploadModelsTable
                      totalCount={filteredReplacedProducts.length}
                      dataSource={filteredReplacedProducts}
                      columns={uploadModelColumns}
                      headerTitle="Replaced Products"
                      hasFooterDeleteButton
                      hasFooterAddButton
                      tableHeight="calc(100vh - 250px)"
                    />
                  </TabPane>
                )}

                {/* UPLOADED ITEMS */}
                <TabPane
                  tab={
                    <Space>
                      Uploaded Items
                      <Badge
                        count={uploadedProducts.length}
                        showZero
                        overflowCount={999999}
                      />
                    </Space>
                  }
                  key="2"
                >
                  <div style={{ maxWidth: '312px', marginTop: '8px' }}>
                    <Input
                      width="312px"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search for a model..."
                      prefix={
                        <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                      }
                    />
                  </div>
                  <UploadModelsTable
                    totalCount={filteredUploadedProducts.length}
                    dataSource={filteredUploadedProducts}
                    columns={uploadModelColumns}
                    headerTitle="Uploaded Products"
                    hasFooterDeleteButton
                    hasFooterAddButton
                    tableHeight="calc(100vh - 250px)"
                  />
                </TabPane>
              </Tabs>
            </div>
          ) : null}
        </div>
      ) : (
        <NoPermission />
      )}
    </ViewWrapper>
  );
};

export default UploadModelsView;
