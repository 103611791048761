import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Alert, Form, Input, message, Modal, Space, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ApiError, handleError } from '../../../../api/base';
import { useAuth } from '../../../../hooks/useAuth';
import { CellModel } from '../../../../models/online-virtual-research';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createOvrCell,
  fetchOvrCells,
  updateOvrCell,
} from '../../../../store/features/cells/cellsSlice';
import {
  cancelAllUploads,
  createCancelTokenSource,
  deleteOvrProjectCellMediaFile,
  getSignedUrl,
  mediaServiceUploadNotification,
  setFileUploading,
} from '../../../../store/features/media/mediaSlice';
import { propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import './CustomFileForm.less';
import { CustomFileFormLocationState } from './types';

const { TextArea } = Input;

interface CustomFileFormProps {
  history: History;
  location: Location<CustomFileFormLocationState>;
}

const CustomFileForm = ({ history, location }: CustomFileFormProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const uploadingFiles = useAppSelector((state) => state.media.uploadingFiles);
  const fileId = location.state?.data?.uuid || 'new';
  const isUploading = uploadingFiles[fileId];
  const isNewFile = useMemo(() => !location.state, [location.state]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [addingFilesToQueue, setAddingFilesToQueue] = useState(false);
  const [jpgFiles, setJpgFiles] = useState<UploadFile<any>[]>([]);
  const [ovrFile, setOvrFile] = useState<UploadFile<any> | null>(null);
  const [rsoFile, setRsoFile] = useState<UploadFile<any> | null>(null);
  const [existingOvrFile, setExistingOvrFile] = useState<any>(null);
  const [existingRsoFile, setExistingRsoFile] = useState<any>(null);
  const [newOvrFile, setNewOvrFile] = useState<UploadFile<any> | null>(null);
  const [newRsoFile, setNewRsoFile] = useState<UploadFile<any> | null>(null);
  const fields = useMemo(
    () =>
      Object.keys(
        isNewFile ? { name: '', description: '' } : location.state?.data
      ),
    [isNewFile, location.state?.data]
  );

  const { userRoles } = useAuth();

  const hasClientRole = userRoles.some(
    (role) => role.name.toLowerCase() === 'client'
  );

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isUploading) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUploading]);

  useEffect(() => {
    if (!isNewFile && location.state?.data) {
      const { media_files } = location.state.data;
      if (media_files) {
        const ovrFile = media_files.find((file: any) =>
          file.filename.endsWith('.ovr')
        );
        const rsoFile = media_files.find((file: any) =>
          file.filename.endsWith('.rso')
        );
        setExistingOvrFile(ovrFile);
        setExistingRsoFile(rsoFile);
      }
    }
  }, [isNewFile, location.state]);

  const onSuccess = useCallback(async () => {
    await dispatch(
      fetchOvrCells({
        params: {
          status: 'completed',
          _columns: 'createdByUser.*,media_files.*',
        },
      })
    );
    setAddingFilesToQueue(false);

    if (
      window.location.pathname === '/ovr-file-management' &&
      window.location.hash === '#custom-file-form'
    ) {
      history.goBack();
    }

    setOvrFile(null);
    setRsoFile(null);
    setJpgFiles([]);
    message.success('Files uploaded.');
  }, [history, dispatch]);

  const onError = useCallback((error: ApiError) => {
    setAddingFilesToQueue(false);
    handleError(error);
  }, []);

  const uploadFileToS3 = useCallback(
    async (signedUrl: string, file: any) => {
      const source = createCancelTokenSource();

      try {
        await axios.put(signedUrl, file, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          cancelToken: source.token,
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Upload canceled by the user');
          throw new Error('Upload canceled by the user');
        } else {
          onError(error as ApiError);
          throw new Error(`S3 upload error for file: ${file?.name}`);
        }
      }
    },
    [onError]
  );

  const processFileUpload = useCallback(
    async (file: UploadFile<any>, cellId: string) => {
      try {
        // get signed url
        const signedUrlResponse = await dispatch(
          getSignedUrl({ fileName: file.name, cellId })
        ).unwrap();

        // upload file to s3
        await uploadFileToS3(signedUrlResponse.signedUrl, file);

        // notify media service
        await dispatch(
          mediaServiceUploadNotification({
            objectKey: signedUrlResponse.objectKey,
            name: file.name,
            size: file.size!,
            uuid: cellId,
            media_id: signedUrlResponse.media_id,
            media_version_id: signedUrlResponse.media_version_id,
            obj: { status: 'completed' },
          })
        );
      } catch (error) {
        throw new Error(`Failed to upload file: ${file.name}`);
      }
    },
    [dispatch, uploadFileToS3]
  );

  const uploadRequiredFiles = useCallback(
    async (cellId: string) => {
      try {
        if (newOvrFile) {
          await processFileUpload(newOvrFile, cellId);
        } else if (ovrFile) {
          await processFileUpload(ovrFile, cellId);
        }

        if (newRsoFile) {
          await processFileUpload(newRsoFile, cellId);
        } else if (rsoFile) {
          await processFileUpload(rsoFile, cellId);
        }
      } catch (error) {
        console.error('Failed to upload required files:', error);
        throw new Error('Failed to upload required files');
      }
    },
    [processFileUpload, newOvrFile, ovrFile, newRsoFile, rsoFile]
  );

  const uploadOptionalJpegFiles = useCallback(
    async (cellId: string) => {
      for (const file of jpgFiles) {
        try {
          await processFileUpload(file, cellId);
        } catch (error) {
          message.error(`Failed to upload optional file: ${file?.name}`);
          console.error('Optional file upload error:', error);
        }
      }
    },
    [jpgFiles, processFileUpload]
  );

  const deleteExistingFile = useCallback(
    async (file: any) => {
      if (file) {
        try {
          await dispatch(
            deleteOvrProjectCellMediaFile({
              ovrProjectCellId: location.state?.data?.uuid!,
              mediaVersionId: file.media_version_id,
              mediaId: file.id,
            })
          ).unwrap();
        } catch (error) {
          message.error(`Failed to delete existing ${file.filename}.`);
          console.error('Error deleting file:', error);
        }
      }
    },
    [dispatch, location.state?.data]
  );

  const onSaveCustomFile = useCallback(
    async (cell: CellModel) => {
      if ((!ovrFile && !newOvrFile) || (!rsoFile && !newRsoFile)) {
        message.error('Both OVR and RSO files are required.');
        return;
      }

      setAddingFilesToQueue(true);
      dispatch(setFileUploading({ fileId, isUploading: true }));
      let cellId = '';
      try {
        if (isNewFile) {
          const cellResponse = await dispatch(createOvrCell(cell)).unwrap();
          cellId = cellResponse.uuid;
        } else {
          cellId = location?.state?.data?.uuid || '';
          if (cellId) {
            await dispatch(
              updateOvrCell({
                cellId,
                cell,
                newFiles: {
                  ovrFile: newOvrFile || undefined,
                  rsoFile: newRsoFile || undefined,
                },
              })
            ).unwrap();
          }
        }

        setUploadingFile(true);
        await uploadRequiredFiles(cellId);
        await uploadOptionalJpegFiles(cellId);

        // Only delete existing files if new ones were successfully uploaded
        if (!isNewFile) {
          if (newOvrFile && existingOvrFile) {
            await deleteExistingFile(existingOvrFile);
          }
          if (newRsoFile && existingRsoFile) {
            await deleteExistingFile(existingRsoFile);
          }
        }

        onSuccess();
      } catch (error) {
        console.error('Failed to process cell:', error);
        message.error('Failed to process cell');
      } finally {
        setUploadingFile(false);
        setAddingFilesToQueue(false);
        dispatch(setFileUploading({ fileId, isUploading: false }));
      }
    },
    [
      ovrFile,
      rsoFile,
      newOvrFile,
      newRsoFile,
      isNewFile,
      uploadRequiredFiles,
      uploadOptionalJpegFiles,
      onSuccess,
      dispatch,
      location?.state?.data?.uuid,
      existingOvrFile,
      existingRsoFile,
      deleteExistingFile,
      fileId,
    ]
  );

  const onSubmit = useCallback(
    async (cell: CellModel) => {
      await form.validateFields(fields);
      onSaveCustomFile(cell);
    },
    [fields, form, onSaveCustomFile]
  );

  const handleOvrUpload = (ovrFile: UploadFile<any>) => {
    setNewOvrFile(ovrFile);
    return false;
  };

  const handleRsoUpload = (rsoFile: UploadFile<any>) => {
    setNewRsoFile(rsoFile);
    return false;
  };

  const handleJpgUpload = (jpgFile: UploadFile<any>) => {
    const isDuplicate = jpgFiles.some((f) => f.name === jpgFile.name);
    if (isDuplicate) {
      message.error('File already exists in the list.');
      return false;
    } else {
      setJpgFiles((prevList) => [...prevList, jpgFile]);
    }
    return false;
  };

  const handleRemove = (file: UploadFile<any>) => {
    if (file.uid === newOvrFile?.uid) {
      setNewOvrFile(null);
    } else if (file.uid === newRsoFile?.uid) {
      setNewRsoFile(null);
    } else {
      setJpgFiles(jpgFiles.filter((f) => f?.uid !== file.uid));
    }
  };

  const handleCancelUpload = useCallback(() => {
    Modal.confirm({
      title: 'Cancel Upload',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to cancel the ongoing upload?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        dispatch(cancelAllUploads());
        setOvrFile(null);
        setRsoFile(null);
        setJpgFiles([]);
      },
    });
  }, [dispatch]);

  const handleClose = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <FormWrapper title="Custom Files" onClose={handleClose}>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={
          isNewFile ? { name: '', description: '' } : location.state?.data
        }
        onFinish={onSubmit}
      >
        <Form.Item label="Cell Name" name="name">
          <Input placeholder="Cell name" />
        </Form.Item>
        <Form.Item label="Cell Description" name="description">
          <TextArea rows={6} placeholder="Cell description" />
        </Form.Item>
        {!hasClientRole && (
          <>
            {isUploading && !isNewFile && (
              <Alert
                className="cancel-alert"
                message="Upload in Progress"
                description="An upload is currently in progress. You cannot start new uploads for this file until the current one is complete."
                type="info"
                showIcon
                action={
                  <Button size="small" danger onClick={handleCancelUpload}>
                    Cancel Upload
                  </Button>
                }
              />
            )}
            {(isNewFile || !isUploading) && (
              <>
                <Form.Item
                  name="webgl_files"
                  valuePropName="ovrFiles"
                  getValueFromEvent={(e) => e.fileList}
                >
                  <Upload
                    beforeUpload={handleOvrUpload}
                    accept=".ovr"
                    maxCount={1}
                    multiple={false}
                    onRemove={handleRemove}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ minWidth: '160px' }}
                    >
                      Upload OVR
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  name="rso_files"
                  valuePropName="rsoFiles"
                  getValueFromEvent={(e) => e.fileList}
                >
                  <Upload
                    beforeUpload={handleRsoUpload}
                    accept=".rso"
                    maxCount={1}
                    multiple={false}
                    onRemove={handleRemove}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ minWidth: '160px' }}
                    >
                      Upload RSO
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item name="jpeg_files" valuePropName="jpgFiles">
                  <Upload
                    beforeUpload={handleJpgUpload}
                    onRemove={handleRemove}
                    fileList={jpgFiles}
                    accept=".jpg"
                    multiple
                    listType="picture"
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ minWidth: '160px' }}
                    >
                      Upload JPGs
                    </Button>
                  </Upload>
                </Form.Item>
              </>
            )}
          </>
        )}
        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
              // disabled={uploadingFile || addingFilesToQueue}
              disabled={
                uploadingFile ||
                addingFilesToQueue ||
                (isUploading && !isNewFile)
              }
              loading={addingFilesToQueue}
            >
              {isNewFile ? 'Add Custom File' : 'Update Custom File'}
            </Button>
            <Button onClick={handleClose} style={{ width: '100%' }}>
              Close Form
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(CustomFileForm, propsAreEqual));
