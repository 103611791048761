import { useAuth } from '../../../hooks/useAuth';
import { UserPermissions } from '../../../util/user';
import ViewWrapper from '../../elements/ViewWrapper';
import RecentOVRProjectsList from './components/RecentOVRProjectsList';
import RecentStoreBuildsList from './components/RecentStoreBuildsList';

const DashboardView = () => {
  const { hasPermission } = useAuth();

  return (
    <ViewWrapper headerTitle="Dashboard">
      {hasPermission(UserPermissions.FebDesignsView) && (
        <RecentStoreBuildsList />
      )}
      {hasPermission(UserPermissions.OvrProjectsView) && (
        <RecentOVRProjectsList />
      )}
    </ViewWrapper>
  );
};

export default DashboardView;
