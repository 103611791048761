import { Tabs } from 'antd';

import { useAuth } from '../../../hooks/useAuth';
import { UserPermissions } from '../../../util';
import NoPermission from '../../elements/NoPermission';
import ViewWrapper from '../../elements/ViewWrapper';
import BrandsTab from './components/BrandsTab/BrandsTab';
import CategoriesTab from './components/CategoriesTab/CategoriesTab';
import CountUnitTab from './components/CountUnitTab/CountUnitTab';
import FormatsTab from './components/FormatsTab/FormatsTab';
import ManufacturersTab from './components/ManufacturersTab/ManufacturersTab';
import MarketsTab from './components/MarketsTab/MarketsTab';
import ParentCompanyTab from './components/ParentCompanyTab/ParentCompanyTab';
import SizeUnitTab from './components/SizeUnitTab/SizeUnitTab';
import TagsTab from './components/TagsTab/TagsTab';
import './MetaDataView.less';

const { TabPane } = Tabs;

const MetaDataView = () => {
  const { hasPermission } = useAuth();

  if (!hasPermission(UserPermissions.MetaDataView)) {
    return <NoPermission />;
  }

  return (
    <ViewWrapper>
      <div className="meta-data-view-wrapper">
        <Tabs defaultActiveKey="1" destroyInactiveTabPane>
          <TabPane tab="Categories" key="1">
            <CategoriesTab />
          </TabPane>
          <TabPane tab="Tags" key="2">
            <TagsTab />
          </TabPane>
          <TabPane tab="Formats" key="3">
            <FormatsTab />
          </TabPane>
          <TabPane tab="Markets" key="4">
            <MarketsTab />
          </TabPane>
          <TabPane tab="Brands" key="5">
            <BrandsTab />
          </TabPane>
          <TabPane tab="Manufacturers" key="6">
            <ManufacturersTab />
          </TabPane>
          <TabPane tab="Parent Company" key="7">
            <ParentCompanyTab />
          </TabPane>
          <TabPane tab="Count Unit of Measurements" key="8">
            <CountUnitTab />
          </TabPane>
          <TabPane tab="Size Unit of Measurements" key="9">
            <SizeUnitTab />
          </TabPane>
        </Tabs>
      </div>
    </ViewWrapper>
  );
};

export default MetaDataView;
