import { BASE_API_URL } from '../../config';
import { UserModel } from '../../models/user';
import { store } from '../../store';
import {
  setUserPermissions,
  setUserRoles,
} from '../../store/features/users/usersSlice';
import { get, post, postAuth } from '../base';
import {
  AuthenticateRequest,
  ConfirmMFAResponse,
  MeResponse,
  MFAErrorResponse,
  MFAResponse,
  RequestMFAResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  Send2FACodeResponse,
  SendPasswordResetVerificationCodeResponse,
  VerifyResetCodeRequest,
  VerifyResetCodeResponse,
} from './reqres';

export default function AuthService() {
  return Object.freeze({
    async authenticate(
      req: AuthenticateRequest
    ): Promise<MFAResponse | MFAErrorResponse> {
      try {
        const authResponse = await postAuth(`${BASE_API_URL()}/auth/login`, {
          apiUser: req.apiUser,
          apiPass: req.apiPass,
          mfaToken: req.mfaToken,
        });

        // Save auth token
        AuthService().saveTokenToLocalStorage(authResponse.data.token);

        // Fetch and save user data, roles, and permissions
        const meResponse = await AuthService().getMe();
        AuthService().saveUserToLocalStorage(meResponse.data);

        // Update Redux store
        store.dispatch(setUserPermissions(meResponse.data.permissions_list));
        store.dispatch(setUserRoles(meResponse.data.roles));

        return {
          userMfaStatus: authResponse.data.user.mfa_status,
          clientMfaStatus: authResponse.data.user.clientMain.mfa_status,
        };
      } catch (err) {
        throw err;
      }
    },
    send2FACode(email: string): Promise<Send2FACodeResponse> {
      return post(`${BASE_API_URL()}/send2FACode`, { email });
    },
    getMe(): Promise<MeResponse> {
      return get(
        `${BASE_API_URL()}/user/me?_columns=permissions.*,roles.*,clientMain.*`
      );
    },
    saveTokenToLocalStorage(token: string): void {
      localStorage.setItem('token', token);
    },
    saveUserToLocalStorage(me: UserModel): void {
      localStorage.setItem('me', JSON.stringify(me));
    },
    logout(): void {
      // Clear Redux store
      store.dispatch(setUserPermissions([]));
      store.dispatch(setUserRoles([]));

      // Clear localStorage
      localStorage.clear();

      setTimeout(() => {
        window.location.replace(`${window.location.origin}/log-in`);
      }, 500);
    },
    sendPasswordResetVerificationCode(
      email: string
    ): Promise<SendPasswordResetVerificationCodeResponse> {
      return post(`${BASE_API_URL()}/sendResetPasswordCode`, { email });
    },
    verifyResetCode(
      body: VerifyResetCodeRequest
    ): Promise<VerifyResetCodeResponse> {
      return post(`${BASE_API_URL()}/verifyResetCode`, body);
    },
    resetPassword(body: ResetPasswordRequest): Promise<ResetPasswordResponse> {
      return post(`${BASE_API_URL()}/resetPassword`, body);
    },
    async requestMFA(
      credentials: AuthenticateRequest
    ): Promise<RequestMFAResponse> {
      return postAuth(`${BASE_API_URL()}/user/requestMfa`, credentials) as any;
    },
    confirmMFA(body: { mfaToken: string }): Promise<ConfirmMFAResponse> {
      return post(`${BASE_API_URL()}/user/confirmMfa`, {
        mfaToken: body.mfaToken,
      }) as any;
    },
    getCurrentUserId(): string {
      const me = localStorage.getItem('me');
      if (!me) throw new Error('No user data found');
      return JSON.parse(me).uuid;
    },
    getCurrentUser(): UserModel {
      const me = localStorage.getItem('me');
      if (!me) throw new Error('No user data found');
      return JSON.parse(me);
    },
    getAuthToken(): string {
      return localStorage.getItem('token')!;
    },
  });
}
