import React, { memo, useCallback, useMemo, useState } from 'react';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  ConfigProvider,
  Modal,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Link, withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { ListProductLibraryModel } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  deleteProductFromMasterLibrarianList,
  fetchProducts,
  paginateProductLibrary,
} from '../../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import { setSelectedProducts } from '../../../../store/features/productList/productListSlice';
import { propsAreEqual } from '../../../../util';
import { DrawerHashRoute } from '../../../containers/Drawers/types';
import { customizeRenderEmpty } from '../../../elements/CustomizeEmptyRender/customizeRenderEmpty';
import './MasterLibrarianListTable.less';

const { Title } = Typography;

interface MasterLibrarianListTableProps {
  dataSource: ListProductLibraryModel[];
  headerTitle: string;
  columns: ColumnsType<ListProductLibraryModel>;
  noDataDescription?: string;
  tableHeight?: string;
  hasFooterDeleteButton?: boolean;
  hasFooterBulkEditButton?: boolean;
  totalCount: number;
}

const MasterLibrarianListTable = ({
  dataSource,
  headerTitle,
  columns,
  noDataDescription,
  tableHeight,
  hasFooterDeleteButton,
  hasFooterBulkEditButton,
  totalCount,
}: MasterLibrarianListTableProps) => {
  const [selectedProductRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    []
  );
  const [processing, setProcessing] = useState(false);

  const dispatch = useAppDispatch();
  const { selectedProducts } = useAppSelector((state) => state.productList);
  const {
    fetchingProductLibraryItems,
    searchingProductLibrary,
    searchQuery,
    currentPage,
    pageSize,
    deletingProduct,
  } = useAppSelector((state) => state.masterLibrarianList);

  const loading = useMemo(
    () =>
      fetchingProductLibraryItems || searchingProductLibrary || deletingProduct,
    [fetchingProductLibraryItems, searchingProductLibrary, deletingProduct]
  );

  const header = useMemo(
    (): JSX.Element | null =>
      headerTitle ? (
        <div className="products-table-header">
          <Title level={3}>{headerTitle}</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
          />
        </div>
      ) : null,
    [headerTitle, totalCount]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const handleOnChange = useCallback(
    (
      newSelectedRowKeys: React.Key[],
      selectedRows: ListProductLibraryModel[]
    ) => {
      setSelectedRowKeys(newSelectedRowKeys);
      dispatch(setSelectedProducts(selectedRows));
    },
    [dispatch]
  );

  const handleDeleteSelectedProducts = useCallback(
    async (productsToBeDeleted: any) => {
      try {
        setProcessing(true);
        message.loading({
          content: 'Deleting products...',
          key: 'deleting-products-message',
          duration: 0,
        });

        for (const product of productsToBeDeleted) {
          const resp = await dispatch(
            deleteProductFromMasterLibrarianList(product.uuid)
          );
          if (resp.error) {
            throw new Error(resp.payload.error);
          }
        }

        message.destroy('deleting-products-message');
        await dispatch(fetchProducts(searchQuery));
        message.success(
          `Successfully deleted ${productsToBeDeleted.length} products`
        );
      } catch (error) {
        handleError(error as ApiError);
      } finally {
        handleClearSelection();
        setProcessing(false);
      }
    },
    [dispatch, handleClearSelection, searchQuery]
  );

  const handleConfirmDeleteProduct = useCallback(
    (e: any, product: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the list or update it after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteSelectedProducts(product),
      });
    },
    [handleDeleteSelectedProducts]
  );

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      dispatch(
        paginateProductLibrary({
          page,
          pageSize,
          status: 'needs_approval',
        })
      );
    },
    [dispatch]
  );

  const hasSelected = selectedProductRowKeys.length > 0;

  const rowSelection: TableRowSelection<ListProductLibraryModel> = {
    selectedRowKeys: selectedProductRowKeys,
    onChange: handleOnChange,
    preserveSelectedRowKeys: true,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const paginationSettings = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: totalCount,
    current: currentPage,
    pageSize: pageSize,
  };

  const tableFooter = () => (
    <>
      <div className="table-footer-wrapper">
        <div className="table-footer-left">
          <span>
            {hasSelected
              ? `${selectedProductRowKeys.length} ${
                  selectedProductRowKeys.length === 1 ? 'Item' : 'Items'
                } selected: `
              : ''}
          </span>
          <Space size="middle">
            {hasFooterDeleteButton && (
              <Button
                type="primary"
                onClick={(e) => handleConfirmDeleteProduct(e, selectedProducts)}
                style={{ minWidth: '200px' }}
                icon={processing ? <LoadingOutlined /> : null}
              >
                {processing ? 'Deleting...' : 'Delete Selected Product(s)'}
              </Button>
            )}

            {hasFooterBulkEditButton && (
              <Link to={DrawerHashRoute.BulkEditProductsForm}>
                <Button type="primary" loading={false}>
                  Bulk Edit
                </Button>
              </Link>
            )}
          </Space>
        </div>
        <div className="table-footer-right">
          <Button
            type="link"
            onClick={handleClearSelection}
            className="link-button"
          >
            Clear Selection
          </Button>
        </div>
      </div>
    </>
  );

  const footerContent = hasSelected ? tableFooter : undefined;

  return (
    <div className="products-table-list-wrapper">
      {header}
      <ConfigProvider
        renderEmpty={() => customizeRenderEmpty(noDataDescription)}
      >
        <Table
          className="products-table-list"
          footer={footerContent}
          scroll={{
            y: tableHeight || 240,
            scrollToFirstRowOnChange: false,
          }}
          rowSelection={rowSelection}
          loading={loading}
          rowKey={(product: ListProductLibraryModel) =>
            `${product.uuid}-row-key`
          }
          pagination={paginationSettings}
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: customizeRenderEmpty(noDataDescription) }}
        />
      </ConfigProvider>
    </div>
  );
};

export default withRouter<any, any>(
  memo(MasterLibrarianListTable, propsAreEqual)
);
