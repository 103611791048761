import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FiltersType } from '../../components/views/ProductLibraryView/components/ProductsFilters/ProductsFilters';
import { ListProductLibraryModel } from '../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../store';
import { filterProducts } from '../../store/features/productLibrary/productLibrarySlice';
import {
  addProductItemToList,
  addProductsToProductList,
  removeProductItemFromList,
  removeProductsFromProductList,
} from '../../store/features/productList/productListSlice';
import { ListProductLibraryParams } from './list-product-library';

export const useProductListOperations = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [loadingItems, setLoadingItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [isAddingProducts, setIsAddingProducts] = useState(false);
  const [isRemovingProducts, setIsRemovingProducts] = useState(false);

  const {
    currentPage,
    pageSize,
    selectedCategory,
    selectedFilters,
    searchQuery,
    selectedMarket,
  } = useAppSelector((state) => state.productLibrary);

  const isProductLibrary = location.pathname === '/product-library';

  const buildFilterParams = useCallback((): ListProductLibraryParams => {
    let params: ListProductLibraryParams = {
      _limit: pageSize,
      _offset: (currentPage! - 1) * pageSize!,
      _order_by: 'updated_at:desc',
      status: 'approved',
      'market[]': selectedMarket,
    };

    if (selectedCategory && selectedCategory !== 'All') {
      params.category = selectedCategory.key;
    }

    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((filterGroup) => {
        const filterKey = filterGroup as keyof FiltersType;
        if (selectedFilters[filterKey]?.length) {
          params = {
            ...params,
            [filterGroup]: selectedFilters[filterKey],
          };
        }
      });
    }

    return params;
  }, [
    currentPage,
    pageSize,
    selectedCategory,
    selectedFilters,
    selectedMarket,
  ]);

  const refreshProductLibrary = useCallback(async () => {
    if (isProductLibrary) {
      await dispatch(
        filterProducts({
          params: buildFilterParams(),
          query: searchQuery,
          maintainCurrentPage: true,
        })
      );
    }
  }, [dispatch, buildFilterParams, searchQuery, isProductLibrary]);

  const handleAddSingleProduct = useCallback(
    async (productItem: ListProductLibraryModel) => {
      setLoadingItems((prev) => ({ ...prev, [productItem.uuid]: true }));
      try {
        await dispatch(addProductItemToList(productItem));
        await refreshProductLibrary();
      } catch (error) {
        console.error('Error adding product item to list:', error);
      } finally {
        setLoadingItems((prev) => ({ ...prev, [productItem.uuid]: false }));
      }
    },
    [dispatch, refreshProductLibrary]
  );

  const handleAddMultipleProducts = useCallback(
    async (products: ListProductLibraryModel[]) => {
      try {
        setIsAddingProducts(true);
        const productIds = products.map((product) => product.uuid);
        await dispatch(addProductsToProductList(productIds));
        await refreshProductLibrary();
      } catch (error) {
        console.error('Error adding products to list:', error);
      } finally {
        setIsAddingProducts(false);
      }
    },
    [dispatch, refreshProductLibrary]
  );

  const handleRemoveSingleProduct = useCallback(
    async (productItem: ListProductLibraryModel) => {
      setLoadingItems((prev) => ({ ...prev, [productItem.uuid]: true }));
      try {
        await dispatch(removeProductItemFromList(productItem.uuid));
        await refreshProductLibrary();
      } catch (error) {
        console.error('Error removing product item from list:', error);
      } finally {
        setLoadingItems((prev) => ({ ...prev, [productItem.uuid]: false }));
      }
    },
    [dispatch, refreshProductLibrary]
  );

  const handleRemoveMultipleProducts = useCallback(
    async (products: ListProductLibraryModel[]) => {
      try {
        setIsRemovingProducts(true);
        const productIds = products.map((product) => product.uuid);
        await dispatch(removeProductsFromProductList(productIds));
        await refreshProductLibrary();
      } catch (error) {
        console.error('Error removing products from list:', error);
      } finally {
        setIsRemovingProducts(false);
      }
    },
    [dispatch, refreshProductLibrary]
  );

  return {
    loadingItems,
    isAddingProducts,
    isRemovingProducts,
    handleAddSingleProduct,
    handleAddMultipleProducts,
    handleRemoveSingleProduct,
    handleRemoveMultipleProducts,
  };
};
