import { Result } from 'antd';

const NoPermission = () => {
  return (
    <Result
      status="403"
      title="No Permission"
      subTitle="Sorry, you are not authorized to access this page."
    />
  );
};

export default NoPermission;
