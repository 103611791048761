import {
  Button,
  Cascader,
  CascaderProps,
  Drawer,
  Input,
  Pagination,
  Select,
  Space,
} from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { DefaultOptionType } from 'antd/lib/select';
import React, { useEffect, useMemo, useState } from 'react';
import { WBMLSearchParams } from '../../../api/wbml/reqres';
import { WBMLMetadata } from '../../../api/wbml/service';
import useDebounce from '../../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  fetchAllWBMLMetadata,
  fetchWBMLCategories,
  searchWBMLProducts,
} from '../../../store/features/wbml/wbmlSlice';
import NoResultsFound from '../../elements/NoResultsFound';
import InitialSearchState from './InitialSearchState';
import ProductFilters from './ProductFilters';
import './ProductsPanel.less';
import SearchResults from './SearchResults';

const { Search } = Input;
const { Option } = Select;

interface ProductsPanelProps {
  onClose: () => void;
  position?: {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
  };
}

interface CategoryOption {
  value: string;
  label: string;
  children?: CategoryOption[];
}

const sortOptions = [
  { value: 'asc', label: 'UPC ASC' },
  { value: 'desc', label: 'UPC DESC' },
];

const ProductsPanel: React.FC<ProductsPanelProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const {
    products,
    loading,
    categories,
    pagination,
    categoriesLoading,
    // unityPosition,
    // iframeUnityDimensions,b
    metadata: {
      brands = [],
      manufacturers = [],
      parentCompanies = [],
      markets = [],
      formats = [],
      tags = [],
    },
  } = useAppSelector((state) => state.wbml);
  const [searchState, setSearchState] = useState<
    'initial' | 'results' | 'no-results'
  >('initial');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showingFilters, setShowingFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, string[]>
  >({});

  const debouncedSearchQuery = useDebounce(searchQuery, 1000);

  const filters = useMemo(
    () => [
      {
        name: 'BRANDS',
        options: brands.map((b: WBMLMetadata) => ({
          label: b.name,
          value: b.id,
        })),
      },
      {
        name: 'MANUFACTURER',
        options: manufacturers.map((m: WBMLMetadata) => ({
          label: m.name,
          value: m.id,
        })),
      },
      {
        name: 'PARENT COMPANY',
        options: parentCompanies.map((p: WBMLMetadata) => ({
          label: p.name,
          value: p.id,
        })),
      },
      {
        name: 'MARKETS',
        options: markets.map((m: WBMLMetadata) => ({
          label: m.name,
          value: m.id,
        })),
      },
      {
        name: 'FORMATS',
        options: formats.map((f: WBMLMetadata) => ({
          label: f.name,
          value: f.id,
        })),
      },
      {
        name: 'TAGS',
        options: tags.map((t: WBMLMetadata) => ({
          label: t.name,
          value: t.id,
        })),
      },
    ],
    [brands, manufacturers, parentCompanies, markets, formats, tags]
  );

  const categoryOptions = useMemo(() => {
    const categoryMap = new Map<string, CategoryOption>();

    categories.forEach((category) => {
      categoryMap.set(category.id, {
        value: category.id,
        label: category.name,
        children: [],
      });
    });

    categories.forEach((category) => {
      if (category.parent_name) {
        const parentCategory = categories.find(
          (c) => c.name === category.parent_name
        );
        if (parentCategory) {
          const parent = categoryMap.get(parentCategory.id);
          const child = categoryMap.get(category.id);
          if (parent && child) {
            parent.children = parent.children || [];
            parent.children.push(child);
          }
        }
      }
    });

    return Array.from(categoryMap.values()).filter((categoryOption) => {
      const category = categories.find((c) => c.id === categoryOption.value);
      return !category?.parent_name;
    });
  }, [categories]);

  const getCategoryPath = (categoryId: string): string[] => {
    const result: string[] = [];
    let currentCategory = categories.find((c) => c.id === categoryId);

    while (currentCategory) {
      result.unshift(currentCategory.id);
      if (currentCategory.parent_name) {
        currentCategory = categories.find(
          (c) => c.name === currentCategory?.parent_name
        );
      } else {
        currentCategory = undefined;
      }
    }

    return result;
  };

  const handleCategoryClear = () => {
    setSelectedCategory('');
    setCurrentPage(1);
  };

  const handleCategoryChange: CascaderProps['onChange'] = (
    value: (string | number)[]
  ) => {
    if (!value || value.length === 0) {
      handleCategoryClear();
      return;
    }
    const newCategory = value[value.length - 1] as string;
    setSelectedCategory(newCategory);
    setCurrentPage(1);
  };

  const categoryFilter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  useEffect(() => {
    dispatch(fetchWBMLCategories());
  }, [dispatch]);

  useEffect(() => {
    const performSearch = async () => {
      if (!debouncedSearchQuery && !selectedCategory) {
        setSearchState('initial');
        return;
      }

      const params: WBMLSearchParams = {
        term: debouncedSearchQuery,
        ...(selectedCategory && { categoryIDs: selectedCategory }),
        ...(selectedFilters['BRANDS']?.length && {
          brandIDs: selectedFilters['BRANDS'].join(','),
        }),
        ...(selectedFilters['MANUFACTURER']?.length && {
          manufacturerIDs: selectedFilters['MANUFACTURER'].join(','),
        }),
        ...(selectedFilters['PARENT COMPANY']?.length && {
          parentCompanyIDs: selectedFilters['PARENT COMPANY'].join(','),
        }),
        page: currentPage.toString(),
        pageSize: pageSize.toString(),
      };

      try {
        const result = await dispatch(
          searchWBMLProducts({
            params,
            page: currentPage,
            pageSize,
          })
        ).unwrap();
        setSearchState(result.products.length > 0 ? 'results' : 'no-results');
      } catch (error) {
        console.error('Search failed:', error);
        setSearchState('no-results');
      }
    };

    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchQuery,
    selectedCategory,
    selectedFilters,
    currentPage,
    pageSize,
    dispatch,
  ]);

  const handleClearSearch = () => {
    setSearchQuery('');
    setSelectedCategory('');
    setSelectedFilters({});
    setSearchState('initial');
    setCurrentPage(1);
  };

  const handleFilterClick = () => {
    dispatch(fetchAllWBMLMetadata());
    setShowingFilters(true);
  };

  const handleFilterChange = (
    category: string,
    values: CheckboxValueType[]
  ) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: values as string[],
    }));
  };

  const clearFilters = () => {
    setSelectedFilters({});
  };

  const getSelectedFiltersCount = () => {
    return Object.values(selectedFilters).flat().length;
  };

  const handleSortChange = (value: string) => {
    setSortOrder(value as 'asc' | 'desc');
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // const contentWrapperStyle = useMemo(() => {
  //   if (!unityPosition) return {};

  //   const styles = {
  //     position: 'absolute' as const,
  //     border: '5px solid lime',
  //     '--drawer-right': `40px`,
  //     // '--drawer-right': `${unityPosition.xMax}px`,
  //     '--drawer-width': `${unityPosition.xMax - unityPosition.xMin}px`,
  //     '--drawer-height': `100%`,
  //     // '--drawer-height': `${unityPosition.yMax}px`,
  //     '--drawer-left': `${unityPosition.xMin}px`,
  //     '--drawer-top': `${unityPosition.yMax}px`,
  //   } as React.CSSProperties;

  //   return styles;
  // }, [unityPosition]);

  return (
    <Drawer
      title={showingFilters ? 'Filter' : 'Product Search'}
      onClose={showingFilters ? () => setShowingFilters(false) : onClose}
      open={true}
      maskClosable={false}
      className="products-panel-drawer"
      // contentWrapperStyle={contentWrapperStyle}
      footer={
        showingFilters ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            {getSelectedFiltersCount() > 0 && (
              <Button block onClick={clearFilters}>
                Clear All Filters ({getSelectedFiltersCount()})
              </Button>
            )}
            <Button
              type="primary"
              block
              onClick={() => setShowingFilters(false)}
            >
              Apply Filter(s)
            </Button>
          </Space>
        ) : null
      }
    >
      {!showingFilters ? (
        <div className="products-panel-content">
          <div className="filter-controls">
            <Cascader
              options={categoryOptions}
              onChange={handleCategoryChange}
              placeholder="Select a category"
              showSearch={{ filter: categoryFilter }}
              style={{ width: '100%', marginBottom: '10px' }}
              allowClear
              changeOnSelect
              value={selectedCategory ? getCategoryPath(selectedCategory) : []}
              dropdownMenuColumnStyle={{ width: 200 }}
              expandTrigger="hover"
              loading={categoriesLoading}
              notFoundContent={categoriesLoading ? 'Loading...' : 'No Data'}
            />
            <Search
              enterButton={true}
              className="product-search-input"
              placeholder="Search products..."
              value={searchQuery}
              allowClear
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              loading={loading}
            />
            <div className="filter-controls-row">
              <Space.Compact style={{ width: '100%' }}>
                <Select
                  style={{ width: '50%' }}
                  value={sortOrder}
                  onChange={handleSortChange}
                  placeholder="Sort by UPC"
                >
                  {sortOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  onClick={handleFilterClick}
                  style={{ width: '50%' }}
                >
                  Filter{' '}
                  {getSelectedFiltersCount() > 0 &&
                    `(${getSelectedFiltersCount()})`}
                </Button>
              </Space.Compact>
            </div>
          </div>
          {searchState === 'initial' && <InitialSearchState />}
          {searchState === 'results' && (
            <>
              <SearchResults
                searchQuery={searchQuery}
                resultCount={pagination?.total || 0}
                products={products}
                sortOrder={sortOrder}
                selectedCategory={selectedCategory}
              />
              <div className="pagination-container">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={pagination?.total || 0}
                  onChange={handlePageChange}
                  size="small"
                  defaultCurrent={1}
                  showSizeChanger={true}
                />
              </div>
            </>
          )}
          {searchState === 'no-results' && (
            <NoResultsFound
              searchQuery={searchQuery}
              onClear={handleClearSearch}
              selectedCategory={selectedCategory}
            />
          )}
        </div>
      ) : (
        <ProductFilters
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
          onClearFilters={clearFilters}
          filterCategories={filters}
        />
      )}
    </Drawer>
  );
};

export default ProductsPanel;
