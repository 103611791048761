export const getMousePosition = (
  event: MouseEvent,
  sidebarCollapsed: boolean
) => {
  const sidebarWidth = sidebarCollapsed ? 80 : 250;
  return {
    x: event.clientX - sidebarWidth,
    y: event.clientY,
    sidebarWidth,
  };
};

export const convertToUnityCoordinates = (
  mouseX: number,
  mouseY: number,
  viewportHeight: number
) => {
  return {
    x: Math.round(mouseX),
    y: Math.round(viewportHeight - mouseY),
  };
};
