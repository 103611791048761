import { LoadingOutlined } from '@ant-design/icons';
import { Input, Space, Spin, Tooltip } from 'antd';
import cx from 'classnames';
import { useCallback } from 'react';
import { ReactComponent as GridViewIcon } from '../../../../../assets/svgs/grid-view-icon.svg';
import { ReactComponent as TableViewIcon } from '../../../../../assets/svgs/list-view-icon.svg';
import MultiLevelSelect from '../../../../elements/MultiLevelSelect';
import { SelectDataItem } from '../../../../elements/MultiLevelSelect/types';
import { ProductLibraryListViewType } from '../ProductLibraryItemsList/ProductLibraryItemsList';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
const { Search } = Input;

interface ProductLibrarySearchBarProps {
  searchingProducts: boolean;
  searchQuery: string;
  updateSearchQuery: (query: string) => void;
  viewType: ProductLibraryListViewType;
  setViewType: (viewType: ProductLibraryListViewType) => void;
  productLibraryListViewTypeKey: string;
  categoryFilters: SelectDataItem[];
  loading: boolean;
  onSelectCategoryFilter: (
    selectedCategory: SelectDataItem | 'All' | undefined
  ) => void;
  selected?: string;
}

const SearchBar = ({
  searchingProducts,
  searchQuery,
  updateSearchQuery,
  viewType,
  setViewType,
  productLibraryListViewTypeKey,
  categoryFilters = [],
  loading = false,
  onSelectCategoryFilter,
  selected,
}: ProductLibrarySearchBarProps) => {
  const updateProductLibraryListViewType = useCallback(
    (type: ProductLibraryListViewType) => {
      setViewType(type);
      localStorage.setItem(productLibraryListViewTypeKey, type);
    },
    [productLibraryListViewTypeKey, setViewType]
  );

  return (
    <div className="product-library__search-bar">
      <div className="product-library__search-bar-left">
        <MultiLevelSelect
          data={categoryFilters}
          loading={loading}
          onChange={onSelectCategoryFilter}
          selected={selected}
          buttonMaxWidth="250px"
        />
        <Search
          className="product-library__search-input"
          allowClear={!searchingProducts}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          enterButton
          placeholder="Search for products..."
          suffix={searchingProducts && <Spin indicator={antSpinIcon} />}
        />
      </div>
      <Space size="middle" className="product-library__search-bar-right">
        <Tooltip title="Grid view" mouseEnterDelay={0.2} mouseLeaveDelay={0}>
          <div
            className={cx('product-library__view-type-icon', {
              'product-library__view-type-icon--selected':
                viewType === ProductLibraryListViewType.Grid,
            })}
            onClick={() =>
              updateProductLibraryListViewType(ProductLibraryListViewType.Grid)
            }
          >
            <GridViewIcon />
          </div>
        </Tooltip>
        <Tooltip title="Table view" mouseEnterDelay={0.2} mouseLeaveDelay={0}>
          <div
            className={cx('product-library__view-type-icon', {
              'product-library__view-type-icon--selected':
                viewType === ProductLibraryListViewType.Table,
            })}
            onClick={() =>
              updateProductLibraryListViewType(ProductLibraryListViewType.Table)
            }
          >
            <TableViewIcon />
          </div>
        </Tooltip>
      </Space>
    </div>
  );
};

export default SearchBar;
