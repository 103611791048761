import { memo, useCallback, useContext, useMemo } from 'react';

import {
  BarChartOutlined,
  BuildOutlined,
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FileTextOutlined,
  HomeOutlined,
  PicCenterOutlined,
  SettingOutlined,
  StarOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Tooltip } from 'antd';
import cx from 'classnames';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import './Sidebar.less';

import { ReactComponent as CylinderIcon } from '../../../assets/svgs/cylinder-icon.svg';
import ReadySetIcon from '../../../assets/svgs/readyset-icon-white.svg';
import ReadySetLogo from '../../../assets/svgs/readyset-logo-white.svg';
import { ReactComponent as RolesIcon } from '../../../assets/svgs/roles-icon.svg';
import { useAuth } from '../../../hooks/useAuth';
import { UserPermissions, isTabletScreenSizeOrSmaller } from '../../../util';
import { AppLayoutContext } from '../../views/AppRoot/AppRoot';
import { RoutePath } from '../../views/AppRoot/types';
import SelectClientDropdown from './components/SelectClientDropdown';
import UserProfileDropdown from './components/UserProfileDropdown';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SidebarProps extends RouteComponentProps {
  onToggle: () => void;
}

interface MenuPermission {
  route: string;
  permission: UserPermissions | UserPermissions[];
  title: string;
  icon?: React.ReactNode;
  children?: MenuPermission[];
}

const MENU_CONFIG: MenuPermission[] = [
  {
    route: RoutePath.Dashboard,
    permission: '' as UserPermissions,
    title: 'Home',
    icon: <HomeOutlined className="sidebar-icon" />,
  },
  {
    route: RoutePath.Products,
    permission: UserPermissions.ModelView,
    title: 'Products',
    icon: (
      <span className="submenu-icon">
        <CylinderIcon className="submenu-icon-svg" />
      </span>
    ),
    children: [
      {
        route: RoutePath.Planogram,
        permission: '' as UserPermissions,
        title: 'Planogram',
      },
      {
        route: RoutePath.ProductLibrary,
        permission: UserPermissions.ModelView,
        title: 'Product Library',
      },
      {
        route: RoutePath.ProductList,
        permission: UserPermissions.ModelView,
        title: 'Product List',
      },
      {
        route: RoutePath.MasterLibrarianList,
        permission: UserPermissions.ModelViewUnapproved,
        title: 'Master Librarian List',
      },
      {
        route: RoutePath.UploadModels,
        permission: UserPermissions.ModelUpload,
        title: 'Upload Models',
      },
      {
        route: RoutePath.POGChecker,
        permission: UserPermissions.PogCheckerView,
        title: 'POG Checker',
      },
      {
        route: RoutePath.MetaData,
        permission: UserPermissions.MetaDataView,
        title: 'Meta Data',
      },
      {
        route: RoutePath.Status,
        permission: UserPermissions.ModelViewLibraryStatistics,
        title: 'Status',
      },
    ],
  },
  {
    route: RoutePath.OVR,
    permission: UserPermissions.OvrProjectsView,
    title: 'Online Virtual Research',
    icon: <BarChartOutlined className="sidebar-icon" />,
    children: [
      {
        route: RoutePath.OvrFileManagement,
        permission: UserPermissions.PortalFileManagementView,
        title: 'Cells',
      },
      {
        route: RoutePath.OVR,
        permission: UserPermissions.OvrProjectsView,
        title: 'Projects',
      },
    ],
  },
  {
    route: RoutePath.Clients,
    permission: UserPermissions.ClientsView,
    title: 'Clients',
    icon: <StarOutlined className="sidebar-icon" />,
  },
  {
    route: RoutePath.StoreBuilder,
    permission: UserPermissions.FebDesignsView,
    title: 'Front End Builder',
    icon: <BuildOutlined className="sidebar-icon" />,
  },
  {
    route: RoutePath.Manual,
    permission: '' as UserPermissions,
    title: 'Manuals / Videos',
    icon: <FileTextOutlined className="sidebar-icon" />,
  },
  {
    route: RoutePath.Users,
    permission: UserPermissions.UsersView,
    title: 'Users',
    icon: <TeamOutlined className="sidebar-icon" />,
  },
  {
    route: RoutePath.Roles,
    permission: UserPermissions.RolesView,
    title: 'Roles',
    icon: (
      <span className="custom-submenu-icon-wrapper">
        <RolesIcon
          className="custom-icon-svg roles-icon"
          width={24}
          height={24}
        />
      </span>
    ),
  },
  {
    route: RoutePath.CustomFields,
    permission: [
      UserPermissions.CustomFieldsView,
      UserPermissions.CustomFieldsCreate,
      UserPermissions.CustomFieldsEdit,
      UserPermissions.CustomFieldsDelete,
    ],
    title: 'Custom Fields',
    icon: <PicCenterOutlined className="sidebar-icon" />,
  },
  {
    route: RoutePath.SystemConfigurations,
    permission: UserPermissions.SystemConfigurationView,
    title: 'System Configurations',
    icon: <SettingOutlined className="sidebar-icon" />,
  },
];

const Sidebar = ({ location, onToggle }: SidebarProps) => {
  const { sidebarCollapsed } = useContext(AppLayoutContext);
  const { hasPermission } = useAuth();

  const isTablet = useMemo(() => isTabletScreenSizeOrSmaller(), []);

  const getKey = useCallback(
    (pathname: string): string => {
      if (location.pathname.includes(pathname)) {
        return location.pathname;
      }
      return pathname;
    },
    [location.pathname]
  );

  const sidebarClass = cx('sidebar-container', {
    hidden: isTablet && sidebarCollapsed,
  });

  const renderMenuItem = (item: MenuPermission) => {
    if (!hasPermission(item.permission)) return null;

    if (item.children) {
      return (
        <SubMenu key={getKey(item.route)} icon={item.icon} title={item.title}>
          {item.children.map((child) => renderMenuItem(child))}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={getKey(item.route)} icon={item.icon}>
        <Link to={item.route}>{item.title}</Link>
      </Menu.Item>
    );
  };

  return (
    <div
      className={`sidebar-background ${sidebarCollapsed ? 'closed' : 'open'}`}
    >
      <Sider
        breakpoint="lg"
        collapsedWidth={80}
        width={isTablet ? '100%' : sidebarCollapsed ? 80 : 250}
        trigger={
          <>
            <div
              className="sidebar-footer"
              onClick={(e) => e.stopPropagation()}
            >
              <UserProfileDropdown collapsed={sidebarCollapsed!} />
            </div>
            {sidebarCollapsed ? (
              <div style={{ height: '46px', padding: '4px' }}>
                <Tooltip placement="right" title="Expand sidebar">
                  <DoubleRightOutlined />
                </Tooltip>
              </div>
            ) : (
              <div className="collapse-action-wrapper">
                <div className="collapse-icon-wrapper">
                  <DoubleLeftOutlined />
                </div>
                <div>Collapse sidebar</div>
              </div>
            )}
          </>
        }
        collapsible
        collapsed={sidebarCollapsed}
        className={sidebarClass}
        onCollapse={onToggle}
      >
        <header className="sidebar-header">
          <Link to={RoutePath.Dashboard}>
            <img
              src={sidebarCollapsed ? ReadySetIcon : ReadySetLogo}
              alt="ReadySet logo"
              className="logo"
            />
          </Link>
          {isTablet && (
            <CloseOutlined onClick={onToggle} className="sidebar-icon large" />
          )}
        </header>

        {hasPermission(UserPermissions.UserSwitchClients) && (
          <SelectClientDropdown collapsed={sidebarCollapsed!} />
        )}

        <Menu
          mode="inline"
          selectedKeys={[location.pathname]}
          onClick={isTablet ? onToggle : undefined}
          inlineCollapsed={sidebarCollapsed}
        >
          {MENU_CONFIG.map((item) => renderMenuItem(item))}
        </Menu>
      </Sider>
    </div>
  );
};

export default withRouter(memo(Sidebar));
