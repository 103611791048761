import {
  createRef,
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { History } from 'history';
import _ from 'lodash';
import { Link, useLocation, withRouter } from 'react-router-dom';
import './UserTableList.less';

import { ApiError, handleError } from '../../../../../api/base';
import { useAuth } from '../../../../../hooks/useAuth';
import { UserModel } from '../../../../../models/user';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { fetchClients } from '../../../../../store/features/clients/clientsSlice';
import { deleteUser } from '../../../../../store/features/users/usersSlice';
import {
  formatDate,
  propsAreEqual,
  UserPermissions,
} from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import LazyLoad from '../../../../elements/LazyLoad';

const { Text } = Typography;

interface UserTableListProps {
  users: UserModel[];
  loading: boolean;
  onLoadMore: () => void;
  hasMore: boolean;
  history: History;
}

const UserTableList = ({
  users,
  loading,
  onLoadMore,
  hasMore,
  history,
}: UserTableListProps) => {
  const [filterReset, setFilterReset] = useState(false);
  const { hasPermission } = useAuth();

  const location = useLocation();
  const currentPath = useMemo(() => location.pathname, [location]);
  const dispatch = useAppDispatch();
  const { clients } = useAppSelector((state) => state.clients);
  const { userRoles } = useAuth();

  const tableListWrapperEl = createRef<any>();
  const observerIndexFromLastItem = 3;
  const hasClientPermission = useMemo((): boolean => {
    return hasPermission([
      UserPermissions.ClientsView,
      UserPermissions.ClientsEdit,
      UserPermissions.ClientsDelete,
    ]);
  }, [hasPermission]);

  const onError = useCallback((err: ApiError) => {
    message.destroy('deleting-user-message');
    handleError(err);
  }, []);

  const handleDeleteUser = useCallback(
    async (user: UserModel) => {
      message.loading(
        { content: 'Deleting user...', key: 'deleting-user-message' },
        0
      );

      const res: any = await dispatch(deleteUser(user.uuid));
      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-user-message');
        message.success(`User '${user.name}' deleted.`);
      }
    },
    [onError, dispatch]
  );

  const handleConfirmDeleteUser = useCallback(
    (e: MouseEvent, user: UserModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete user '${user.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover this user.',
        okText: 'Delete',
        onOk: () => handleDeleteUser(user),
      });
    },
    [handleDeleteUser]
  );

  const getClientName = useCallback(
    (clientId: string): string => {
      const client = clients.find((c) => c.uuid === clientId);
      return client?.name || '';
    },
    [clients]
  );

  const actionsColumn: any = useMemo(
    () => ({
      title: 'Actions',
      key: 'actions',
      dataIndex: 'uuid',
      render: (_: string, user: UserModel) => {
        const isClientUser = userRoles.some((role) => role.name === 'client');
        if (isClientUser) return null;

        return (
          <Space direction="horizontal" size="middle">
            {hasPermission(UserPermissions.UsersEdit) && (
              <Button
                type="link"
                onClick={() =>
                  history.push({
                    hash: DrawerHashRoute.UserForm,
                    state: { data: user },
                  })
                }
              >
                Edit
              </Button>
            )}
            {hasPermission(UserPermissions.UsersDelete) && (
              <Button
                type="link"
                onClick={(e) => handleConfirmDeleteUser(e, user)}
              >
                Delete
              </Button>
            )}
          </Space>
        );
      },
    }),
    [hasPermission, handleConfirmDeleteUser, history]
  );

  const columns: any = useMemo(() => {
    const cols = [
      {
        title: 'No.',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (_: any, __: any, index: number) => index + 1,
      },
      {
        title: 'Name',
        width: 100,
        render: (user: UserModel, _: any, index: number) => {
          if (
            hasMore &&
            index + 1 === users.length - observerIndexFromLastItem
          ) {
            return (
              <>
                <Link className="ant-btn-link" to={`users/${user.uuid}`}>
                  {user.name}
                </Link>

                <LazyLoad
                  root={tableListWrapperEl.current!}
                  onIntersection={() => {
                    if (!filterReset) {
                      onLoadMore();
                    }
                  }}
                  triggerOnce
                  threshold={1.0}
                />
              </>
            );
          }
          return (
            <Link className="ant-btn-link" to={`users/${user.uuid}`}>
              {user.name}
            </Link>
          );
        },
      },
      {
        title: 'Client',
        dataIndex: 'client_id',
        key: 'client_id',
        width: 200,
        render: (client: string) => {
          if (!!client) {
            const clientName = getClientName(client);
            return (
              <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                {clientName}
              </div>
            );
          }
          return <Text type="secondary">N/A</Text>;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: any) => (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              placeholder="Enter a client name..."
              value={selectedKeys[0]}
              allowClear
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
              style={{
                width: '100%',
                marginBottom: 8,
              }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: '100%', marginBottom: 8 }}
            >
              Filter by Client
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
                setFilterReset(true);
                setTimeout(() => setFilterReset(false), 1000);
              }}
              size="small"
              style={{ width: '100%' }}
            >
              Reset
            </Button>
          </div>
        ),
        onFilter: (value: any, record: any) => {
          const NO_CLIENT_TEXT = 'no client';
          const clientName =
            getClientName(record.client_id)?.toLowerCase() || NO_CLIENT_TEXT;

          if (value.toLowerCase() === NO_CLIENT_TEXT) {
            return clientName === NO_CLIENT_TEXT;
          }

          return clientName.includes(value.toLowerCase());
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 250,
        render: (email: string) =>
          !!email ? (
            <Tooltip title={email} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {email}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No email.
            </Text>
          ),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: any) => (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              placeholder="Enter an email..."
              value={selectedKeys[0]}
              allowClear
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
              style={{
                width: '100%',
                marginBottom: 8,
              }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: '100%', marginBottom: 8 }}
            >
              Filter by Email
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
                setFilterReset(true);
                setTimeout(() => setFilterReset(false), 1000);
              }}
              size="small"
              style={{ width: '100%' }}
            >
              Reset
            </Button>
          </div>
        ),
        onFilter: (value: any, record: any) =>
          record.email.toString().toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
        key: 'roles',
        width: 200,
        render: (roles: { key: string }[]) => {
          return _.uniqBy(roles, 'display_name').map(
            (p: { display_name: string }) => {
              if (!!p.display_name) {
                return <Tag color="purple">{p.display_name}</Tag>;
              }
              return null;
            }
          );
        },
      },
      {
        title: 'Last activity',
        dataIndex: 'last_login_at',
        display_name: 'last_login_at',
        width: 200,
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (
          a: { last_login_at: string | number | Date },
          b: { last_login_at: string | number | Date }
        ) =>
          new Date(a.last_login_at).getTime() -
          new Date(b.last_login_at).getTime(),
      },
      {
        title: 'Last updated',
        dataIndex: 'updated_at',
        display_name: 'updated_at',
        width: 200,
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (
          a: { updated_at: string | number | Date },
          b: { updated_at: string | number | Date }
        ) =>
          new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
      },
    ];

    if (!currentPath.includes('/clients/')) {
      cols.push(actionsColumn);
    }

    return cols;
  }, [
    actionsColumn,
    currentPath,
    filterReset,
    getClientName,
    hasMore,
    onLoadMore,
    tableListWrapperEl,
    users.length,
  ]);

  useEffect(() => {
    if (hasClientPermission) {
      dispatch(fetchClients({ query: '' }));
    }
  }, [dispatch, hasClientPermission]);

  return (
    <div ref={tableListWrapperEl}>
      <Table
        loading={loading && users.length === 0}
        locale={{ emptyText: <></> }}
        rowKey={(user) => `${user.uuid}-row-key`}
        sortDirections={['ascend', 'descend', 'ascend']}
        className="users-table-list"
        pagination={false}
        columns={columns}
        dataSource={users}
        scroll={{ x: 1000 }}
      />

      {loading && users.length > 0 && (
        <div className="loading-more-spin-wrapper">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default withRouter<any, any>(memo(UserTableList, propsAreEqual));
