import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { ApiError, handleError, PaginationModel } from '../../../api/base';
import ParentCompanyService from '../../../api/parent-company';
import { ParentCompanyModel } from '../../../models/parent-company';
import { convertParamsToQuery } from '../../../util';
import { fetchManufacturers } from '../manufacturers/manufacturerSlice';

export interface ParentCompanyState {
  value: ParentCompanyModel[] | undefined;
  allParentCompanies: ParentCompanyModel[];
  allParentCompaniesValue: ParentCompanyModel[];
  fetchingParentCompanies: boolean;
  searchingParentCompanies: boolean;
  savingParentCompany: boolean;
  deletingParentCompany: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  count: number;
  current: number;
  currentPage: number;
  pageSize: number;
}

interface CreateParentCompanyProps {
  parentCompany: ParentCompanyModel;
}
interface UpdateParentCompanyProps {
  parentCompanyId: string;
  parentCompany: ParentCompanyModel;
}

const initialState: ParentCompanyState = {
  value: undefined,
  allParentCompanies: [],
  allParentCompaniesValue: [],
  fetchingParentCompanies: false,
  searchingParentCompanies: false,
  savingParentCompany: false,
  deletingParentCompany: false,
  error: { code: null, message: null },
  count: 0,
  current: 1,
  currentPage: 1,
  pageSize: 10,
  pagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
};

export interface ParentCompaniesParams {
  _limit?: number;
  _order_by?: string;
  _offset?: number;
  _columns?: string;
  name?: string;
  q?: string;
}

export const fetchParentCompanies = createAsyncThunk(
  'parentCompany/fetchParentCompanies',
  async (_arg, { getState, rejectWithValue }) => {
    try {
      const {
        parentCompany: { pagination },
      } = getState() as RootState;

      const nextParams: ParentCompaniesParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
      };

      const query = convertParamsToQuery(nextParams);
      let resp = await ParentCompanyService().listParentCompanies(query);

      const { limit, count, offset } = resp.pagination;
      let current = offset / limit + 1;

      if (offset >= count && count > 0) {
        current = current - 1;
        const nextParams = {
          _limit: limit,
          _offset: offset - limit,
          _order_by: 'created_at:desc',
        };

        const query = convertParamsToQuery(nextParams);
        resp = await ParentCompanyService().listParentCompanies(query);
      }

      return {
        current,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createParentCompany = createAsyncThunk(
  'parentCompany/createParentCompany',
  async (
    { parentCompany }: CreateParentCompanyProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ParentCompanyService().createParentCompany(
        parentCompany
      );

      await dispatch(fetchManufacturers());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateParentCompany = createAsyncThunk(
  'parentCompany/updateParentCompany',
  async (
    { parentCompanyId, parentCompany }: UpdateParentCompanyProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ParentCompanyService().updateParentCompany(
        parentCompanyId,
        parentCompany
      );

      await dispatch(fetchManufacturers());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteParentCompany = createAsyncThunk(
  'parentCompany/deleteParentCompany',
  async (
    { parentCompanyId }: { parentCompanyId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ParentCompanyService().deleteParentCompany(
        parentCompanyId
      );

      await dispatch(fetchManufacturers());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchParentCompaniesByName = createAsyncThunk(
  'parentCompany/searchParentCompaniesByName',
  async ({ query }: { query: string }, { getState, rejectWithValue }) => {
    try {
      const {
        parentCompany: { pagination },
      } = getState() as RootState;

      let params: ParentCompaniesParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await ParentCompanyService().listParentCompanies(
        queryString
      );
      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateParentCompanies = createAsyncThunk(
  'parentCompany/paginateParentCompanies',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: ParentCompaniesParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          q: `${queryStr.toLowerCase()}`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await ParentCompanyService().listParentCompanies(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const parentCompanySlice = createSlice({
  name: 'parentCompany',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // FETCH PARENT COMPANIES
      .addCase(fetchParentCompanies.pending, (state) => {
        state.savingParentCompany = true;
      })
      .addCase(fetchParentCompanies.fulfilled, (state, action) => {
        state.fetchingParentCompanies = false;
        state.value = action.payload.data;
        state.allParentCompanies = action.payload.data;
        state.pagination = action.payload.pagination;
        state.current = action.payload.current;
      })
      .addCase(fetchParentCompanies.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingParentCompanies = false;
        state.searchingParentCompanies = false;
      })

      // CREATE PARENT COMPANY
      .addCase(createParentCompany.pending, (state) => {
        state.savingParentCompany = true;
      })
      .addCase(createParentCompany.fulfilled, (state) => {
        state.savingParentCompany = false;
      })
      .addCase(createParentCompany.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingParentCompany = false;
      })

      // UPDATE PARENT COMPANY
      .addCase(updateParentCompany.pending, (state) => {
        state.savingParentCompany = true;
      })
      .addCase(updateParentCompany.fulfilled, (state) => {
        state.savingParentCompany = false;
      })
      .addCase(updateParentCompany.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingParentCompany = false;
      })

      // DELETE PARENT COMPANY
      .addCase(deleteParentCompany.pending, (state) => {
        state.deletingParentCompany = true;
      })
      .addCase(deleteParentCompany.fulfilled, (state) => {
        state.deletingParentCompany = false;
      })
      .addCase(deleteParentCompany.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.deletingParentCompany = false;
      })

      // SEARCH PARENT COMPANIES BY NAME
      .addCase(searchParentCompaniesByName.pending, (state) => {
        state.searchingParentCompanies = true;
      })
      .addCase(searchParentCompaniesByName.fulfilled, (state, action) => {
        state.count = action.payload.pagination.count;
        state.fetchingParentCompanies = false;
        state.searchingParentCompanies = false;
        state.value = action.payload.data;
        state.allParentCompaniesValue = action.payload.data;
        state.pagination = action.payload.pagination;
        state.current = 1;
        state.currentPage = 1;
        state.pageSize = action.payload.pagination.limit;
      })
      .addCase(searchParentCompaniesByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingParentCompanies = false;
        state.searchingParentCompanies = false;
      })

      // PAGINATE PARENT COMPANIES
      .addCase(paginateParentCompanies.pending, (state) => {
        state.fetchingParentCompanies = true;
      })
      .addCase(paginateParentCompanies.fulfilled, (state, action) => {
        state.fetchingParentCompanies = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...action.payload.data];
          state.allParentCompaniesValue = [...action.payload.data];
          state.fetchingParentCompanies = false;
          state.current = action.payload.current;
          state.currentPage = action.meta.arg.page;
          state.pageSize = action.meta.arg.pageSize;
        }
      })
      .addCase(paginateParentCompanies.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingParentCompanies = false;
      });
  },
});

export default parentCompanySlice.reducer;
