import { useCallback, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuth';
import { fetchProductItem } from '../../../store/features/productItem/productItemSlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { UserPermissions } from '../../../util';
import EditableInformationForm from '../../containers/forms/EditableInformationForm';
import ViewWrapper from '../../elements/ViewWrapper';
import BasicInfo from './components/BasicInfo';
import HeaderSectionLeft from './components/HeaderSectionLeft';
import HeaderSectionRight from './components/HeaderSectionRight';
import ImageSelection from './components/ImageSelection';
import NotEditableInfo from './components/NotEditableInfo';
import Notes from './components/Notes';
import PackageInfo from './components/PackageInfo';
import Status from './components/Status';

import './ProductDetailsView.less';

interface ParamsState {
  id: string;
}

const ProductDetailsView = () => {
  const [displayEditableForm, setDisplayEditableForm] = useState(false);
  const { id } = useParams<ParamsState>();
  const dispatch = useAppDispatch();
  const { hasPermission, userRoles } = useAuth();
  const { value: productItem, fetchingProductItem } = useAppSelector(
    (state) => state.productItem
  );

  const hasClientRole = userRoles.some(
    (role) => role.name.toLowerCase() === 'client'
  );
  const canViewProducts = hasPermission(UserPermissions.ModelView);
  const canViewUnapproved = hasPermission(UserPermissions.ModelViewUnapproved);
  const canEditModels = hasPermission(UserPermissions.ModelEdit);

  const getProduct = useCallback(() => {
    dispatch(fetchProductItem(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (canViewProducts) {
      getProduct();
    }
  }, [canViewProducts, getProduct]);

  if (!canViewProducts) {
    return <Redirect to="/" />;
  }

  if (productItem && productItem.status !== 'approved' && !canViewUnapproved) {
    return <Redirect to="/" />;
  }

  return (
    <ViewWrapper
      options={{
        goBack: true,
        loading: fetchingProductItem,
      }}
      headerTitle={
        <HeaderSectionLeft
          hasClientRole={hasClientRole}
          productItem={productItem}
        />
      }
      headerSectionRight={
        productItem ? (
          <HeaderSectionRight
            productItem={productItem}
            displayEditableForm={displayEditableForm}
            setDisplayEditableForm={setDisplayEditableForm}
            canEdit={canEditModels}
          />
        ) : null
      }
    >
      <div className="product-item-content-container">
        <div className="product-item-details">
          <ImageSelection />

          {productItem && !hasClientRole ? (
            <Status productItem={productItem} />
          ) : null}
        </div>
        <div className="edit-content-container">
          {displayEditableForm && canEditModels ? (
            <>
              <div className="edit-form-wrapper">
                <EditableInformationForm
                  productItem={productItem}
                  setDisplayEditableForm={setDisplayEditableForm}
                />
              </div>
              <div className="not-editable-info-wrapper">
                {productItem ? (
                  <NotEditableInfo productItem={productItem} />
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className="basic-info-wrapper">
                {productItem ? <BasicInfo productItem={productItem} /> : null}
              </div>
              <div className="package-info-wrapper">
                {productItem ? <PackageInfo productItem={productItem} /> : null}
              </div>
              <div className="notes-wrapper">
                {productItem && !hasClientRole ? (
                  <Notes productItem={productItem} />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </ViewWrapper>
  );
};

export default ProductDetailsView;
