import { MouseEvent, useCallback, useMemo, useRef } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Modal,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';

import { ApiError, handleError } from '../../../../../api/base';
import { useAuth } from '../../../../../hooks/useAuth';
import { RoleModel } from '../../../../../models/role';
import { useAppDispatch } from '../../../../../store';
import { deleteRole } from '../../../../../store/features/acl/roles/rolesSlice';
import { formatDate, UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import './RoleTableList.less';

const { Text } = Typography;

interface RoleTableListProps {
  roles: RoleModel[];
  selectedRoles?: RoleModel[];
  loading: boolean;
  view?: 'Users' | 'Roles';
  onRolesSelected?: (roles: RoleModel[]) => void;
}

const RoleTableList: React.FC<RoleTableListProps> = ({
  roles,
  selectedRoles = [],
  loading,
  view,
  onRolesSelected,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuth();
  const tableListWrapperEl = useRef<any>();

  const onError = useCallback((err: ApiError) => {
    message.destroy('deleting-user-message');
    handleError(err);
  }, []);

  const handleDeleteRole = useCallback(
    async (role: RoleModel) => {
      message.loading(
        { content: 'Deleting role...', key: 'deleting-user-message' },
        0
      );

      const res: any = await dispatch(deleteRole(role.id));
      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-user-message');
        message.success(`Role '${role.display_name}' deleted.`);
      }
    },
    [onError, dispatch]
  );

  const handleConfirmDeleteRole = useCallback(
    (e: MouseEvent, role: RoleModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete role '${role.display_name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover this role.',
        okText: 'Delete',
        onOk: () => handleDeleteRole(role),
      });
    },
    [handleDeleteRole]
  );

  const columns: ColumnsType<RoleModel> = useMemo(() => {
    let cols: any = [
      {
        title: 'Role Name',
        dataIndex: 'display_name',
        key: 'display_name',
        render: (name: string) => {
          return <Text>{name}</Text>;
        },
        sorter: {
          compare: (u1: RoleModel, u2: RoleModel) => {
            if (u1.name < u2.name) {
              return -1;
            } else if (u1.name > u2.name) {
              return 1;
            } else {
              return 0;
            }
          },
          multiple: 1,
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (description: string) =>
          !!description ? (
            <Tooltip title={description} mouseEnterDelay={1}>
              <Text
                ellipsis
                style={{ maxWidth: '400px' }}
                className={view === 'Roles' ? 'column-size' : ''}
              >
                {description}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No description.
            </Text>
          ),
        sorter: {
          compare: (u1: RoleModel, u2: RoleModel) => {
            if (u1.description < u2.description) {
              return -1;
            } else if (u1.description > u2.description) {
              return 1;
            } else {
              return 0;
            }
          },
          multiple: 2,
        },
      },
    ];

    if (view === 'Roles') {
      cols = [
        ...cols,
        {
          title: 'Last updated',
          dataIndex: 'updated_at',
          key: 'updated_at',
          render: (date: string) => {
            return <div>{date ? formatDate(date) : null}</div>;
          },
          sorter: {
            compare: (a: RoleModel, b: RoleModel) =>
              new Date(a.updated_at).getTime() -
              new Date(b.updated_at).getTime(),
            multiple: 3,
          },
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (role: RoleModel) => (
            <Space direction="horizontal" size="middle">
              {hasPermission(UserPermissions.RolesEdit) && (
                <Button
                  type="link"
                  onClick={() => {
                    history.push({
                      hash: DrawerHashRoute.RoleForm,
                      state: { data: role },
                    });
                  }}
                >
                  Edit
                </Button>
              )}
              {hasPermission(UserPermissions.RolesDelete) && (
                <Button
                  type="link"
                  onClick={(e) => handleConfirmDeleteRole(e, role)}
                >
                  Delete
                </Button>
              )}
            </Space>
          ),
        },
      ];
    }

    return cols;
  }, [view, history, handleConfirmDeleteRole, hasPermission]);

  return (
    <div ref={tableListWrapperEl}>
      <Table
        rowSelection={
          view === 'Users'
            ? {
                type: 'checkbox',
                onChange: (_: React.Key[], selectedRows: RoleModel[]) => {
                  onRolesSelected?.(selectedRows);
                },
                selectedRowKeys: selectedRoles.map(
                  (role) => `${role.id}-row-key`
                ),
              }
            : undefined
        }
        loading={loading && roles.length === 0}
        locale={{ emptyText: <></> }}
        rowKey={(role) => `${role.id}-row-key`}
        sortDirections={['ascend', 'descend', 'ascend']}
        className="roles-table-list"
        pagination={false}
        columns={columns}
        dataSource={roles}
      />

      {loading && roles.length > 0 && (
        <div className="loading-more-spin-wrapper">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default RoleTableList;
