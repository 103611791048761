import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router-dom';

import { SortOrder } from 'antd/lib/table/interface';
import { useAuth } from '../../../../../hooks/useAuth';
import useDebounce from '../../../../../hooks/useDebounce';
import { BrandModel } from '../../../../../models/brand';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateBrands,
  searchBrandsByName,
} from '../../../../../store/features/brand/brandSlice';
import { UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';
import SearchBar from '../../../../elements/SearchBar/SearchBar';
import { RoutePath } from '../../../AppRoot/types';

const { Text } = Typography;

const BrandsTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { hasPermission } = useAuth();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    value: brands,
    allBrandsValue,
    fetchingBrands,
    searchingBrands,
    totalCount,
    currentPage,
    pageSize,
  } = useAppSelector((state) => state.brand);

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingBrands && !!searchQuery && allBrandsValue.length === 0,
    [fetchingBrands, searchQuery, allBrandsValue.length]
  );

  const hasBrands = !fetchingBrands && brands.length > 0;

  const columns: ColumnsType<BrandModel> = useMemo(
    () =>
      getColumns(history, hasPermission).map((column) => ({
        ...column,
        defaultSortOrder: column.defaultSortOrder as SortOrder | undefined,
      })),
    [history, hasPermission]
  );

  useEffect(() => {
    dispatch(searchBrandsByName(debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      dispatch(
        paginateBrands({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      );
    },
    [dispatch, debouncedSearchQuery]
  );

  const paginationSettings = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: totalCount,
    current: currentPage,
    pageSize: pageSize,
  };

  return (
    <div>
      <SearchBar
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        isSearching={searchingBrands}
        canCreate={hasPermission(UserPermissions.MetaDataCreate)}
        createLink={DrawerHashRoute.BrandForm}
        createButtonText="Add New Brand"
        placeholder="Search Brands"
      />
      {hasBrands && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          loading={fetchingBrands || searchingBrands}
          sortDirections={['ascend', 'descend', 'ascend']}
          columns={columns}
          dataSource={brands}
          pagination={paginationSettings}
        />
      )}
    </div>
  );
};

const getColumns = (
  history: any,
  hasPermission: (permission: UserPermissions | UserPermissions[]) => boolean
) => [
  {
    title: 'Brand',
    dataIndex: 'name',
    width: '35%',
    sorter: (a: BrandModel, b: BrandModel) => (a.name > b.name ? 1 : -1),
  },
  {
    title: '# in Processing',
    dataIndex: 'in_processing',
    defaultSortOrder: 'descend',
    sorter: (a: BrandModel, b: BrandModel) => a.in_processing - b.in_processing,
  },
  {
    title: '# in Library',
    dataIndex: 'in_library',
    defaultSortOrder: 'descend',
    sorter: (a: BrandModel, b: BrandModel) => a.in_library - b.in_library,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    defaultSortOrder: 'descend',
    sorter: (a: BrandModel, b: BrandModel) => {
      if (a.status && b.status) {
        return a.status > b.status ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (status: string) => {
      if (status === 'approved') {
        return <Text>{status}</Text>;
      } else {
        return <Text type="danger">Needs Approval</Text>;
      }
    },
  },
  {
    title: 'Actions',
    width: 100,
    render: (brand: BrandModel) => (
      <Space direction="horizontal" size="middle">
        {hasPermission(UserPermissions.MetaDataEdit) && (
          <Button
            type="link"
            className="link-button"
            onClick={() =>
              history.push({
                hash: DrawerHashRoute.BrandForm,
                state: { data: brand },
              })
            }
          >
            Edit
          </Button>
        )}
        <Button
          className="link-button"
          type="link"
          onClick={() => {
            history.push({
              state: { brand },
              pathname: RoutePath.ProductLibrary,
            });
          }}
        >
          View in Library
        </Button>
      </Space>
    ),
  },
];

export default BrandsTab;
