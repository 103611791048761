import React, { memo, useCallback, useMemo, useState } from 'react';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  ConfigProvider,
  Modal,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { History } from 'history';
import { Link, useLocation, withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { ListProductLibraryModel } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { fetchProducts } from '../../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import { deleteProductItem } from '../../../../store/features/productItem/productItemSlice';
import {
  ProductListState,
  addProductsToProductList,
  removeSelectedProductItemsFromList,
  searchProductsByName,
  setSelectedProducts,
} from '../../../../store/features/productList/productListSlice';
import {
  UploadState,
  bulkDeleteModels,
  removeProducts,
} from '../../../../store/features/uploadModel/uploadModel';
import { propsAreEqual } from '../../../../util';
import { customizeRenderEmpty } from '../../../elements/CustomizeEmptyRender/customizeRenderEmpty';
import './UploadModelsTable.less';
import { DrawerHashRoute } from '../../../containers/Drawers/types';

const { Title } = Typography;

interface UploadModelsTableProps {
  totalCount: number;
  // paginate: (page: number, pageSize: number) => void;
  paginationSetting: any;
  skeletonItemLength?: number;
  dataSource: ListProductLibraryModel[];
  loading: boolean;
  history: History;
  headerTitle: string;
  columns: ColumnsType<ListProductLibraryModel>;
  noDataDescription?: string;
  disabledCheckbox: boolean;
  hasFooterDeleteButton?: boolean;
  hasFooterRemoveButton?: boolean;
  hasFooterAddButton?: boolean;
  hasFooterBulkEditButton?: boolean;
  hasFooterReplaceExistingButton?: boolean;
  replaceExistingProducts: (
    selectedProducts: ListProductLibraryModel[]
  ) => Promise<void>;
  tableHeight?: string;
}

interface SelectedProduct {
  uuid: string;
  in_list: number | null;
}

type ProductTypeKeys =
  | 'Uploaded Products'
  | 'Replaced Products'
  | 'Existing Products'
  | 'Master Librarian List'
  | 'Recently Added';

type ProductTypeMapType = Record<ProductTypeKeys, keyof UploadState>;

const UploadModelsTable = ({
  totalCount,
  paginationSetting,
  loading,
  dataSource,
  headerTitle,
  columns,
  hasFooterDeleteButton,
  hasFooterRemoveButton,
  hasFooterAddButton,
  hasFooterBulkEditButton,
  hasFooterReplaceExistingButton,
  noDataDescription,
  replaceExistingProducts,
  tableHeight,
}: UploadModelsTableProps) => {
  const [selectedProductRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    []
  );

  const [selectionLoading, setSelectionLoading] = useState(false);
  const hasSelected = selectedProductRowKeys.length > 0;
  const dispatch = useAppDispatch();

  const { selectedProducts } = useAppSelector<ProductListState>(
    (state) => state.productList
  );

  const { processing } = useAppSelector((state) => state.uploadModel);
  const location = useLocation();

  const isProductListRoute = location.pathname === '/product-list';
  const isProductLibraryRoute = location.pathname === '/product-library';

  const showRemoveButton =
    hasFooterRemoveButton &&
    (isProductListRoute ||
      (isProductLibraryRoute &&
        selectedProducts.every((product) => product.in_list === 1)));

  const header = useMemo(
    (): JSX.Element | null =>
      headerTitle ? (
        <div className="products-table-header">
          <Title level={3}>{headerTitle}</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
          />
        </div>
      ) : null,
    [headerTitle, totalCount]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const handleRemoveSelectionFromProductList = (
    selectedProducts: ListProductLibraryModel[]
  ) => {
    setSelectionLoading(true);
    setSelectedRowKeys([]);
    setSelectionLoading(false);
    dispatch(removeSelectedProductItemsFromList(selectedProducts));
    dispatch(setSelectedProducts(selectedProducts));
  };

  const handleDeleteSelectedProducts = useCallback(
    async (productsToBeDeleted: any) => {
      try {
        message.loading({
          content: 'Deleting products...',
          key: 'deleting-products-message',
          duration: 0,
        });

        // Delete products one by one
        for (const product of productsToBeDeleted) {
          const resp = await dispatch(deleteProductItem(product.uuid));
          if (resp.error) {
            throw new Error(resp.payload.error);
          }
        }

        message.destroy('deleting-products-message');
        message.success(
          `Successfully deleted ${productsToBeDeleted.length} products`
        );

        const uuidsOfProductsToDelete = productsToBeDeleted.map(
          (product: any) => product.uuid
        );

        switch (headerTitle) {
          case 'Uploaded Products':
            dispatch(
              removeProducts({
                uuids: uuidsOfProductsToDelete,
                productType: 'uploadedProducts',
              })
            );
            break;
          case 'Existing Products':
            dispatch(
              removeProducts({
                uuids: uuidsOfProductsToDelete,
                productType: 'existingProducts',
              })
            );
            break;
          case 'Replaced Products':
            dispatch(
              removeProducts({
                uuids: uuidsOfProductsToDelete,
                productType: 'replacedProducts',
              })
            );
            break;
        }

        await dispatch(fetchProducts(''));
      } catch (error) {
        handleError(error as ApiError);
      } finally {
        handleClearSelection();
      }
    },
    [dispatch, handleClearSelection, headerTitle]
  );

  const handleConfirmDeleteProduct = useCallback(
    (e: any, product: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the list or update it after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteSelectedProducts(product),
      });
    },
    [handleDeleteSelectedProducts]
  );

  const [hasMixedSelection, setHasMixedSelection] = useState(false);

  const tableFooter = () => (
    <>
      <div className="table-footer-wrapper">
        <div className="table-footer-left">
          <span>
            {hasSelected
              ? `${selectedProductRowKeys.length} ${
                  selectedProductRowKeys.length === 1 ? 'Item' : 'Items'
                } selected${!hasMixedSelection ? ':' : '.'} `
              : ''}
          </span>
          <Space size="middle">
            {hasMixedSelection && (
              <Typography.Text type="secondary">
                Please select only added or only removed products.
              </Typography.Text>
            )}
            {!hasMixedSelection && (
              <>
                {hasFooterDeleteButton &&
                  (headerTitle !== 'Master Librarian List' ||
                    process.env.NODE_ENV === 'development') && (
                    <Button
                      type="primary"
                      onClick={(e) =>
                        handleConfirmDeleteProduct(e, selectedProducts)
                      }
                      loading={processing}
                      style={{ minWidth: '200px' }}
                      icon={processing ? <LoadingOutlined /> : null}
                    >
                      {processing
                        ? 'Deleting...'
                        : 'Delete Selected Product(s)'}
                    </Button>
                  )}

                {showRemoveButton && (
                  <Button
                    type="primary"
                    onClick={() =>
                      handleRemoveSelectionFromProductList(selectedProducts)
                    }
                    loading={selectionLoading}
                  >
                    Remove from List
                  </Button>
                )}

                {hasFooterAddButton &&
                  selectedProducts.every(
                    (product) => product.in_list === 0
                  ) && (
                    <Button
                      type="primary"
                      onClick={() =>
                        handleAddSelectionToProductList(selectedProducts)
                      }
                      loading={selectionLoading}
                    >
                      Add to Product List
                    </Button>
                  )}

                {hasFooterReplaceExistingButton && (
                  <Button
                    type="primary"
                    onClick={() =>
                      handleReplaceExistingProducts(selectedProducts)
                    }
                    loading={selectionLoading}
                  >
                    Replace Existing
                  </Button>
                )}

                {hasFooterBulkEditButton && (
                  <Link to={DrawerHashRoute.BulkEditProductsForm}>
                    <Button type="primary" loading={selectionLoading}>
                      Bulk Edit
                    </Button>
                  </Link>
                )}
              </>
            )}
          </Space>
        </div>
        <div className="table-footer-right">
          <Button
            type="link"
            onClick={handleClearSelection}
            className="link-button"
          >
            Clear Selection
          </Button>
        </div>
      </div>
    </>
  );

  const handleAddSelectionToProductList = async (
    selectedProducts: SelectedProduct[]
  ) => {
    const productTypeMap: ProductTypeMapType = {
      'Uploaded Products': 'uploadedProducts',
      'Replaced Products': 'replacedProducts',
      'Existing Products': 'existingProducts',
      'Master Librarian List': 'masterLibrarianList',
      'Recently Added': 'productList',
    };

    const productIds = selectedProducts.map((product) => product.uuid);

    setSelectionLoading(true);
    setSelectedRowKeys([]);
    setSelectionLoading(false);
    await dispatch(addProductsToProductList(productIds));
    await dispatch(searchProductsByName(''));
    const uuidsOfProductsToDelete = selectedProducts.map(
      (product: any) => product.uuid
    );

    // Get the product type key from the mapping based on the header title
    const productTypeKey = productTypeMap[headerTitle as ProductTypeKeys];

    if (!productTypeKey) {
      throw new Error('Invalid header title');
    } else if (productTypeKey === 'masterLibrarianList') {
      // Delete products one by one
      for (const product of uuidsOfProductsToDelete) {
        const resp = await dispatch(deleteProductItem(product.uuid));
        if (resp.error) {
          throw new Error(resp.payload.error);
        }
      }

      // Clear loading message and show success
      message.destroy('deleting-products-message');
      message.success(
        `Successfully deleted ${uuidsOfProductsToDelete.length} products`
      );

      // Refresh the list
      await dispatch(fetchProducts(''));
    } else {
      await dispatch(bulkDeleteModels(uuidsOfProductsToDelete));
    }

    dispatch(
      removeProducts({
        uuids: uuidsOfProductsToDelete,
        productType: productTypeKey,
      })
    );
  };

  const handleReplaceExistingProducts = (
    selectedProducts: ListProductLibraryModel[]
  ) => {
    setSelectionLoading(true);
    setSelectedRowKeys([]);
    setSelectionLoading(false);
    replaceExistingProducts(selectedProducts);
  };

  const handleOnChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: ListProductLibraryModel[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedProducts(selectedRows));

    // Check if there's a mix of added and removed products
    const hasAdded = selectedRows.some((product) => product.in_list === 0);
    const hasRemoved = selectedRows.some((product) => product.in_list === 1);
    setHasMixedSelection(hasAdded && hasRemoved);
  };

  const rowSelection: TableRowSelection<ListProductLibraryModel> = {
    selectedRowKeys: selectedProductRowKeys,
    onChange: handleOnChange,
    preserveSelectedRowKeys: true,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const footerContent = hasSelected ? tableFooter : undefined;
  const paginationContent = dataSource.length > 0 ? paginationSetting : false;

  return (
    <div className="products-table-list-wrapper">
      {header}
      <ConfigProvider
        renderEmpty={() => customizeRenderEmpty(noDataDescription)}
      >
        <Table
          className="products-table-list"
          footer={footerContent}
          scroll={{ y: tableHeight || 240 }}
          rowSelection={rowSelection}
          loading={loading && dataSource.length > 0}
          rowKey={(product: ListProductLibraryModel) =>
            `${product.uuid}-row-key`
          }
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={paginationContent}
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: customizeRenderEmpty(noDataDescription) }}
        />
      </ConfigProvider>
    </div>
  );
};

export default withRouter<any, any>(memo(UploadModelsTable, propsAreEqual));
