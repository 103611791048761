import { useMemo, useRef } from 'react';

import { Spin, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { PermissionModel } from '../../../../../models/permission';
import './PermissionTableList.less';

const { Text } = Typography;

interface PermissionTableProps {
  permissions: PermissionModel[];
  selectedPermissions?: PermissionModel[];
  loading: boolean;
  onPermissionsSelected?: (roles: PermissionModel[]) => void;
}

const PermissionTableList: React.FC<PermissionTableProps> = ({
  permissions,
  selectedPermissions = [],
  loading,
  onPermissionsSelected,
}) => {
  const tableListWrapperEl = useRef<any>();

  const columns: ColumnsType<PermissionModel> = useMemo(
    () => [
      {
        title: 'Permission',
        dataIndex: 'display_name',
        key: 'display_name',
        width: '35%',
        render: (name: string) => {
          return <Text>{name}</Text>;
        },
        sorter: {
          compare: (u1: PermissionModel, u2: PermissionModel) => {
            if (u1.name < u2.name) {
              return -1;
            } else if (u1.name > u2.name) {
              return 1;
            } else {
              return 0;
            }
          },
          multiple: 1,
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (description: string) =>
          !!description ? (
            <Tooltip title={description} mouseEnterDelay={1}>
              <Text>{description}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No description.
            </Text>
          ),
        sorter: {
          compare: (u1: PermissionModel, u2: PermissionModel) => {
            if (u1.description < u2.description) {
              return -1;
            } else if (u1.description > u2.description) {
              return 1;
            } else {
              return 0;
            }
          },
          multiple: 2,
        },
      },
    ],
    []
  );

  return (
    <div ref={tableListWrapperEl}>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (_: React.Key[], selectedRows: PermissionModel[]) => {
            onPermissionsSelected?.(selectedRows);
          },
          selectedRowKeys: selectedPermissions.map(
            (permission) => `${permission.id}-row-key`
          ),
        }}
        loading={loading && permissions.length === 0}
        locale={{ emptyText: <></> }}
        rowKey={(permission) => `${permission.id}-row-key`}
        sortDirections={['ascend', 'descend', 'ascend']}
        className="roles-table-list"
        pagination={false}
        columns={columns}
        dataSource={permissions}
      />

      {loading && permissions.length > 0 && (
        <div className="loading-more-spin-wrapper">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default PermissionTableList;
