import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { BackTop, Badge, Empty, Input, Space, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';
import { RootState, useAppDispatch } from '../../../store';
import {
  fetchRoles,
  RolesState,
  searchRolesByName,
} from '../../../store/features/acl/roles/rolesSlice';
import { UserPermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import Button from '../../elements/Button';
import NoPermission from '../../elements/NoPermission';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import RoleTableList from './components/RoleTableList/RoleTableList';
import './RolesView.less';

const { Title } = Typography;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const RolesView = () => {
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuth();
  const {
    value: roles,
    allRoles,
    fetchingRoles,
    searchingRoles,
    totalCount,
  } = useSelector<RootState, RolesState>((state) => state.roles);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const noSearchResultsFound = useMemo(
    () => !searchingRoles && !!searchQuery && roles.length === 0,
    [roles.length, searchQuery, searchingRoles]
  );

  const createNewRoleButton = useMemo(
    (): JSX.Element => (
      <Link to={DrawerHashRoute.RoleForm}>
        <Button type="primary" data-cy="create-new-role-btn">
          Create New Role
        </Button>
      </Link>
    ),
    []
  );

  const updateSearchQuery = useCallback(
    (query: string) => {
      setSearchQuery(query);
      if (query === '') {
        dispatch(fetchRoles());
      }
    },
    [dispatch]
  );

  const headerSectionRight = useMemo(
    (): JSX.Element => (
      <Space direction="horizontal" size="middle">
        <Input
          allowClear={!searchingRoles}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          placeholder="Search for a role..."
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          suffix={searchingRoles && <Spin indicator={antSpinIcon} />}
        />
        {hasPermission(UserPermissions.RolesCreate) && createNewRoleButton}
      </Space>
    ),
    [
      createNewRoleButton,
      searchQuery,
      searchingRoles,
      hasPermission,
      updateSearchQuery,
    ]
  );

  useEffect(() => {
    if (hasPermission(UserPermissions.RolesView)) {
      dispatch(searchRolesByName(debouncedSearchQuery));
    }
  }, [debouncedSearchQuery, dispatch, hasPermission]);

  useEffect(() => {
    if (hasPermission(UserPermissions.RolesView)) {
      dispatch(fetchRoles());
    }
  }, [dispatch, hasPermission]);

  if (!hasPermission(UserPermissions.RolesView)) {
    return <NoPermission />;
  }

  return (
    <ViewWrapper
      headerTitle={
        <Space direction="horizontal">
          <Title level={3}>Roles</Title>
          <Badge
            size="default"
            count={totalCount as number}
            className="total-count-badge"
          />
        </Space>
      }
      headerSectionRight={headerSectionRight}
    >
      {fetchingRoles ? (
        <RoleTableList roles={[]} loading={true} view="Roles" />
      ) : noSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : allRoles.length === 0 ? (
        <Empty
          description="Currently, there are no roles"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
          {hasPermission(UserPermissions.RolesCreate) && createNewRoleButton}
        </Empty>
      ) : (
        <RoleTableList roles={roles} loading={fetchingRoles} view="Roles" />
      )}

      <BackTop />
    </ViewWrapper>
  );
};

export default RolesView;
