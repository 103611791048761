import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import './LogInView.less';

import AuthService, { AuthenticateRequest } from '../../../api/auth';
import { handleError } from '../../../api/base';
import ReadySetLogo from '../../../assets/svgs/readyset-logo.svg';
import Button from '../../elements/Button';
import { RoutePath } from '../AppRoot/types';

const LogInView = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [currentAuthStep, setCurrentAuthStep] = useState(0);
  const [qrCode, setQrCode] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [authCredentials, setAuthCredentials] =
    useState<AuthenticateRequest | null>(null);
  const [form] = Form.useForm();

  const onSuccess = async () => {
    history.push('/');
  };

  const handleAuthError = (err: any) => {
    console.log('handleAuthError called with:', err);
    if (err.error?.code === 'E_MFA_TOKEN_REQUIRED') {
      console.log('Setting MFA step to 3');
      setCurrentAuthStep(3);
      console.log('Current auth step after setting:', currentAuthStep);
      return;
    }

    if (err.error?.code === 'E_MFA_TOKEN_INCORRECT') {
      console.log('MFA token incorrect');
      handleError({
        error: {
          code: 400,
          message: err.error.message,
        },
      });
      setMfaCode('');
      return;
    }

    // Handle generic errors
    console.error('Authentication error:', err);
    handleError({
      error: {
        code: 500,
        message: 'Oops, something went wrong.',
      },
    });
  };

  console.log('currentAuthStep', currentAuthStep);

  const onSubmit = async (userCredentials: AuthenticateRequest) => {
    try {
      setLoading(true);
      setAuthCredentials(userCredentials);
      const response = await AuthService().authenticate(userCredentials);

      if ('error' in response) {
        throw response;
      }

      const { clientMfaStatus, userMfaStatus } = response;

      switch (clientMfaStatus) {
        case 'required':
          if (userMfaStatus === 'disabled') {
            const mfaResp = await AuthService().requestMFA(userCredentials);
            setQrCode(mfaResp.qrCode);
            setCurrentAuthStep(1);
          }
          break;

        case 'optional':
          if (userMfaStatus === 'disabled') {
            setCurrentAuthStep(4);
          }
          break;

        case 'disabled':
          if (userMfaStatus === 'enabled') {
            handleError({
              error: {
                code: 400,
                message:
                  'Invalid state: MFA enabled for client with disabled MFA',
              },
            });
          }

          onSuccess();
          break;
      }
    } catch (err: any) {
      handleAuthError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleContinueWithoutMFA = async () => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('me');

    if (!token || !userData) {
      throw new Error('Missing authentication data');
    }

    onSuccess();
  };

  const handleCompleteMFASetup = async (
    e: React.MouseEvent | React.KeyboardEvent
  ) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!authCredentials) {
        throw new Error('No authentication credentials found');
      }

      await AuthService().confirmMFA({ mfaToken: mfaCode });

      const authResponse = await AuthService().authenticate({
        ...authCredentials,
        mfaToken: mfaCode,
      });

      if ('error' in authResponse) {
        throw authResponse;
      }
      onSuccess();
    } catch (err: any) {
      handleAuthError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyMFA = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!authCredentials) {
        throw new Error('No authentication credentials found');
      }

      const authResponse = await AuthService().authenticate({
        ...authCredentials,
        mfaToken: mfaCode,
      });

      if ('error' in authResponse) {
        throw authResponse;
      }
      onSuccess();
    } catch (err: any) {
      handleAuthError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSetupTwoFactorAuth = async () => {
    try {
      setLoading(true);
      if (!authCredentials) {
        throw new Error('No authentication credentials found');
      }
      const mfaResp = await AuthService().requestMFA(authCredentials);
      setQrCode(mfaResp.qrCode);
      setCurrentAuthStep(1);
    } catch (err: any) {
      handleAuthError(err);
    } finally {
      setLoading(false);
    }
  };

  const authSteps = [
    {
      content: (
        <>
          <Form
            layout="vertical"
            className="log-in-form"
            onFinish={onSubmit}
            requiredMark={false}
            form={form}
          >
            <Form.Item
              label="Email"
              name="apiUser"
              rules={[{ required: true, message: 'Email is required.' }]}
            >
              <Input
                autoFocus
                data-cy="log-in-email-input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="apiPass"
              rules={[{ required: true, message: 'Password is required.' }]}
              className="log-in-password-input"
            >
              <Input.Password
                data-cy="log-in-password-input"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Link
              to={RoutePath.ForgotPassword}
              className="log-in-forgotten-label"
            >
              Forgot password?
            </Link>

            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="log-in-form-button"
                data-cy="log-in-btn"
              >
                Log In
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">
            Two-Factor Authentication Required
          </h3>
          <p style={{ marginTop: 12 }}>
            To add an extra layer of security to your account, we recommend
            setting up Time-Based One-Time Password (TOTP) authentication. This
            requires a code generated by an app on your phone in addition to
            your password when you log in.
          </p>
          <div style={{ marginTop: 24 }}>
            <h4>1. Download a TOTP Authenticator App</h4>
            <ul>
              <li>Google Authenticator (iOS and Android)</li>
              <li>Microsoft Authenticator (iOS and Android)</li>
              <li>FreeOTP (iOS and Android)</li>
            </ul>
          </div>
          <Button
            type="primary"
            onClick={() => setCurrentAuthStep(2)}
            style={{ marginTop: 24 }}
          >
            Continue
          </Button>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">Scan QR Code</h3>
          <p style={{ marginTop: 12 }}>
            Open your authenticator app, select the option to scan a QR code,
            and use your camera to scan the displayed code.
          </p>
          {qrCode && (
            <div style={{ margin: '24px 0' }}>
              <img
                src={qrCode}
                alt="MFA QR Code"
                style={{ height: '200px', width: '100%' }}
              />
            </div>
          )}
          <p>
            After scanning, your app will generate a six-digit code. Enter this
            code to verify and complete the setup.
          </p>
          <div
            className="forgot-password-input-wrapper"
            style={{ marginBottom: 16 }}
          >
            <label htmlFor="mfa-code" className="forgot-password-input-label">
              Verification Code
            </label>
            <Input
              autoFocus
              name="mfa-code"
              placeholder="Enter 6-digit code"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
              maxLength={6}
              onPressEnter={(e) => {
                e.preventDefault();
                handleCompleteMFASetup(e);
              }}
            />
          </div>
          <p>
            <strong>
              Important: Remember, you'll need this app to access your account
              in the future. If you change devices, be sure to transfer your
              authentication codes to your new phone.
            </strong>
          </p>
          <Button
            loading={loading}
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCompleteMFASetup(e);
            }}
          >
            Verify and Complete Setup
          </Button>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">Two-Factor Authentication</h3>
          <p style={{ marginTop: 12 }}>
            Please enter the verification code from your authenticator app to
            continue.
          </p>
          <div
            className="forgot-password-input-wrapper"
            style={{ marginBottom: 16 }}
          >
            <label htmlFor="mfa-code" className="forgot-password-input-label">
              Verification Code
            </label>
            <Input
              autoFocus
              name="mfa-code"
              placeholder="Enter 6-digit code"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
              maxLength={6}
              onPressEnter={handleVerifyMFA}
            />
          </div>
          <Button
            loading={loading}
            type="primary"
            onClick={handleVerifyMFA}
            disabled={!mfaCode}
          >
            Verify
          </Button>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">
            Two-Factor Authentication Available
          </h3>
          <p style={{ marginTop: 12 }}>
            Would you like to set up Two-Factor Authentication for additional
            account security? This will require a code generated by an app on
            your phone in addition to your password when you log in.
          </p>
          <div className="mfa-choice-buttons">
            <Button type="primary" onClick={handleSetupTwoFactorAuth}>
              Set Up 2FA
            </Button>
            <Button type="default" onClick={handleContinueWithoutMFA}>
              Continue Without 2FA
            </Button>
          </div>
        </>
      ),
    },
  ];

  console.log('LogInView rendering with:', {
    currentAuthStep,
    hasQrCode: !!qrCode,
    hasMfaCode: !!mfaCode,
    hasAuthCredentials: !!authCredentials,
    authStepsLength: authSteps?.length,
  });

  return (
    <div className="log-in-view">
      <Card style={{ width: '450px', marginBottom: '72px' }}>
        <div className="log-in-card-content-wrapper">
          <img
            src={ReadySetLogo}
            alt="ReadySet logo"
            className="readyset-logo"
          />
          {authSteps[currentAuthStep].content}
        </div>
      </Card>
    </div>
  );
};

export default LogInView;
