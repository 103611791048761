import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { ApiError, handleError, PaginationModel } from '../../../api/base';
import ClientService from '../../../api/client';
import CustomFieldService from '../../../api/custom-field';
import { LicenseProps } from '../../../components/containers/forms/LicenseForm/LicenseForm';
import { ClientModel, FieldModel, MarketMap } from '../../../models/client';
import { EmailNotificationModel } from '../../../models/emailNotification';
import { LicenseModel } from '../../../models/license';
import { UserModel } from '../../../models/user';
import { convertParamsToQuery } from '../../../util';

export interface ClientsState {
  // Clients
  clients: ClientModel[];
  fetchingClients: boolean;
  searchingClients: boolean;
  pagination: PaginationModel | null;
  error: {
    code: number | null;
    message: string | null;
  };
  count: number;
  current: number;
  // Client
  client: ClientModel | null;
  myClient: ClientModel | null;
  fetchingClient: boolean;
  updatingClient: boolean;
  // Client Users
  users: UserModel[];
  searchingUsers: boolean;
  usersPagination: PaginationModel | null;
  // Client Licenses
  licenses: LicenseModel[];
  fetchingLicenses: boolean;
  searchingLicenses: boolean;
  savingLicenses: boolean;
  licensesPagination: PaginationModel | null;
  licensesCount: number;
  licensesCurrent: number;
  // Client License
  license: LicenseModel | null;
  // Client Email Notifications
  emailNotifications: EmailNotificationModel[];
  fetchingEmailNotifications: boolean;
}

export interface ClientsParams {
  _limit?: number;
  _order_by?: string;
  _offset?: number;
  _columns?: string;
  name?: string;
  q?: string;
}

interface FetchClientsProps {
  offset?: number;
  query?: string;
}
interface CreateClientProps {
  client: CreateClientModel;
}

interface EmailNotificationProps {
  short_name?: string;
  fields: {
    email_notify_list: EmailNotificationModel[];
  };
}

interface CreateClientLicenseProps {
  clientId: string;
  license: LicenseProps;
}

interface DeleteClientLicenseProps {
  clientId: string;
  licenseId: string;
}
interface ClientEmailNotificationProps {
  clientId: string;
  emailNotificationField: EmailNotificationProps;
}

interface UpdateClientModel {
  short_name: string;
  fields?: {};
}
interface CreateClientModel {
  name: string;
  short_name: string;
  fields?: FieldModel[] | FieldModel;
}
interface UpdateClientProps {
  clientId: string;
  client: UpdateClientModel;
}

interface UpdateClientLicenseProps {
  clientId: string;
  licenseId: string;
  license: LicenseProps;
}

const initialState: ClientsState = {
  clients: [],
  fetchingClients: false,
  searchingClients: false,
  pagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
  error: { code: null, message: null },
  count: 0,
  current: 1,
  // Client
  client: null,
  myClient: null,
  fetchingClient: false,
  updatingClient: false,
  // Client Users
  users: [],
  searchingUsers: false,
  usersPagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
  // Client Licenses
  licenses: [],
  fetchingLicenses: false,
  searchingLicenses: false,
  savingLicenses: false,
  licensesPagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
  licensesCount: 0,
  licensesCurrent: 1,
  // Client License
  license: null,
  // Client Email Notifications
  emailNotifications: [],
  fetchingEmailNotifications: false,
};

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async ({ offset = 0, query }: FetchClientsProps, { rejectWithValue }) => {
    try {
      let params: ClientsParams = {
        _order_by: 'created_at:desc',
        _limit: 10,
        _offset: offset,
        _columns: 'license.*',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await ClientService().listClients(queryString);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getClient = createAsyncThunk(
  'clients/getClient',
  async ({ id, me }: { id: string; me?: boolean }, { rejectWithValue }) => {
    console.log('Fetching client with ID:', id);
    try {
      const response = await ClientService().getClient(id);

      const { fields, ...clientData } = response.data;
      const email_notification = (fields as FieldModel[]).find(
        (field) => field.field_key === 'email_notify_list'
      )?.value;
      const marketFieldValue = (fields as FieldModel[]).find(
        (field) => field.field_key === 'default_market'
      )?.value;
      const markets = MarketMap[marketFieldValue as keyof typeof MarketMap];
      const notes = (fields as FieldModel[]).find(
        (field) => field.field_key === 'client_notes'
      )?.value;

      const clientTypeValue = (fields as FieldModel[]).find(
        (field) => field.field_key === 'client_type'
      )?.value;

      const clientTypeResponse = await CustomFieldService().getCustomField(
        'client_type'
      );

      const clientTypeList = clientTypeResponse.data.options;
      const client_type = clientTypeList.find(
        (type) => type.id === clientTypeValue
      )?.name;

      return {
        me,
        data: {
          ...clientData,
          email_notification,
          markets,
          notes,
          client_type,
        },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createClient = createAsyncThunk(
  'Client/createClient',
  async ({ client }: CreateClientProps, { dispatch, rejectWithValue }) => {
    try {
      const resp = await ClientService().createClient(client);

      await dispatch(searchClientsByName(''));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateClient = createAsyncThunk(
  'Client/updateClient',
  async (
    { clientId, client }: UpdateClientProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ClientService().updateClient(clientId, client);

      await dispatch(getClient({ id: clientId }));
      await dispatch(searchClientsByName(''));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteClient = createAsyncThunk(
  'Client/deleteClient',
  async (clientId: string, { dispatch, rejectWithValue }) => {
    try {
      const resp = await ClientService().deleteClient(clientId);

      await dispatch(searchClientsByName(''));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchClientsByName = createAsyncThunk(
  'clients/searchClientsByName',
  async (query: string, { getState, rejectWithValue }) => {
    try {
      const {
        clients: { pagination },
      } = getState() as RootState;

      let params: ClientsParams = {
        _order_by: 'created_at:desc',
        _columns: 'license.*',
        _limit: pagination?.limit ?? 10,
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);
      const resp = await ClientService().listClients(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchClientUsersByName = createAsyncThunk(
  'clients/searchClientUsersByName',
  async ({ id, query }: { id: string; query: string }, { rejectWithValue }) => {
    try {
      let params: ClientsParams = {
        _order_by: 'created_at:desc',
        _columns: 'roles.*',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await ClientService().listClientUsers(id, queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchClientLicensesByName = createAsyncThunk(
  'clients/searchClientLicensesByName',
  async ({ id, query }: { id: string; query: string }, { rejectWithValue }) => {
    try {
      let params: ClientsParams = {
        _order_by: 'created_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await ClientService().listClientLicenses(id, queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateClients = createAsyncThunk(
  'client/paginateClients',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: ClientsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'created_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          q: `${queryStr.toLowerCase()}`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await ClientService().listClients(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateClientLicenses = createAsyncThunk(
  'client/paginateClientLicenses',
  async (
    {
      clientId,
      page,
      pageSize,
      queryStr,
    }: {
      clientId: string;
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: ClientsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'created_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          q: `${queryStr.toLowerCase()}`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await ClientService().listClientLicenses(clientId, query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getClientLicense = createAsyncThunk(
  'Client/getClientLicense',
  async (clientId: string, { dispatch, rejectWithValue }) => {
    try {
      const resp = await ClientService().getClientLicense(clientId);

      await dispatch(searchClientLicensesByName({ id: clientId, query: '' }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createClientLicense = createAsyncThunk(
  'Client/createClientLicense',
  async (
    { clientId, license }: CreateClientLicenseProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ClientService().createClientLicense(clientId, license);

      await dispatch(searchClientLicensesByName({ id: clientId, query: '' }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteClientLicense = createAsyncThunk(
  'Client/deleteClientLicense',
  async (
    { clientId, licenseId }: DeleteClientLicenseProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ClientService().deleteClientLicense(
        clientId,
        licenseId
      );

      await dispatch(searchClientLicensesByName({ id: clientId, query: '' }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateClientLicense = createAsyncThunk(
  'Client/updateClientLicense',
  async (
    { clientId, licenseId, license }: UpdateClientLicenseProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ClientService().updateClientLicense(
        clientId,
        licenseId,
        license
      );

      await dispatch(searchClientLicensesByName({ id: clientId, query: '' }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createClientEmailNotification = createAsyncThunk(
  'Client/createClientEmailNotification',
  async (
    { clientId, emailNotificationField }: ClientEmailNotificationProps,
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const existingEmailNotifications = state.clients.emailNotifications;

      const newEmailNotifications =
        emailNotificationField.fields.email_notify_list;

      const updatedEmailNotifications: EmailNotificationModel[] = [
        ...existingEmailNotifications,
        ...newEmailNotifications,
      ];

      emailNotificationField.fields.email_notify_list =
        updatedEmailNotifications;

      const resp = await ClientService().updateClientEmailNotification(
        clientId,
        emailNotificationField
      );

      const emailNotificationsResp = resp.data.fields.filter(
        (field: FieldModel) => field.field_key === 'email_notify_list'
      )[0];
      const emailNotifications = JSON.parse(emailNotificationsResp?.value);
      return emailNotifications;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteClientEmailNotification = createAsyncThunk(
  'Client/deleteClientEmailNotification',
  async (
    { clientId, emailNotificationField }: ClientEmailNotificationProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ClientService().updateClientEmailNotification(
        clientId,
        emailNotificationField
      );
      const emailNotificationsResp = resp.data.fields.filter(
        (field: FieldModel) => field.field_key === 'email_notify_list'
      )[0];
      const emailNotifications = JSON.parse(emailNotificationsResp?.value);
      await dispatch(getClientEmailNotification({ clientId }));
      return emailNotifications;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getClientEmailNotification = createAsyncThunk(
  'Client/getClientEmailNotification',
  async ({ clientId }: { clientId: string }, { rejectWithValue }) => {
    try {
      const resp = await ClientService().getClient(clientId);

      const emailNotificationField = (
        resp?.data?.fields as FieldModel[]
      )?.filter(
        (field: FieldModel) => field?.field_key === 'email_notify_list'
      )[0];

      if (emailNotificationField) {
        return JSON.parse(emailNotificationField?.value as string);
      }
      return [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreClientUsers = createAsyncThunk(
  'Client/loadMoreUsers',
  async (clientId: string, { getState, rejectWithValue }) => {
    try {
      const {
        clients: { usersPagination },
      } = getState() as RootState;

      const limit = usersPagination?.limit!;
      const offset = usersPagination?.offset!;
      const nextOffset = limit + offset;

      const nextParams = {
        _order_by: 'created_at:desc',
        _limit: limit,
        _offset: nextOffset,
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await ClientService().listClientUsers(clientId, query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.fetchingClients = true;
      })
      .addCase(fetchClients.fulfilled, (state, action: any) => {
        state.clients = [...action.payload];
        state.pagination = action.payload.pagination;
        state.fetchingClients = false;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.fetchingClients = false;
      });

    // getCliet
    builder
      .addCase(getClient.pending, (state) => {
        state.fetchingClient = true;
      })
      .addCase(getClient.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.myClient = action.payload.data;
        } else {
          state.client = action.payload.data;
        }
        state.fetchingClient = false;
      })
      .addCase(getClient.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingClient = false;
      });

    builder
      .addCase(updateClient.pending, (state) => {
        state.updatingClient = true;
      })
      .addCase(updateClient.fulfilled, (state) => {
        state.updatingClient = false;
      })
      .addCase(updateClient.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.updatingClient = false;
      });

    builder
      .addCase(getClientLicense.pending, (state) => {
        state.fetchingClient = true;
      })
      .addCase(getClientLicense.fulfilled, (state, action: any) => {
        state.license = action.payload.data;
        state.fetchingClient = false;
      })
      .addCase(getClientLicense.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingClient = false;
      });

    builder
      .addCase(createClientLicense.pending, (state) => {
        state.savingLicenses = true;
      })
      .addCase(createClientLicense.fulfilled, (state) => {
        state.savingLicenses = false;
      })
      .addCase(createClientLicense.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingLicenses = false;
      });

    builder
      .addCase(updateClientLicense.pending, (state) => {
        state.savingLicenses = true;
      })
      .addCase(updateClientLicense.fulfilled, (state, action: any) => {
        state.license = action.payload.data;
        state.savingLicenses = false;
      })
      .addCase(updateClientLicense.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingLicenses = false;
      });

    builder
      .addCase(searchClientUsersByName.pending, (state) => {
        state.searchingUsers = true;
      })
      .addCase(searchClientUsersByName.fulfilled, (state, action: any) => {
        state.users = action.payload.data;
        state.usersPagination = action.payload.pagination;
        state.searchingUsers = false;
      })
      .addCase(searchClientUsersByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.searchingUsers = false;
      });

    builder
      .addCase(searchClientLicensesByName.pending, (state) => {
        state.searchingLicenses = true;
      })
      .addCase(searchClientLicensesByName.fulfilled, (state, action: any) => {
        state.licensesCount = action.payload.pagination.count;
        state.fetchingLicenses = false;
        state.searchingLicenses = false;
        state.licenses = action.payload.data;
        state.licensesPagination = action.payload.pagination;
        state.licensesCount = 1;
      })
      .addCase(searchClientLicensesByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingLicenses = false;
        state.searchingLicenses = false;
      });

    builder
      .addCase(searchClientsByName.pending, (state) => {
        state.searchingClients = true;
        state.fetchingClients = false;
        state.error = { code: null, message: null };
      })
      .addCase(searchClientsByName.fulfilled, (state, action) => {
        state.count = action.payload.pagination.count;
        state.clients = action.payload.data;
        state.pagination = action.payload.pagination;
        state.searchingClients = false;
        state.fetchingClients = false;
        state.error = { code: null, message: null };
      })
      .addCase(searchClientsByName.rejected, (state, action) => {
        state.searchingClients = false;
        state.fetchingClients = false;
        state.error = {
          code: action.payload ? 400 : 500,
          message: (action.payload as string) || 'An unknown error occurred',
        };
      });

    builder
      .addCase(paginateClients.pending, (state) => {
        state.fetchingClients = true;
      })
      .addCase(paginateClients.fulfilled, (state, action) => {
        state.fetchingClients = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.clients = [...action.payload.data];
          state.fetchingClients = false;
          state.current = action.payload.current;
        }
      })
      .addCase(paginateClients.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingClients = false;
      });

    builder
      .addCase(paginateClientLicenses.pending, (state) => {
        state.fetchingLicenses = true;
      })
      .addCase(paginateClientLicenses.fulfilled, (state, action) => {
        state.fetchingLicenses = false;
        if (action.payload) {
          state.licensesPagination = action.payload.pagination;
          state.licenses = [...action.payload.data];
          state.fetchingLicenses = false;
          state.licensesCurrent = action.payload.current;
        }
      })
      .addCase(paginateClientLicenses.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingLicenses = false;
      });

    builder
      .addCase(createClientEmailNotification.pending, (state) => {
        state.fetchingEmailNotifications = true;
      })
      .addCase(createClientEmailNotification.fulfilled, (state, action) => {
        state.fetchingEmailNotifications = false;
        if (action.payload) {
          state.emailNotifications = [
            ...state.emailNotifications,
            ...action.payload.filter(
              (emailNotification: EmailNotificationModel) => {
                return !state.emailNotifications.some(
                  (existingEmailNotification) =>
                    existingEmailNotification.email === emailNotification.email
                );
              }
            ),
          ];
        }
      })
      .addCase(createClientEmailNotification.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingEmailNotifications = false;
      });

    builder
      .addCase(deleteClientEmailNotification.pending, (state) => {
        state.fetchingEmailNotifications = true;
      })
      .addCase(deleteClientEmailNotification.fulfilled, (state, action) => {
        state.fetchingEmailNotifications = false;
        if (action.payload) {
          state.emailNotifications = state.emailNotifications.filter(
            (emailNotification) =>
              emailNotification.email !== action.payload.email ||
              emailNotification.name !== action.payload.name
          );
        }
      })
      .addCase(deleteClientEmailNotification.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingEmailNotifications = false;
      });

    builder
      .addCase(getClientEmailNotification.pending, (state) => {
        state.fetchingEmailNotifications = true;
      })
      .addCase(getClientEmailNotification.fulfilled, (state, action) => {
        state.fetchingEmailNotifications = false;
        if (action.payload) {
          state.emailNotifications = [
            // ...state.emailNotifications,
            ...action.payload,
          ];
        }
      })
      .addCase(getClientEmailNotification.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingEmailNotifications = false;
      });

    builder.addCase(loadMoreClientUsers.fulfilled, (state, action: any) => {
      state.searchingUsers = false;
      state.usersPagination = action.payload.pagination;
      state.users = [...state.users, ...action.payload.data];
    });
  },
});

export default clientsSlice.reducer;
