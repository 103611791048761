import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { BackTop, Badge, Empty, Input, Space, Spin, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './StoreBuilderView.less';

import { useAuth } from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  clearCurrentStoreBuild,
  loadMoreStoreBuilds,
  searchStoreBuildsByName,
} from '../../../store/features/storeBuilds/storeBuildsSlice';
import { UserPermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import Button from '../../elements/Button';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import StoreBuildItemsList from './components/StoreBuildItemsList';

const { Title } = Typography;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
const storeBuildsItemLengthKey = 'store-builds-item-length-key';

const StoreBuilderView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);
  const { hasPermission } = useAuth();

  const {
    value: storeBuilds,
    fetchingStoreBuilds,
    totalCount,
    pagination,
    searchingStoreBuilds,
    allStoreBuilds,
  } = useAppSelector((state) => state.storeBuilds);

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingStoreBuilds && !!searchQuery && storeBuilds.length === 0,
    [fetchingStoreBuilds, searchQuery, storeBuilds.length]
  );

  const hasStoreBuilds = !(!fetchingStoreBuilds && allStoreBuilds.length === 0);

  const hasMoreStoreBuilds =
    hasStoreBuilds && pagination?.offset! + pagination?.limit! < totalCount!;

  const isPermittedToLoadMore =
    !fetchingStoreBuilds &&
    !fetchingStoreBuilds &&
    !searchQuery &&
    hasMoreStoreBuilds;

  useEffect(() => {
    dispatch(searchStoreBuildsByName({ query: debouncedSearchQuery }));
  }, [debouncedSearchQuery, dispatch]);
  useEffect(() => {
    cacheStoreBuildItemsLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeBuilds.length]);

  const cacheStoreBuildItemsLength = () => {
    if (!!searchQuery || storeBuilds.length > 20) return;

    localStorage.setItem(storeBuildsItemLengthKey, `${storeBuilds.length}`);
  };

  const createNewBuildButton = useMemo(
    () => (
      <Link to={DrawerHashRoute.BuildForm}>
        <Button type="primary" data-cy="create-new-build-btn">
          Create New Build
          <PlusOutlined />
        </Button>
      </Link>
    ),
    []
  );

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const handleOpenNewBuildDrawer = useCallback(() => {
    dispatch(clearCurrentStoreBuild());
    return history.push(DrawerHashRoute.BuildForm);
  }, [dispatch, history]);

  const headerSectionRight = useMemo(
    () => (
      <Space direction="horizontal" size="middle">
        <Input
          allowClear={!searchingStoreBuilds}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          placeholder="Search for a build..."
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          suffix={searchingStoreBuilds && <Spin indicator={antSpinIcon} />}
        />
        {hasPermission(UserPermissions.FebDesignsCreate) && (
          <Button type="primary" onClick={handleOpenNewBuildDrawer}>
            Create New Build
            <PlusOutlined />
          </Button>
        )}
      </Space>
    ),
    [
      searchingStoreBuilds,
      searchQuery,
      hasPermission,
      handleOpenNewBuildDrawer,
      updateSearchQuery,
    ]
  );

  return (
    <ViewWrapper
      headerTitle={
        <Space direction="horizontal">
          <Title level={3}>Builds</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
            overflowCount={999}
          />
        </Space>
      }
      headerSectionRight={hasStoreBuilds && headerSectionRight}
    >
      {hasStoreBuilds ? (
        hasNoSearchResultsFound ? (
          <NoResultsFound
            searchQuery={searchQuery}
            onClear={() => updateSearchQuery('')}
          />
        ) : (
          <StoreBuildItemsList
            skeletonItemLength={
              (+localStorage.getItem(storeBuildsItemLengthKey)! as number) || 5
            }
            loading={fetchingStoreBuilds}
            builds={storeBuilds}
            onLoadMore={() => dispatch(loadMoreStoreBuilds())}
            hasMore={isPermittedToLoadMore}
          />
        )
      ) : (
        <Empty
          description="You currently have no build projects."
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
          {createNewBuildButton}
        </Empty>
      )}

      <BackTop />
    </ViewWrapper>
  );
};

export default StoreBuilderView;
