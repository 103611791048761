import { useState } from 'react';

import { Tabs, Typography } from 'antd';

import ViewWrapper from '../../elements/ViewWrapper';

import './ManualView.less';

const { Title } = Typography;
const { TabPane } = Tabs;

const ManualTab = () => {
  return (
    <div>
      <div className="manuals-container">
        <div className="manual-link">
          <a
            style={{ color: '#8e24aa' }}
            href="https://s3.us-east-2.amazonaws.com/docs.dev.readysetvr.com/ReadySetUserManual.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            ReadySet User Manual
          </a>
        </div>
        <div className="manual-link">
          <a
            style={{ color: '#8e24aa' }}
            href="https://s3.us-east-2.amazonaws.com/docs.dev.readysetvr.com/OVR+Realtime+Data+Instructions.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            OVR Parameter Guide
          </a>
        </div>
      </div>
    </div>
  );
};

const VideosTab = () => {
  const videoList = [
    {
      id: 1,
      url: 'https://s3.us-east-2.amazonaws.com/docs.dev.readysetvr.com/HardwareSetupManualViveVersionV2.mp4',
    },
  ];

  return (
    <div className="video-tabs-container">
      <Title level={3}>ReadySet User Manual Videos</Title>
      <div className="video-container">
        {videoList.map((video) => (
          <div key={video.id}>
            <video width="100%" controls>
              <source src={video.url} type="video/mp4" />
            </video>
            <Title level={4}>Hardware Setup Manual Vive Version V2</Title>
          </div>
        ))}
      </div>
    </div>
  );
};

const ManualView = () => {
  const [activeTab, setActiveTab] = useState('manuals');

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };
  return (
    <ViewWrapper>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Manuals" key="manuals">
          <ManualTab />
        </TabPane>
        <TabPane tab="Videos" key="videos">
          <VideosTab />
        </TabPane>
      </Tabs>
    </ViewWrapper>
  );
};

export default ManualView;
