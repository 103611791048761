import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Form, Input, message, Select, Space } from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import CustomFieldService from '../../../../api/custom-field';
import { useAuth } from '../../../../hooks/useAuth';
import {
  ClientActive,
  FieldModel,
  initialNewClientModel,
} from '../../../../models/client';
import { CustomFieldOption } from '../../../../models/custom-field';
import { MarketModel } from '../../../../models/market';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createClient,
  updateClient,
} from '../../../../store/features/clients/clientsSlice';
import { propsAreEqual, UserPermissions } from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { ClientFormLocationState } from './types';

import './ClientForm.less';

const { Option } = Select;

interface ClientFormProps {
  history: History;
  location: Location<ClientFormLocationState>;
}

interface ClientProps {
  name: string;
  short_name: string;
  uuid: string;
  fields?: FieldModel[];
  active?: ClientActive;
  created_at?: string;
  id?: number;
  parent_id?: string;
  updated_at?: string;
}

const ClientForm: FC<ClientFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const { fetchingClients, updatingClient } = useAppSelector(
    (state) => state.clients
  );
  const [form] = Form.useForm();

  const [markets, setMarkets] = useState<CustomFieldOption[]>([]);
  const [clientTypes, setClientTypes] = useState<CustomFieldOption[]>([]);

  const clientLocationData = location.state?.client;
  const isNewClient = useMemo(() => !clientLocationData, [clientLocationData]);
  const fields = useMemo(
    () => Object.keys(isNewClient ? initialNewClientModel : clientLocationData),
    [isNewClient, clientLocationData]
  );

  const { hasPermission } = useAuth();

  const onSuccess = useCallback(() => {
    message.success('Client saved.');
    history.goBack();
  }, [history]);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const saveClient = useCallback(
    async (client: ClientProps) => {
      const { short_name, uuid } = client;
      const newClient: ClientProps = {
        ...client,
        short_name: short_name!,
        uuid: clientLocationData?.uuid,
      };

      try {
        const res: any = isNewClient
          ? await dispatch(createClient({ client: newClient }))
          : await dispatch(
              updateClient({
                clientId: uuid || clientLocationData?.uuid,
                client: newClient,
              })
            );

        if (res.error) {
          onError({ error: res.payload.error });
        } else {
          onSuccess();
        }
      } catch (error: any) {
        onError(error);
      }
    },
    [dispatch, isNewClient, clientLocationData?.uuid, onError, onSuccess]
  );

  const onSubmit = useCallback(
    (values: ClientProps) => {
      form.validateFields(fields).then(() => saveClient(values));
    },
    [fields, form, saveClient]
  );

  const getInitialValues = () => {
    if (isNewClient) {
      return { ...initialNewClientModel };
    }

    const { fields } = clientLocationData;

    const defaultMarket = (fields as FieldModel[])?.find(
      (field) => field.field_key === 'default_market'
    );

    return {
      ...clientLocationData,
      fields: {
        default_market: clientLocationData?.markets || defaultMarket || null,
        client_type: clientLocationData?.client_type || null,
      },
    };
  };

  useEffect(() => {
    const fetchMarketsAndClientTypes = async () => {
      try {
        const [marketsResponse, clientTypesResponse] = await Promise.all([
          CustomFieldService().getCustomField('market'),
          CustomFieldService().getCustomField('client_type'),
        ]);
        setMarkets(marketsResponse.data.options);
        setClientTypes(clientTypesResponse.data.options);
      } catch (error) {
        onError(error as ApiError);
      }
    };
    fetchMarketsAndClientTypes();
  }, [onError]);

  return (
    <FormWrapper
      title={`${isNewClient ? 'Create new' : 'Edit'} client`}
      onClose={() => history.goBack()}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Client"
          name="name"
          rules={[{ required: true, message: 'Client name is required.' }]}
        >
          <Input data-cy="client-form-input" placeholder="Client name" />
        </Form.Item>
        <Form.Item
          label="Client identifier (for usage in URL, nospace or special characters)"
          name="short_name"
          rules={[
            { required: true, message: 'Client identifier is required.' },
          ]}
        >
          <Input data-cy="client-form-input" placeholder="Client identifier" />
        </Form.Item>
        <Form.Item label="Client Type" name={['fields', 'client_type']}>
          <Select placeholder="Choose a client type">
            {clientTypes?.map((clientType: CustomFieldOption) => (
              <Option value={clientType.id}>{clientType.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Default Market" name={['fields', 'default_market']}>
          <Select placeholder="Choose a market">
            {markets?.map((market: MarketModel) => (
              <Option value={`${market.id}`}>{market.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          {isNewClient ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={fetchingClients}
                data-cy="client-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={!hasPermission(UserPermissions.ClientsEdit)}
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              <Button
                loading={updatingClient}
                type="primary"
                htmlType="submit"
                disabled={!hasPermission(UserPermissions.ClientsEdit)}
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(ClientForm, propsAreEqual));
