import { useCallback, useEffect, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { ApiError, handleError } from '../../../../api/base';
import { useAuth } from '../../../../hooks/useAuth';
import { EmailNotificationModel } from '../../../../models/emailNotification';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  deleteClientEmailNotification,
  getClientEmailNotification,
} from '../../../../store/features/clients/clientsSlice';
import EmailNotificationForm from '../../../containers/forms/EmailNotificationForm/EmailNotificationForm';
import { UserPermissions } from '../../../../util';

const { Title, Text } = Typography;

type EmailNotificationTableProps = {
  clientId: string;
};

const EmailNotificationTable = ({ clientId }: EmailNotificationTableProps) => {
  const dispatch = useAppDispatch();
  const [emailNotificationModalOpen, setEmailNotificationModalOpen] =
    useState(false);

  const { client, emailNotifications } = useAppSelector(
    (state) => state.clients
  );

  const { hasPermission } = useAuth();

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  useEffect(() => {
    dispatch(getClientEmailNotification({ clientId }));
  }, [clientId, dispatch]);

  const onSuccess = useCallback(() => {
    message.success(`Email deleted.`);
  }, []);

  const handleDeleteEmail = useCallback(
    async (emailRecord: EmailNotificationModel) => {
      try {
        const short_name = client?.short_name;
        const filteredEmailNotifications = emailNotifications.filter(
          (emailNotification) => {
            return emailNotification.email !== emailRecord.email;
          }
        );

        const updatedEmailNotification = {
          short_name,
          fields: {
            email_notify_list: filteredEmailNotifications,
          },
        };

        await dispatch(
          deleteClientEmailNotification({
            clientId,
            emailNotificationField: updatedEmailNotification,
          })
        );
        onSuccess();
      } catch (error) {
        onError(error as ApiError);
      }
    },
    [
      client?.short_name,
      clientId,
      dispatch,
      emailNotifications,
      onError,
      onSuccess,
    ]
  );

  const handleConfirmDeleteEmail = useCallback(
    (emailRecord: EmailNotificationModel) => (e: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the email "${emailRecord?.email}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer recieve notifications to "${emailRecord?.email}" after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteEmail(emailRecord),
      });
    },
    [handleDeleteEmail]
  );

  const columns: ColumnsType<EmailNotificationModel> = [
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      title: 'Action',
      width: '10%',
      render: (_text, record) => {
        return (
          <Space direction="horizontal" size="middle">
            {hasPermission(UserPermissions.ClientsEdit) ? (
              <Button
                type="link"
                className="link-button"
                onClick={handleConfirmDeleteEmail(record)}
              >
                Delete
              </Button>
            ) : null}
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: '1rem 0',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Title level={5}>Email Notification List</Title>
          <Text>
            Any emails added to this list will receive notifications about any
            seat/concurrent users
          </Text>
        </div>
        {hasPermission(UserPermissions.ClientsCreate) ? (
          <Button
            type="primary"
            onClick={() => setEmailNotificationModalOpen(true)}
          >
            Add Email
          </Button>
        ) : null}
      </div>

      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        rowKey={(emailNotification: EmailNotificationModel) =>
          `${emailNotification?.id}-row-key`
        }
        dataSource={emailNotifications}
      />

      <Modal
        key={client?.uuid}
        open={emailNotificationModalOpen}
        title={'Add Email'}
        footer={null}
        onCancel={() => {
          setEmailNotificationModalOpen(false);
        }}
      >
        <EmailNotificationForm
          clientId={clientId}
          data={client}
          closeModal={() => setEmailNotificationModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default EmailNotificationTable;
