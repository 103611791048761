import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { ApiError, handleError, PaginationModel } from '../../../api/base';
import MetaDataService from '../../../api/meta-data';
import { CountUnitModel } from '../../../models/count-unit';
import { FormatModel } from '../../../models/format';
import { MarketModel } from '../../../models/market';
import { CustomFieldModel, CustomFieldOption } from '../../../models/meta-data';
import { SizeUnitModel } from '../../../models/size-unit';
import { TagModel } from '../../../models/tag';
import { convertParamsToQuery } from '../../../util';

export interface MetaDataState {
  value: CustomFieldOption | null;
  customField: CustomFieldModel | null;
  allCustomFieldOptions: CustomFieldOption[];
  allCustomFieldOptionsValue: CustomFieldOption[];
  allTags: TagModel[];
  fetchingTags: boolean;
  searchingTags: boolean;
  fetchingCustomFieldOptions: boolean;
  searchingCustomFieldOptions: boolean;
  savingCustomFieldOption: boolean;
  deletingCustomFieldOption: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  count: number;
  current: number;
  currentPage: number;
  pageSize: number;
  allFormats: FormatModel[];
  fetchingFormats: boolean;
  searchingFormats: boolean;
  allMarkets: MarketModel[];
  fetchingMarkets: boolean;
  searchingMarkets: boolean;
  allCountUnits: CountUnitModel[];
  fetchingCountUnits: boolean;
  searchingCountUnits: boolean;
  allSizeUnits: SizeUnitModel[];
  fetchingSizeUnits: boolean;
  searchingSizeUnits: boolean;
}

interface CreateCustomFieldOptionProps {
  customFieldKey: string;
  customFieldOption: CustomFieldOption;
}

interface UpdateCustomFieldOptionProps {
  customFieldKey: string;
  optionId: number;
  customFieldOption: CustomFieldOption;
}
interface DeleteCustomFieldOptionProps {
  customFieldKey: string;
  optionId: number;
}

const initialState: MetaDataState = {
  value: null,
  customField: null,
  allCustomFieldOptions: [],
  allCustomFieldOptionsValue: [],
  allTags: [],
  fetchingTags: false,
  searchingTags: false,
  fetchingCustomFieldOptions: false,
  searchingCustomFieldOptions: false,
  savingCustomFieldOption: false,
  deletingCustomFieldOption: false,
  error: { code: null, message: null },
  pagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
  count: 0,
  current: 1,
  currentPage: 1,
  pageSize: 10,
  allFormats: [],
  fetchingFormats: false,
  searchingFormats: false,
  allMarkets: [],
  fetchingMarkets: false,
  searchingMarkets: false,
  allCountUnits: [],
  fetchingCountUnits: false,
  searchingCountUnits: false,
  allSizeUnits: [],
  fetchingSizeUnits: false,
  searchingSizeUnits: false,
};
export interface CustomFieldOptionsParams {
  _limit?: number;
  _order_by?: string;
  _offset?: number;
  _columns?: string;
  name?: string;
  q?: string;
  status?: string;
}

interface searchCustomFieldOptionsByNameProps {
  customFieldKey: string;
  query: string;
}

interface fetchCustomFieldOptionsProps {
  customFieldKey: string;
  params?: CustomFieldOptionsParams;
}

interface TagsParams {
  _limit?: number;
  _offset?: number;
  _order_by?: string;
  name?: string;
  q?: string;
}

interface FormatsParams {
  _limit?: number;
  _offset?: number;
  _order_by?: string;
  name?: string;
  q?: string;
}

interface MarketsParams {
  _limit?: number;
  _offset?: number;
  _order_by?: string;
  name?: string;
  q?: string;
}

interface CountUnitsParams {
  _limit?: number;
  _offset?: number;
  _order_by?: string;
  name?: string;
  q?: string;
}

interface SizeUnitsParams {
  _limit?: number;
  _offset?: number;
  _order_by?: string;
  name?: string;
  q?: string;
}

export const fetchCustomFieldOptionsByKey = createAsyncThunk(
  'metaData/fetchCustomFieldOptionsByKey',
  async (
    { customFieldKey }: fetchCustomFieldOptionsProps,
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      const nextParams: CustomFieldOptionsParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
      };

      const queryString = convertParamsToQuery(nextParams);
      let resp = await MetaDataService().listCustomFieldOptionsByKey(
        customFieldKey,
        queryString
      );

      const { limit, count, offset } = resp.pagination;
      let current = offset / limit + 1;
      if (offset >= count && count > 0) {
        current = current - 1;
        const nextParams = {
          _limit: limit,
          _offset: offset - limit,
          _order_by: 'created_at:desc',
        };

        const queryString = convertParamsToQuery(nextParams);
        resp = await MetaDataService().listCustomFieldOptionsByKey(
          customFieldKey,
          queryString
        );
      }

      return {
        current,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createCustomFieldOption = createAsyncThunk(
  'metaData/createCustomFieldOption',
  async (
    { customFieldKey, customFieldOption }: CreateCustomFieldOptionProps,
    { rejectWithValue }
  ) => {
    try {
      const resp = await MetaDataService().createCustomFieldOption(
        customFieldKey,
        customFieldOption
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateCustomFieldOption = createAsyncThunk(
  'metaData/updateCustomFieldOption',
  async (
    {
      customFieldKey,
      optionId,
      customFieldOption,
    }: UpdateCustomFieldOptionProps,
    { rejectWithValue }
  ) => {
    try {
      const resp = await MetaDataService().updateCustomFieldOption(
        customFieldKey,
        optionId,
        customFieldOption
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCustomField = createAsyncThunk(
  'metaData/fetchCustomField',
  async (customFieldKey: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await MetaDataService().getCustomField(customFieldKey);

      await dispatch(fetchCustomFieldOptionsByKey({ customFieldKey }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCustomFieldOption = createAsyncThunk(
  'metaData/deleteCustomFieldOption',
  async (
    { customFieldKey, optionId }: DeleteCustomFieldOptionProps,
    { rejectWithValue }
  ) => {
    try {
      const resp = await MetaDataService().deleteCustomFieldOption(
        customFieldKey,
        optionId
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTags = createAsyncThunk(
  'metaData/fetchTags',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await MetaDataService().getTags();

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchTagsByName = createAsyncThunk(
  'metaData/searchTagsByName',
  async ({ query }: { query: string }, { getState, rejectWithValue }) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      let params: TagsParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);
      console.log(queryString);
      const resp = await MetaDataService().searchTagsByName(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        fetchingCustomFieldsMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchCustomFieldOptionsByName = createAsyncThunk(
  'metaData/searchCustomFieldOptionsByName',
  async (
    { customFieldKey, query }: searchCustomFieldOptionsByNameProps,
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      let params: CustomFieldOptionsParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await MetaDataService().listCustomFieldOptionsByKey(
        customFieldKey,
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        fetchingCustomFieldsMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateCustomFieldOptions = createAsyncThunk(
  'productList/paginateCustomFieldOptions',
  async (
    {
      customFieldKey,
      page,
      pageSize,
      queryStr,
    }: {
      customFieldKey: string;
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: CustomFieldOptionsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          q: `${queryStr.toLowerCase()}`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await MetaDataService().listCustomFieldOptionsByKey(
        customFieldKey,
        query
      );

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateTags = createAsyncThunk(
  'metaData/paginateTags',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: TagsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'created_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          name: `*${queryStr.toLowerCase()}*`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await MetaDataService().searchTagsByName(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchFormats = createAsyncThunk(
  'metaData/fetchFormats',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await MetaDataService().getFormats();
      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchFormatsByName = createAsyncThunk(
  'metaData/searchFormatsByName',
  async ({ query }: { query: string }, { getState, rejectWithValue }) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      const params = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
        name: `*${query.toLowerCase()}*`,
      };

      const queryString = convertParamsToQuery(params);
      const resp = await MetaDataService().searchFormatsByName(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateFormats = createAsyncThunk(
  'metaData/paginateFormats',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: FormatsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'created_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          name: `*${queryStr.toLowerCase()}*`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await MetaDataService().searchFormatsByName(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMarkets = createAsyncThunk(
  'metaData/fetchMarkets',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await MetaDataService().getMarkets();
      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchMarketsByName = createAsyncThunk(
  'metaData/searchMarketsByName',
  async ({ query }: { query: string }, { getState, rejectWithValue }) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      const params = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
        name: `*${query.toLowerCase()}*`,
      };

      const queryString = convertParamsToQuery(params);
      const resp = await MetaDataService().searchMarketsByName(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateMarkets = createAsyncThunk(
  'metaData/paginateMarkets',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: MarketsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'created_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          name: `*${queryStr.toLowerCase()}*`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await MetaDataService().searchMarketsByName(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCountUnits = createAsyncThunk(
  'metaData/fetchCountUnits',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await MetaDataService().getCountUnits();
      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchCountUnitsByName = createAsyncThunk(
  'metaData/searchCountUnitsByName',
  async ({ query }: { query: string }, { getState, rejectWithValue }) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      const params = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
        name: `*${query.toLowerCase()}*`,
      };

      const queryString = convertParamsToQuery(params);
      const resp = await MetaDataService().searchCountUnitsByName(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateCountUnits = createAsyncThunk(
  'metaData/paginateCountUnits',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: CountUnitsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'created_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          name: `*${queryStr.toLowerCase()}*`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await MetaDataService().searchCountUnitsByName(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSizeUnits = createAsyncThunk(
  'metaData/fetchSizeUnits',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await MetaDataService().getSizeUnits();
      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchSizeUnitsByName = createAsyncThunk(
  'metaData/searchSizeUnitsByName',
  async ({ query }: { query: string }, { getState, rejectWithValue }) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      const params = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
        name: `*${query.toLowerCase()}*`,
      };

      const queryString = convertParamsToQuery(params);
      const resp = await MetaDataService().searchSizeUnitsByName(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateSizeUnits = createAsyncThunk(
  'metaData/paginateSizeUnits',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: SizeUnitsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'created_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          name: `*${queryStr.toLowerCase()}*`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await MetaDataService().searchSizeUnitsByName(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const metaDataSlice = createSlice({
  name: 'metaData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomField.pending, (state) => {
        state.savingCustomFieldOption = true;
      })
      .addCase(fetchCustomField.fulfilled, (state, action) => {
        state.savingCustomFieldOption = false;
        state.customField = action.payload;
      })
      .addCase(fetchCustomField.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.savingCustomFieldOption = false;
      })

      .addCase(updateCustomFieldOption.pending, (state) => {
        state.savingCustomFieldOption = true;
      })
      .addCase(updateCustomFieldOption.fulfilled, (state, action) => {
        state.savingCustomFieldOption = false;
        state.value = action.payload;
      })
      .addCase(updateCustomFieldOption.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingCustomFieldOption = false;
      })

      .addCase(deleteCustomFieldOption.pending, (state) => {
        state.deletingCustomFieldOption = true;
      })

      .addCase(deleteCustomFieldOption.fulfilled, (state) => {
        state.deletingCustomFieldOption = false;
      })

      .addCase(deleteCustomFieldOption.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.deletingCustomFieldOption = false;
      })

      .addCase(createCustomFieldOption.pending, (state) => {
        state.savingCustomFieldOption = true;
      })
      .addCase(createCustomFieldOption.fulfilled, (state, action) => {
        state.savingCustomFieldOption = false;
        state.value = action.payload;
      })
      .addCase(createCustomFieldOption.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingCustomFieldOption = false;
      })

      .addCase(fetchCustomFieldOptionsByKey.pending, (state) => {
        state.fetchingCustomFieldOptions = true;
      })
      .addCase(fetchCustomFieldOptionsByKey.fulfilled, (state, action) => {
        state.fetchingCustomFieldOptions = false;
        if (action.payload) {
          state.count = action.payload.pagination.count;
          state.current = action.payload.current;
          state.allCustomFieldOptionsValue = action.payload.data;
          state.allCustomFieldOptions = action.payload.data;
          state.pagination = action.payload.pagination;
        }
      })
      // REJECTED
      .addCase(fetchCustomFieldOptionsByKey.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFieldOptions = false;
        state.searchingCustomFieldOptions = false;
      })

      .addCase(searchCustomFieldOptionsByName.pending, (state) => {
        state.searchingCustomFieldOptions = true;
      })
      .addCase(searchCustomFieldOptionsByName.fulfilled, (state, action) => {
        if (action.payload) {
          state.count = action.payload.pagination.count;
          state.fetchingCustomFieldOptions = false;
          state.searchingCustomFieldOptions = false;
          state.allCustomFieldOptionsValue = action.payload.data;
          state.pagination = action.payload.pagination;
          state.current = 1;
        }
      })
      .addCase(searchCustomFieldOptionsByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFieldOptions = false;
        state.searchingCustomFieldOptions = false;
      })

      .addCase(paginateCustomFieldOptions.pending, (state) => {
        state.fetchingCustomFieldOptions = true;
      })
      .addCase(paginateCustomFieldOptions.fulfilled, (state, action) => {
        state.fetchingCustomFieldOptions = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.allCustomFieldOptionsValue = [...action.payload.data];
          state.fetchingCustomFieldOptions = false;
          state.current = action.payload.current;
        }
      })
      .addCase(paginateCustomFieldOptions.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFieldOptions = false;
      })
      .addCase(fetchTags.pending, (state) => {
        state.fetchingTags = true;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.fetchingTags = false;
        if (action.payload) {
          state.allTags = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(fetchTags.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingTags = false;
      })
      .addCase(searchTagsByName.pending, (state) => {
        state.searchingTags = true;
      })
      .addCase(searchTagsByName.fulfilled, (state, action) => {
        state.searchingTags = false;
        if (action.payload) {
          state.allTags = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
          state.currentPage = 1;
        }
      })
      .addCase(searchTagsByName.rejected, (state) => {
        state.searchingTags = false;
      })
      .addCase(paginateTags.pending, (state) => {
        state.fetchingTags = true;
      })
      .addCase(paginateTags.fulfilled, (state, action) => {
        state.fetchingTags = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.allTags = [...action.payload.data];
          state.current = action.payload.current;
          state.currentPage = action.meta.arg.page;
          state.pageSize = action.meta.arg.pageSize;
        }
      })
      .addCase(paginateTags.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingTags = false;
      })
      .addCase(fetchFormats.pending, (state) => {
        state.fetchingFormats = true;
      })
      .addCase(fetchFormats.fulfilled, (state, action) => {
        state.fetchingFormats = false;
        if (action.payload) {
          state.allFormats = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(fetchFormats.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingFormats = false;
      })
      .addCase(searchFormatsByName.pending, (state) => {
        state.searchingFormats = true;
      })
      .addCase(searchFormatsByName.fulfilled, (state, action) => {
        state.searchingFormats = false;
        if (action.payload) {
          state.allFormats = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(searchFormatsByName.rejected, (state) => {
        state.searchingFormats = false;
      })
      .addCase(paginateFormats.pending, (state) => {
        state.fetchingFormats = true;
      })
      .addCase(paginateFormats.fulfilled, (state, action) => {
        state.fetchingFormats = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.allFormats = [...action.payload.data];
          state.count = action.payload.pagination.count;
          state.current = action.payload.current;
        }
      })
      .addCase(paginateFormats.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingFormats = false;
      })
      .addCase(fetchMarkets.pending, (state) => {
        state.fetchingMarkets = true;
      })
      .addCase(fetchMarkets.fulfilled, (state, action) => {
        state.fetchingMarkets = false;
        if (action.payload) {
          state.allMarkets = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(fetchMarkets.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingMarkets = false;
      })
      .addCase(searchMarketsByName.pending, (state) => {
        state.searchingMarkets = true;
      })
      .addCase(searchMarketsByName.fulfilled, (state, action) => {
        state.searchingMarkets = false;
        if (action.payload) {
          state.allMarkets = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(searchMarketsByName.rejected, (state) => {
        state.searchingMarkets = false;
      })
      .addCase(paginateMarkets.pending, (state) => {
        state.fetchingMarkets = true;
      })
      .addCase(paginateMarkets.fulfilled, (state, action) => {
        state.fetchingMarkets = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.allMarkets = [...action.payload.data];
          state.count = action.payload.pagination.count;
          state.current = action.payload.current;
        }
      })
      .addCase(paginateMarkets.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingMarkets = false;
      })
      .addCase(fetchCountUnits.pending, (state) => {
        state.fetchingCountUnits = true;
      })
      .addCase(fetchCountUnits.fulfilled, (state, action) => {
        state.fetchingCountUnits = false;
        if (action.payload) {
          state.allCountUnits = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(fetchCountUnits.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCountUnits = false;
      })
      .addCase(searchCountUnitsByName.pending, (state) => {
        state.searchingCountUnits = true;
      })
      .addCase(searchCountUnitsByName.fulfilled, (state, action) => {
        state.searchingCountUnits = false;
        if (action.payload) {
          state.allCountUnits = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(searchCountUnitsByName.rejected, (state) => {
        state.searchingCountUnits = false;
      })
      .addCase(paginateCountUnits.pending, (state) => {
        state.fetchingCountUnits = true;
      })
      .addCase(paginateCountUnits.fulfilled, (state, action) => {
        state.fetchingCountUnits = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.allCountUnits = [...action.payload.data];
          state.count = action.payload.pagination.count;
          state.current = action.payload.current;
        }
      })
      .addCase(paginateCountUnits.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCountUnits = false;
      })
      .addCase(fetchSizeUnits.pending, (state) => {
        state.fetchingSizeUnits = true;
      })
      .addCase(fetchSizeUnits.fulfilled, (state, action) => {
        state.fetchingSizeUnits = false;
        if (action.payload) {
          state.allSizeUnits = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(fetchSizeUnits.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingSizeUnits = false;
      })
      .addCase(searchSizeUnitsByName.pending, (state) => {
        state.searchingSizeUnits = true;
      })
      .addCase(searchSizeUnitsByName.fulfilled, (state, action) => {
        state.searchingSizeUnits = false;
        if (action.payload) {
          state.allSizeUnits = action.payload.data || [];
          state.pagination = action.payload.pagination;
          state.count = action.payload.pagination.count;
          state.current = 1;
        }
      })
      .addCase(searchSizeUnitsByName.rejected, (state) => {
        state.searchingSizeUnits = false;
      })
      .addCase(paginateSizeUnits.pending, (state) => {
        state.fetchingSizeUnits = true;
      })
      .addCase(paginateSizeUnits.fulfilled, (state, action) => {
        state.fetchingSizeUnits = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.allSizeUnits = [...action.payload.data];
          state.count = action.payload.pagination.count;
          state.current = action.payload.current;
        }
      })
      .addCase(paginateSizeUnits.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingSizeUnits = false;
      });
  },
});

export default metaDataSlice.reducer;
