import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  LoadingOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  BackTop,
  Badge,
  Button,
  Input,
  Modal,
  Space,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import { History } from 'history';
import { Link, withRouter } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';
import {
  fetchOvrCells,
  searchOvrCellsByName,
  setCellSearchQuery,
} from '../../../store/features/cells/cellsSlice';
import { cancelAllUploads } from '../../../store/features/media/mediaSlice';
import {
  fetchFailedOvrFiles,
  fetchProcessingOvrFiles,
  searchOvrFilesByName,
  setOvrFileSearchQuery,
} from '../../../store/features/ovrFiles/ovrFilesSlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { UserPermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import ViewWrapper from '../../elements/ViewWrapper';
import AutomationQueueFileTable from './components/AutomationQueueFileTable';

const { Title } = Typography;
const { TabPane } = Tabs;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const OVRFileManagement = ({ history }: { history: History }) => {
  const [activeTab, setActiveTab] = useState('Completed');
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuth();

  const {
    totalCount: ovrCellsTotalCount,
    searchingOvrCells,
    cellSearchQuery,
    fetchingOvrCells,
  } = useAppSelector((state) => state.cells);

  const {
    processingOvrFilesTotalCount,
    failedOvrFilesTotalCount,
    searchingOvrFiles,
    ovrFileSearchQuery,
    fetchingProcessingOvrFiles,
    fetchingFailedOvrFiles,
  } = useAppSelector((state) => state.ovrFiles);

  const searchQuery =
    activeTab === 'Completed' ? cellSearchQuery : ovrFileSearchQuery;

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (debouncedSearchQuery) {
      if (activeTab === 'Completed') {
        dispatch(
          searchOvrCellsByName({
            query: debouncedSearchQuery,
            options: { status: 'completed' },
          })
        );
      } else {
        dispatch(searchOvrFilesByName(debouncedSearchQuery));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, dispatch]);

  useEffect(() => {
    dispatch(setOvrFileSearchQuery(''));
    dispatch(setCellSearchQuery(''));
  }, [activeTab, dispatch]);

  useEffect(() => {
    dispatch(fetchOvrCells({ params: { status: 'completed' } }));
    dispatch(fetchFailedOvrFiles());
    dispatch(fetchProcessingOvrFiles());
  }, [dispatch, activeTab]);

  const handleClear = useCallback(async () => {
    if (activeTab === 'Completed') {
      dispatch(setCellSearchQuery(''));
      await dispatch(fetchOvrCells({ params: { status: 'completed' } }));
    } else {
      dispatch(setOvrFileSearchQuery(''));
      await dispatch(fetchProcessingOvrFiles());
    }
  }, [activeTab, dispatch]);

  const updateSearchQuery = useCallback(
    (query: string) => {
      if (query === '') {
        handleClear();
      }
      if (activeTab === 'Completed') {
        dispatch(setCellSearchQuery(query));
      } else {
        dispatch(setOvrFileSearchQuery(query));
      }
    },
    [activeTab, dispatch, handleClear]
  );

  const headerSectionRight = useMemo(
    () => (
      <Space direction="horizontal" size="middle">
        <Input
          allowClear={!searchingOvrFiles || !searchingOvrCells}
          value={
            activeTab === 'Completed' ? cellSearchQuery : ovrFileSearchQuery
          }
          onChange={(e) => updateSearchQuery(e.target.value)}
          placeholder="Search for a file..."
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          suffix={
            ((ovrFileSearchQuery !== '' && searchingOvrFiles) ||
              (cellSearchQuery !== '' && searchingOvrCells)) && (
              <Spin indicator={antSpinIcon} />
            )
          }
          style={{ minWidth: 220 }}
        />
        {hasPermission(UserPermissions.OvrFileManagementUpload) ? (
          <>
            <Link to={DrawerHashRoute.CustomFileForm}>
              <Button type="primary">
                Upload Custom File
                <UploadOutlined />
              </Button>
            </Link>
            <Link to={DrawerHashRoute.RSGFileForm}>
              <Button type="primary">
                Upload .rsg or .rssegment
                <UploadOutlined />
              </Button>
            </Link>
          </>
        ) : (
          <Link to={DrawerHashRoute.RSGFileForm}>
            <Button type="primary">
              Upload .rsg or .rssegment
              <UploadOutlined />
            </Button>
          </Link>
        )}
      </Space>
    ),
    [
      searchingOvrFiles,
      searchingOvrCells,
      activeTab,
      cellSearchQuery,
      ovrFileSearchQuery,
      hasPermission,
      updateSearchQuery,
    ]
  );

  const uploadingFiles = useAppSelector((state) => state.media.uploadingFiles);
  const isAnyFileUploading = Object.values(uploadingFiles).some(Boolean);

  useEffect(() => {
    const unblock = history.block((location) => {
      const currentHash = history.location.hash;
      const nextHash = location.hash;

      if (
        isAnyFileUploading &&
        !(
          currentHash === '#custom-file-form' ||
          nextHash === '#custom-file-form'
        )
      ) {
        Modal.confirm({
          title: 'Upload in Progress',
          content: 'An upload is in progress. Are you sure you want to leave?',
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            dispatch(cancelAllUploads());
            unblock();
            history.push(location.pathname + location.hash);
          },
        });
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [history, isAnyFileUploading, dispatch]);

  return (
    <ViewWrapper
      headerTitle={
        <Space direction="horizontal">
          <Title level={3}>Cells</Title>
        </Space>
      }
      headerSectionRight={headerSectionRight}
    >
      <Tabs
        defaultActiveKey="Completed"
        activeKey={activeTab}
        destroyInactiveTabPane
        onChange={(key) => setActiveTab(key)}
      >
        <TabPane
          tab={
            <Space>
              Completed
              <Badge
                overflowCount={99999}
                count={ovrCellsTotalCount}
                showZero
                style={{ backgroundColor: '#8E25AA' }}
              />
            </Space>
          }
          key="Completed"
        >
          <AutomationQueueFileTable
            loading={fetchingOvrCells}
            history={history}
            tab={activeTab}
          />
        </TabPane>
        <TabPane
          tab={
            <Space>
              Processing Queue
              <Badge
                overflowCount={99999}
                count={processingOvrFilesTotalCount}
                showZero
                style={{ backgroundColor: '#8E25AA' }}
              />
            </Space>
          }
          key="Processing"
        >
          <AutomationQueueFileTable
            history={history}
            tab={activeTab}
            loading={fetchingProcessingOvrFiles}
          />
        </TabPane>
        <TabPane
          tab={
            <Space>
              Failed
              <Badge
                overflowCount={99999}
                count={failedOvrFilesTotalCount}
                showZero
                style={{ backgroundColor: '#8E25AA' }}
              />
            </Space>
          }
          key="Failed"
        >
          <AutomationQueueFileTable
            history={history}
            tab={activeTab}
            loading={fetchingFailedOvrFiles}
          />
        </TabPane>
      </Tabs>

      <BackTop />
    </ViewWrapper>
  );
};

export default withRouter(OVRFileManagement);
