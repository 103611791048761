import { FC, memo, useCallback, useEffect, useMemo } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { useAuth } from '../../../../hooks/useAuth';
import {
  initialNewManufacturerModel,
  ManufacturerModel,
} from '../../../../models/manufacturer';
import { ParentCompanyModel } from '../../../../models/parent-company';
import { ProductStatusEnum } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createManufacturer,
  deleteManufacturer,
  updateManufacturer,
} from '../../../../store/features/manufacturers/manufacturerSlice';
import { searchParentCompaniesByName } from '../../../../store/features/parentCompany/parentCompanySlice';
import { propsAreEqual, UserPermissions } from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';
import { ManufacturerFormLocationState } from './types';

import './ManufacturerForm.less';

const { Option } = Select;

interface ManufacturerFormProps {
  history: History;
  location: Location<ManufacturerFormLocationState>;
}
interface ManufacturerProps {
  name: string;
  status: string;
  short_name?: string;
  client_type: string;
  uuid: string;
  in_library: number;
  in_processing: number;
  parent_company?: string | false;
  parent_delete?: string | null;
}

const ManufacturerForm: FC<ManufacturerFormProps> = (props) => {
  const { history, location } = props;

  const dispatch = useAppDispatch();
  const { deletingManufacturer, savingManufacturer } = useAppSelector(
    (state) => state.manufacturer
  );
  const { value: parentCompanies } = useAppSelector(
    (state) => state.parentCompany
  );
  const { hasPermission } = useAuth();

  const [form] = Form.useForm();
  const isNewManufacturer = useMemo(() => !location.state, [location.state]);
  const { brands, in_library, in_processing, in_use } = {
    ...location?.state?.data,
  };
  const { data: manufacturer } = { ...location?.state };

  const fields = useMemo(
    () =>
      Object.keys(
        isNewManufacturer ? initialNewManufacturerModel : location.state?.data
      ),
    [isNewManufacturer, location.state?.data]
  );

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const handleDeleteManufacturer = useCallback(
    async (manufacturer: ManufacturerModel) => {
      message.loading(
        {
          content: 'Deleting manufacturer...',
          key: 'deleting-manufacturer-message',
        },
        0
      );

      const res: any = await dispatch(deleteManufacturer(manufacturer.uuid));
      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-manufacturer-message');
        message.success(`Manufacturer '${manufacturer.name}' deleted.`);
        history.goBack();
      }
    },
    [dispatch, onError, history]
  );

  const saveManufacturer = useCallback(
    async (manufacturer: ManufacturerModel) => {
      console.log(manufacturer);
      const { name, parent_company, statusCheckValue } = manufacturer;
      let status: string;
      let newManufacturer: ManufacturerProps = {
        name: '',
        status: '',
        short_name: '',
        client_type: '',
        uuid: '',
        in_library: 0,
        in_processing: 0,
        parent_company: false,
      };

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newManufacturer = {
        name,
        status,
        short_name: name,
        client_type: 'manufacturer',
        uuid: location.state?.data.uuid,
        in_library: location.state?.data.in_library,
        in_processing: location.state?.data.in_processing,
        parent_company: parent_company ?? false,
      };

      const res: any = isNewManufacturer
        ? await dispatch(createManufacturer({ manufacturer: newManufacturer }))
        : await dispatch(
            updateManufacturer({
              manufacturerId: location.state?.data.uuid,
              manufacturer: newManufacturer,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Manufacturer saved.');
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewManufacturer,
      location.state?.data.in_library,
      location.state?.data.in_processing,
      location.state?.data.uuid,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: ManufacturerModel) => {
      form.validateFields(fields).then(() => saveManufacturer(values));
    },
    [fields, form, saveManufacturer]
  );

  const getInitialValues = () => {
    if (isNewManufacturer) {
      return { ...initialNewManufacturerModel };
    }
    const { status } = location.state.data;
    return {
      ...location.state.data,
      parent_company: location.state.data.parent?.name,
      statusCheckValue: status === ProductStatusEnum.approved,
    };
  };

  useEffect(() => {
    dispatch(
      searchParentCompaniesByName({
        query: '',
      })
    );
  }, [dispatch]);

  const handleSearch = (query: string) => {
    dispatch(
      searchParentCompaniesByName({
        query,
      })
    );
  };

  const handleConfirmDeleteManufacturer = useCallback(
    (e: MouseEvent, manufacturer: ManufacturerModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the manufacturer "${manufacturer.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the Manufacturer "${manufacturer.name}" after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteManufacturer(manufacturer),
      });
    },
    [handleDeleteManufacturer]
  );

  return (
    <FormWrapper
      title={`${isNewManufacturer ? 'Create new' : 'Edit'} manufacturer`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Manufacturer"
          name="name"
          rules={[
            { required: true, message: 'Manufacturer name is required.' },
          ]}
        >
          <Input
            data-cy="manufacturer-form-input"
            placeholder="Manufacturer name"
          />
        </Form.Item>
        <Form.Item required={false}>
          <Form.Item label="Select Parent Company" name="parent_company">
            <Select
              placeholder="Select a parent company"
              allowClear
              showSearch
              onSearch={handleSearch}
              filterOption={false}
            >
              {parentCompanies?.map((parentCompany: ParentCompanyModel) => (
                <Option value={parentCompany.uuid}>{parentCompany.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Status">
          <div className="parent-company-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue" valuePropName="checked">
              <Switch
                disabled={!hasPermission(UserPermissions.MetaDataApprove)}
              />
            </Form.Item>
          </div>
        </Form.Item>

        {!isNewManufacturer ? (
          <>
            <Form.Item label="Associated Brands">
              {brands
                ? brands.map((brand) => (
                    <Button className="link-button" type="link">
                      {brand.name}
                    </Button>
                  ))
                : 'There are currently no assoicated brands'}
            </Form.Item>
            <Form.Item label="Details">
              <div className="manufacturer-form-details">
                <Typography>Number of models in library</Typography>
                <Typography>{in_library}</Typography>
              </div>
              <div className="manufacturer-form-details">
                <Typography>
                  Number of models in processing / master librarian list
                </Typography>
                <Typography>{in_processing}</Typography>
              </div>
              <Button
                className="link-button"
                type="link"
                onClick={() =>
                  history.push({
                    state: { manufacturer },
                    pathname: RoutePath.ProductLibrary,
                  })
                }
              >
                View in Library
              </Button>
            </Form.Item>
          </>
        ) : null}
        <Form.Item>
          {isNewManufacturer ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingManufacturer}
                data-cy="manufacturer-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={!hasPermission(UserPermissions.MetaDataCreate)}
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!in_use ? (
                <Button
                  danger
                  loading={deletingManufacturer}
                  type="primary"
                  onClick={(e: any) =>
                    handleConfirmDeleteManufacturer(e, manufacturer)
                  }
                  disabled={!hasPermission(UserPermissions.MetaDataDelete)}
                >
                  Delete
                </Button>
              ) : null}
              <Button
                type="primary"
                loading={savingManufacturer}
                htmlType="submit"
                disabled={!hasPermission(UserPermissions.MetaDataEdit)}
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(ManufacturerForm, propsAreEqual));
