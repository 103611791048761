import { useCallback, useEffect, useMemo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import './SelectClientDropdown.less';

import { useAuth } from '../../../../../hooks/useAuth';
import { ClientModel, TagModel } from '../../../../../models/client';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { fetchDropdownClients } from '../../../../../store/features/clientDropdown/clientDropdownSlice';
import {
  getCurrentUser,
  updateUserClient,
} from '../../../../../store/features/users/usersSlice';
import { UserPermissions } from '../../../../../util';

interface SelectClientDropdownProps {
  collapsed: boolean;
}

const SelectClientDropdown = ({ collapsed }: SelectClientDropdownProps) => {
  const dispatch = useAppDispatch();
  const { clients, loading, pagination } = useAppSelector(
    (state) => state.clientDropdown
  );
  const { currentUser } = useAppSelector((state) => state.users);
  const { hasPermission } = useAuth();
  const { fetchingUser } = useAppSelector((state) => state.users);

  const clientDropdownClass = cx('select-client-dropdown', {
    collapsed: collapsed,
  });

  const clientDropdownOverlayClass = cx('client-dropdown-menu-overlay', {
    collapsed: collapsed,
  });

  const getClientName = useCallback((): string => {
    if (!clients.length || !currentUser) {
      return 'No Client';
    }

    const client = clients.find(
      (client) => client.uuid === currentUser.client_id
    );
    const clientName = client ? client.name : 'No Client';

    return collapsed && clientName ? clientName[0] : clientName;
  }, [clients, currentUser, collapsed]);

  const handleUpdateClient = useCallback(
    (clientId: string) => {
      if (!currentUser?.uuid) return;

      dispatch(
        updateUserClient({
          userId: currentUser.uuid,
          clientId,
        })
      ).then(() => {
        window.location.reload();
      });
    },
    [dispatch, currentUser?.uuid]
  );

  const filteredClients = useMemo(() => {
    return clients
      .filter((item: ClientModel) => {
        return (
          item.tags &&
          item.tags.some(
            (tag: TagModel) => tag.name === 'Client' && tag.set === 1
          )
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [clients]);

  const handleScroll = useCallback(
    (e: any) => {
      const target = e.target as HTMLDivElement;
      if (
        !loading &&
        target.scrollTop + target.offsetHeight >= target.scrollHeight - 1 &&
        pagination
      ) {
        dispatch(
          fetchDropdownClients({ offset: pagination.offset + pagination.limit })
        );
      }
    },
    [pagination, dispatch, loading]
  );

  useEffect(() => {
    const canSwitchClients = hasPermission(UserPermissions.UserSwitchClients);

    if (canSwitchClients) {
      dispatch(fetchDropdownClients({}));
      dispatch(getCurrentUser());
    }
  }, [dispatch, hasPermission]);

  if (!hasPermission(UserPermissions.UserSwitchClients)) {
    return null;
  }

  if (loading || fetchingUser) {
    return (
      <div className={clientDropdownClass}>
        <LoadingOutlined style={{ color: 'white' }} />
      </div>
    );
  }

  const menu = (
    <Menu
      className="select-client-menu"
      inlineCollapsed={false}
      style={{ maxHeight: '300px', overflowY: 'auto' }}
      onScroll={handleScroll}
    >
      {filteredClients.map((client: ClientModel) => (
        <div
          key={client.uuid}
          className="select-client-menu-item"
          onClick={() => handleUpdateClient(client.uuid)}
        >
          {client.name}
        </div>
      ))}
      {loading && (
        <div className="select-client-menu-item">
          <LoadingOutlined style={{ color: 'white' }} />
        </div>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlayClassName={clientDropdownOverlayClass}
      placement="bottom"
      overlay={menu}
      trigger={['click']}
      overlayStyle={{ position: 'fixed' }}
    >
      <div className={clientDropdownClass}>
        <div className="client-name-text">{getClientName()}</div>
      </div>
    </Dropdown>
  );
};

export default SelectClientDropdown;
