import { memo, useCallback, useMemo } from 'react';

import { Badge, ConfigProvider, List, Spin, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import { ListProductLibraryParams } from '../../../../../../../hooks/product-library';
import { ListProductLibraryModel } from '../../../../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../../../../store';
import { loadMoreProductLibraryItems } from '../../../../../../../store/features/productLibrary/productLibrarySlice';
import { propsAreEqual } from '../../../../../../../util';
import { customizeRenderEmpty } from '../../../../../../elements/CustomizeEmptyRender/customizeRenderEmpty';
import ProductLibraryItem from '../../../ProductLibraryItem';

import './ProductLibraryGridList.less';

interface ProductLibraryGridListProps {
  loading: boolean;
  onLoadMore?: () => void;
  hasMore: boolean;
  productLibrary: ListProductLibraryModel[];
  buildFilterParams: () => ListProductLibraryParams;
}

const { Title } = Typography;

const ProductLibraryGridList = ({
  loading,
  hasMore,
  productLibrary,
  buildFilterParams,
}: ProductLibraryGridListProps) => {
  const dispatch = useAppDispatch();
  const { searchQuery, pagination, totalCount, fetchingProductLibraryItems } =
    useAppSelector((state) => state.productLibrary);

  const showLoadMoreSpinner = loading || fetchingProductLibraryItems;

  const headerTitle = useMemo(
    (): JSX.Element => (
      <div className="product-library-table-header">
        <Title level={3}>Recently Added</Title>
        <Badge
          size="default"
          count={totalCount}
          className="total-count-badge"
        />
      </div>
    ),
    [totalCount]
  );

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading) return;

    const currentOffset = pagination?.offset || 0;
    const currentLimit = pagination?.limit || 20;

    const params: ListProductLibraryParams = {
      ...buildFilterParams(),
      _offset: currentOffset + currentLimit,
      _limit: currentLimit,
    };

    dispatch(loadMoreProductLibraryItems({ params, query: searchQuery }));
  }, [dispatch, hasMore, loading, pagination, buildFilterParams, searchQuery]);

  return (
    <div className="product-library-grid-list-wrapper">
      {headerTitle}
      <InfiniteScroll
        loadMore={handleLoadMore}
        hasMore={hasMore}
        threshold={100}
        initialLoad={false}
      >
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <List
            className="product-library-grid-list"
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 3,
              xxl: 4,
            }}
            itemLayout="vertical"
            dataSource={productLibrary}
            rowKey={(productLibrary) => `${productLibrary.uuid}-row-key`}
            renderItem={(product, index) => (
              <List.Item key={`product-library-item-${index}`}>
                <ProductLibraryItem product={product} />
              </List.Item>
            )}
          />
        </ConfigProvider>
      </InfiniteScroll>
      {showLoadMoreSpinner && (
        <div className="loading-more-spin-wrapper">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default memo(ProductLibraryGridList, propsAreEqual);
