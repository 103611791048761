import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { CheckOutlined, LineOutlined } from '@ant-design/icons';
import { Form, Input, Select, Space, message } from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import AuthService from '../../../../api/auth';
import { ApiError, handleError } from '../../../../api/base';
import { useAuth } from '../../../../hooks/useAuth';
import { ClientModel } from '../../../../models/client';
import { RoleModel } from '../../../../models/role';
import { UserModel, initialNewUserModel } from '../../../../models/user';
import { fetchRoles } from '../../../../store/features/acl/roles/rolesSlice';
import { fetchClients } from '../../../../store/features/clients/clientsSlice';
import {
  createUser,
  disableMfa,
  getCurrentUser,
  getUser,
  updateUser,
} from '../../../../store/features/users/usersSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/index';
import { UserPermissions, propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import NoPermission from '../../../elements/NoPermission';
import RoleTableList from '../../../views/RolesView/components/RoleTableList/RoleTableList';
import { UserFormLocationState } from './types';

interface UserFormProps {
  history: History;
  location: Location<UserFormLocationState>;
}

interface PasswordRequirement {
  text: string;
  fulfilled: boolean;
}

const { Option } = Select;

const UserForm = ({ history, location }: UserFormProps) => {
  const [sortedClients, setSortedClients] = useState<ClientModel[]>([]);

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const { savingUser } = useAppSelector((state) => state.users);
  const { clients, fetchingClients } = useAppSelector((state) => state.clients);
  const { value: roles, fetchingRoles } = useAppSelector(
    (state) => state.roles
  );

  const isNewUser = useMemo(() => !location.state, [location.state]);

  const fields = useMemo(
    () => Object.keys(isNewUser ? initialNewUserModel : location.state?.data),
    [isNewUser, location.state?.data]
  );

  const getInitialUserClientValue = () => {
    return isNewUser ? '' : location.state?.data?.client_id;
  };

  const [selectedRoles, setSelectedRoles] = useState<RoleModel[]>(
    location.state?.data?.roles || []
  );

  const [userClient, setUserClient] = useState<string>(
    getInitialUserClientValue()
  );

  const { hasPermission } = useAuth();

  const isUserDetailsView = useMemo((): boolean => {
    return location.pathname.includes(`/users/${location?.state?.data?.uuid}`);
  }, [location.pathname, location?.state?.data?.uuid]);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const onSuccess = useCallback(async () => {
    message.success('User saved.');

    await dispatch(getCurrentUser());

    if (isUserDetailsView) {
      await dispatch(getUser(location?.state?.data?.uuid));
      history.goBack();
    } else {
      history.goBack();
    }

    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [dispatch, history, isUserDetailsView, location?.state?.data?.uuid]);

  const saveUser = useCallback(
    async (user: UserModel) => {
      try {
        let userResponse;
        if (isNewUser) {
          userResponse = await dispatch(
            createUser({
              user,
              userRoles: selectedRoles.map(({ name }) => name),
            })
          ).unwrap();
        } else {
          const userId = location.state?.data?.uuid;
          if (!userId) {
            throw new Error('User ID is missing for updating the user');
          }

          userResponse = await dispatch(
            updateUser({
              userId: location.state?.data?.uuid!,
              user,
              userRoles: selectedRoles.map(({ name }) => name),
            })
          ).unwrap();
        }
        if (userResponse) {
          onSuccess();
        }
      } catch (error) {
        onError(error as ApiError);
        console.error('Error:', error);
      }
    },
    [
      dispatch,
      isNewUser,
      location.state?.data?.uuid,
      onError,
      onSuccess,
      selectedRoles,
    ]
  );

  const [passwordRequirements, setPasswordRequirements] = useState<
    PasswordRequirement[]
  >([
    { text: 'Minimum 10 characters', fulfilled: false },
    { text: 'Special characters (!, @, #, $, %, ^, &, *)', fulfilled: false },
    { text: 'Numbers (0-9)', fulfilled: false },
    { text: 'Uppercase letters (A-Z)', fulfilled: false },
    { text: 'Lowercase letters (a-z)', fulfilled: false },
  ]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const fulfilledRequirements = passwordRequirements.map((req) =>
      req.text === 'Minimum 10 characters'
        ? { ...req, fulfilled: value.length >= 10 }
        : req.text === 'Special characters (!, @, #, $, %, ^, &, *)'
        ? { ...req, fulfilled: /[!@#$%^&*]/.test(value) }
        : req.text === 'Numbers (0-9)'
        ? { ...req, fulfilled: /\d/.test(value) }
        : req.text === 'Uppercase letters (A-Z)'
        ? { ...req, fulfilled: /[A-Z]/.test(value) }
        : req.text === 'Lowercase letters (a-z)'
        ? { ...req, fulfilled: /[a-z]/.test(value) }
        : req
    );
    setPasswordRequirements(fulfilledRequirements);
  };

  const onSubmit = useCallback(
    (values: UserModel) => {
      const currentUser = AuthService().getCurrentUser();

      const targetUserId = location?.state?.data?.uuid;

      if (values.password) {
        const canChangePassword =
          (targetUserId && targetUserId === currentUser.uuid) ||
          hasPermission(UserPermissions.UsersEdit);

        if (!canChangePassword) {
          message.error('You do not have permission to change this password.');
          return;
        }

        if (values.password.length < 10) {
          message.error('Password must be at least 10 characters long.');
          return;
        }

        const characterTypeRequirements = passwordRequirements.slice(1);
        const numFulfilledCharacterTypes = characterTypeRequirements.filter(
          (req) => req.fulfilled
        ).length;

        if (numFulfilledCharacterTypes < 3) {
          message.error(
            'Password must contain at least 3 of the following: uppercase letters, lowercase letters, numbers, or special characters.'
          );
          return;
        }
      }

      form.validateFields(fields).then(() => saveUser(values));
    },
    [
      fields,
      form,
      saveUser,
      passwordRequirements,
      location?.state?.data?.uuid,
      hasPermission,
    ]
  );

  const getInitialValues = () => {
    if (isNewUser) {
      return { ...initialNewUserModel };
    }
    return { ...location?.state?.data };
  };

  useEffect(() => {
    if (hasPermission(UserPermissions.UsersView)) {
      dispatch(fetchClients({ query: '' }));
    }
  }, [dispatch, hasPermission]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  useEffect(() => {
    const filteredClients = clients.filter(
      (item) =>
        item.tags &&
        item.tags.some((tag) => tag.name === 'Client' && tag.set === 1)
    );

    const sorted = [...filteredClients].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    setSortedClients(sorted);
  }, [clients]);

  const [mfaPassword, setMfaPassword] = useState<string>('');
  const [showMfaPassword, setShowMfaPassword] = useState<boolean>(false);

  const handleMfaToggle = useCallback(
    async (checked: boolean) => {
      if (!checked && location.state?.data?.email) {
        try {
          await dispatch(
            disableMfa({
              apiUser: location.state.data.email,
              apiPass: mfaPassword,
            })
          ).unwrap();

          if (location.state?.data?.uuid) {
            const updatedUser = await dispatch(
              getUser(location.state.data.uuid)
            ).unwrap();
            history.replace({
              ...location,
              state: { data: updatedUser },
            });
          }

          message.success('MFA disabled successfully');
          setShowMfaPassword(false);
          setMfaPassword('');
        } catch (error) {
          console.error('Error disabling MFA:', error);
        }
      }
    },
    [location, dispatch, mfaPassword, history]
  );

  if (
    !hasPermission(
      isNewUser ? UserPermissions.UsersCreate : UserPermissions.UsersEdit
    )
  ) {
    return <NoPermission />;
  }

  return (
    <FormWrapper
      title={`${isNewUser ? 'Create new' : 'Edit'} user`}
      onClose={() => history.goBack()}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="User Name"
          name="name"
          rules={[{ required: true, message: 'User name is required.' }]}
        >
          <Input
            data-cy="user-form-input"
            placeholder="User name"
            disabled={!isNewUser}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Please input a valid email address.',
            },
            { required: true, message: 'Email is required.' },
          ]}
        >
          <Input data-cy="user-form-input" placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={
            isNewUser
              ? [
                  {
                    required: true,
                    message: 'Password is required.',
                  },
                ]
              : []
          }
        >
          <Input.Password
            data-cy="user-form-input"
            type="password"
            placeholder="Password"
            onChange={handlePasswordChange}
          />
        </Form.Item>
        <div>
          <ul className="password-requirements-list">
            {passwordRequirements.map((req, index) => (
              <li
                key={index}
                style={{ color: req.fulfilled ? '#40C976' : 'inherit' }}
              >
                {req.fulfilled ? (
                  <CheckOutlined style={{ color: '#40C976', marginRight: 8 }} />
                ) : (
                  <LineOutlined style={{ marginRight: 8 }} />
                )}
                {req.text}
              </li>
            ))}
          </ul>
        </div>
        <Form.Item
          label="Client"
          name="client_id"
          rules={
            isNewUser
              ? [
                  {
                    required: true,
                    message: 'Client is required.',
                  },
                ]
              : []
          }
        >
          <Select
            loading={fetchingClients}
            placeholder="Select a Client"
            value={userClient}
            defaultValue={getInitialUserClientValue()}
            onChange={(value) => setUserClient(value)}
          >
            {sortedClients.map((client) => {
              return <Option value={client.uuid}>{client.name}</Option>;
            })}
          </Select>
        </Form.Item>

        <RoleTableList
          roles={roles}
          selectedRoles={selectedRoles}
          loading={fetchingRoles}
          view="Users"
          onRolesSelected={setSelectedRoles}
        />

        {!isNewUser && (
          <>
            <div style={{ margin: '2rem 0' }}>
              <p style={{ marginBottom: 0 }}>MFA Status:</p>
              <p
                style={{
                  fontWeight: 'bold',
                  margin: 0,
                  color:
                    location.state?.data?.mfa_status === 'enabled'
                      ? '#52c41a'
                      : '#ff4d4f',
                }}
              >
                {location.state?.data?.mfa_status.toUpperCase()}
              </p>
            </div>

            {location.state?.data?.mfa_status === 'enabled' && (
              <>
                {!showMfaPassword ? (
                  <Form.Item>
                    <Button onClick={() => setShowMfaPassword(true)}>
                      Disable MFA
                    </Button>
                  </Form.Item>
                ) : (
                  <Form.Item label="Password to Disable MFA" required>
                    <Space>
                      <Input.Password
                        value={mfaPassword}
                        onChange={(e) => setMfaPassword(e.target.value)}
                        placeholder="Enter password to disable MFA"
                      />
                      <Button
                        onClick={() => handleMfaToggle(false)}
                        disabled={!mfaPassword}
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={() => {
                          setShowMfaPassword(false);
                          setMfaPassword('');
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form.Item>
                )}
              </>
            )}
          </>
        )}

        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              loading={savingUser as boolean}
              data-cy="user-form-submit-btn"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              {`${isNewUser ? 'Create' : 'Save'} User`}
            </Button>
            <Button onClick={() => history.goBack()} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(UserForm, propsAreEqual));
