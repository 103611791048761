import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { permissionsSlice } from './features/acl/permissions/permissionsSlice';
import { rolesSlice } from './features/acl/roles/rolesSlice';
import { apiLogsSlice } from './features/api-log/apiLogsSlice';
import { brandSlice } from './features/brand/brandSlice';
import { categorySlice } from './features/category/categorySlice';
import { cellsSlice } from './features/cells/cellsSlice';
import { clientDropdownSlice } from './features/clientDropdown/clientDropdownSlice';
import { clientsSlice } from './features/clients/clientsSlice';
import { customFieldsSlice } from './features/customFields/customFieldsSlice';
import { manufacturerSlice } from './features/manufacturers/manufacturerSlice';
import { masterLibrarianListSlice } from './features/masterLibrarianList/masterLibrarianListSlice';
import { mediaSlice } from './features/media/mediaSlice';
import { metaDataSlice } from './features/metaData/metaDataSlice';
import { modelDownloadSlice } from './features/modelDownload/modelDownloadSlice';
import { modelLibrarySearchSlice } from './features/modelLibrarySearch/modelLibrarySearch';
import { ovrFilesSlice } from './features/ovrFiles/ovrFilesSlice';
import { ovrProjectDetailsSlice } from './features/ovrProjectDetails/ovrProjectDetailsSlice';
import { ovrProjectsSlice } from './features/ovrProjects/ovrProjectsSlice';
import { parentCompanySlice } from './features/parentCompany/parentCompanySlice';
import { productItemSlice } from './features/productItem/productItemSlice';
import { productLibrarySlice } from './features/productLibrary/productLibrarySlice';
import { productListSlice } from './features/productList/productListSlice';
import { statusSlice } from './features/status/statusSlice';
import { storeBuildsSlice } from './features/storeBuilds/storeBuildsSlice';
import { systemConfigurationsSlice } from './features/systemConfigurations/systemConfigurationsSlice';
import { uploadModelSlice } from './features/uploadModel/uploadModel';
import { usersSlice } from './features/users/usersSlice';
import { wbmlSlice } from './features/wbml/wbmlSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['users'], // only persist users slice which contains roles/permissions
};

const rootReducer = combineReducers({
  ovrFiles: ovrFilesSlice.reducer,
  cells: cellsSlice.reducer,
  modelDownload: modelDownloadSlice.reducer,
  modelLibrarySearch: modelLibrarySearchSlice.reducer,
  ovrProjectDetails: ovrProjectDetailsSlice.reducer,
  ovrProjects: ovrProjectsSlice.reducer,
  customFields: customFieldsSlice.reducer,
  users: usersSlice.reducer,
  permissions: permissionsSlice.reducer,
  clients: clientsSlice.reducer,
  systemConfigurations: systemConfigurationsSlice.reducer,
  productLibrary: productLibrarySlice.reducer,
  productList: productListSlice.reducer,
  masterLibrarianList: masterLibrarianListSlice.reducer,
  productItem: productItemSlice.reducer,
  roles: rolesSlice.reducer,
  status: statusSlice.reducer,
  storeBuilds: storeBuildsSlice.reducer,
  metaData: metaDataSlice.reducer,
  category: categorySlice.reducer,
  brand: brandSlice.reducer,
  parentCompany: parentCompanySlice.reducer,
  manufacturer: manufacturerSlice.reducer,
  media: mediaSlice.reducer,
  apiLogs: apiLogsSlice.reducer,
  uploadModel: uploadModelSlice.reducer,
  clientDropdown: clientDropdownSlice.reducer,
  wbml: wbmlSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: any = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
