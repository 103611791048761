import { useCallback, useMemo } from 'react';
import AuthService from '../api/auth';
import { useAppSelector } from '../store';
import {
  getUserPermissions,
  getUserRoles,
} from '../store/features/users/usersSlice';
import { UserPermissions } from '../util/user';

export const useAuth = () => {
  const userPermissions = useAppSelector(getUserPermissions);
  const userRoles = useAppSelector(getUserRoles);

  const hasValidToken = useCallback(() => {
    const token = AuthService().getAuthToken();
    return Boolean(token);
  }, []);

  const getStoredPermissions = useCallback(() => {
    if (userPermissions && userPermissions?.length > 0) return userPermissions;

    try {
      const me = localStorage.getItem('me');
      if (!me) return [];

      const userData = JSON.parse(me);
      return userData.permissions_list || userData.permissions || [];
    } catch (error) {
      console.error('Error parsing stored permissions:', error);
      return [];
    }
  }, [userPermissions]);

  const hasPermission = useCallback(
    (permissionName: UserPermissions | UserPermissions[]): boolean => {
      if (!permissionName) return true;
      const availablePermissions =
        userPermissions && userPermissions?.length > 0
          ? userPermissions
          : getStoredPermissions();

      if (Array.isArray(permissionName)) {
        return permissionName.every((permission) =>
          availablePermissions.some(
            (userPerm: UserPermissions) => userPerm === permission
          )
        );
      }

      return availablePermissions.some(
        (permission: UserPermissions) => permission === permissionName
      );
    },
    [userPermissions, getStoredPermissions]
  );

  const isAuthenticated = useMemo(
    () => hasValidToken() && getStoredPermissions().length > 0,
    [hasValidToken, getStoredPermissions]
  );

  return {
    isAuthenticated,
    userPermissions,
    userRoles,
    hasPermission,
  };
};
