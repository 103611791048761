import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, handleError, PaginationModel } from '../../../api/base';
import ProductLibraryService, {
  UpdateProductRequest,
} from '../../../api/product-library';
import { SelectDataItem } from '../../../components/elements/MultiLevelSelect/types';
import {
  FiltersType,
  SelectedFilters,
} from '../../../components/views/ProductLibraryView/components/ProductsFilters/ProductsFilters';
import { ListProductLibraryParams } from '../../../hooks/product-library';
import {
  getAllMarketIds,
  isAllMarketsView,
  ListProductLibraryModel,
} from '../../../models/product-library';
import { convertParamsToQuery } from '../../../util';
import { RootState } from '../../index';

interface ProductLibraryError {
  code: number | null;
  message: string | null;
}

interface BaseProductLibraryParams {
  _limit?: number;
  _offset?: number;
  _order_by?: string;
  status?: string;
  q?: string;
  category?: string;
  [key: string]: any;
}

export interface ProductLibraryState {
  value: ListProductLibraryModel[];
  productUPCs: { [fieldName: string]: ListProductLibraryModel | undefined };
  allProductLibraryItems: ListProductLibraryModel[];
  fetchingProductLibraryItems: boolean;
  searchingProductLibrary: boolean;
  savingProductLibrary: boolean;
  error: ProductLibraryError;
  pagination: PaginationModel | null;
  totalCount: number;
  searchQuery: string;
  selectedCategory: SelectDataItem | 'All' | undefined;
  selectedFilters: SelectedFilters | undefined;
  currentPage: number;
  pageSize: number;
  selectedMarket: number;
  productItemSearchParams: BaseProductLibraryParams;
}

interface UpdateProductProps {
  productId: string;
  product: UpdateProductRequest;
}

const initialState: ProductLibraryState = {
  value: [],
  productUPCs: {},
  allProductLibraryItems: [],
  fetchingProductLibraryItems: false,
  searchingProductLibrary: false,
  savingProductLibrary: false,
  error: { code: null, message: null },
  totalCount: 0,
  pagination: null,
  searchQuery: '',
  selectedCategory: undefined,
  selectedFilters: undefined,
  currentPage: 1,
  pageSize: 10,
  selectedMarket: 66,
  productItemSearchParams: {
    _limit: 30,
    _offset: 0,
    _order_by: 'updated_at:desc',
    status: 'approved',
  },
};

export const fetchProductLibrary = createAsyncThunk(
  'productLibrary/fetchProductLibrary',
  async (
    {
      params,
      searching = false,
      loadMore = false,
    }: {
      params: ListProductLibraryParams;
      searching?: boolean;
      loadMore?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().listProductLibrary(
        queryString
      );
      return {
        data: resp.data,
        pagination: resp.pagination,
        searching,
        loadMore,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchProductsByName = createAsyncThunk(
  'productLibrary/searchProductsByName',
  async (query: string, { getState, rejectWithValue }) => {
    try {
      const {
        productLibrary: { pagination },
      } = getState() as RootState;

      let params: ListProductLibraryParams = {
        _limit: pagination?.limit,
        _offset: 0,
        _order_by: 'updated_at:desc',
        status: 'approved',
      };

      if (query && query.length) {
        params = {
          ...params,
          q: `${query.toLowerCase()}`,
        };
      }

      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchProductsByUPC = createAsyncThunk(
  'productLibrary/searchProductsByUPC',
  async (
    { query, fieldKey }: { query: string; fieldKey: number },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        productLibrary: { pagination },
      } = getState() as RootState;

      let params: ListProductLibraryParams = {
        _limit: pagination?.limit,
        _offset: 0,
        _order_by: 'updated_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          q: `${query.toLowerCase()}`,
        };
      }

      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        fieldKey,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllMarketsCount = createAsyncThunk(
  'productLibrary/fetchAllMarketsCount',
  async (
    {
      params,
      query,
    }: {
      params: ListProductLibraryParams;
      query?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const marketIds = getAllMarketIds();
      const counts = await Promise.all(
        marketIds.map(async (marketId) => {
          let marketParams = {
            ...params,
            _offset: 0,
            _limit: 1,
            status: 'approved',
            'market[]': marketId,
          };

          if (query && query?.length) {
            marketParams = {
              ...marketParams,
              q: query.toLowerCase(),
            };
          }

          const queryString = convertParamsToQuery(marketParams);
          const resp = await ProductLibraryService().searchProductLibrary(
            queryString
          );
          return resp.pagination?.count || 0;
        })
      );

      return {
        totalCount: counts.reduce((sum, count) => sum + count, 0),
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const filterProducts = createAsyncThunk(
  'productLibrary/filterProducts',
  async (
    {
      params,
      query,
      maintainCurrentPage = false,
    }: {
      params: ListProductLibraryParams;
      query?: string;
      maintainCurrentPage?: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const selectedMarket = params['market[]'];
      if (selectedMarket && isAllMarketsView(selectedMarket)) {
        const allMarketsResult = await dispatch(
          fetchAllMarketsCount({ params, query })
        ).unwrap();

        // fetch all markets
        const marketIds = getAllMarketIds();
        const allProducts = await Promise.all(
          marketIds.map(async (marketId) => {
            let marketParams = {
              ...params,
              _offset: 0,
              _limit: params._limit || 10,
              status: 'approved',
              'market[]': marketId,
            };

            if (query && query?.length) {
              marketParams = {
                ...marketParams,
                q: query.toLowerCase(),
              };
            }

            const queryString = convertParamsToQuery(marketParams);
            const resp = await ProductLibraryService().searchProductLibrary(
              queryString
            );
            return resp.data;
          })
        );

        // combine all products and sort by updated_at
        const combinedProducts = allProducts
          .flat()
          .sort(
            (a, b) =>
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
          );

        // pagination for all markets
        const offset = params._offset || 0;
        const limit = params._limit || 10;
        const paginatedProducts = combinedProducts.slice(
          offset,
          offset + limit
        );

        return {
          data: paginatedProducts,
          pagination: {
            offset,
            limit,
            count: allMarketsResult.totalCount,
            total: allMarketsResult.totalCount,
          },
          maintainCurrentPage,
        };
      }

      let updatedParams = {
        ...params,
        _offset: params._offset || 0,
        status: 'approved',
      };

      if (query && query?.length) {
        updatedParams = {
          ...updatedParams,
          q: query.toLowerCase(),
        };
      }

      const queryString = convertParamsToQuery(updatedParams);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        maintainCurrentPage,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateProducts = createAsyncThunk(
  'productLibrary/paginateProducts',
  async (
    {
      page,
      pageSize,
      query,
      filters,
      category,
      market,
    }: {
      page: number;
      pageSize: number;
      query?: string;
      filters?: SelectedFilters;
      category?: SelectDataItem | 'All';
      market: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let params: any = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
        status: 'approved',
      };

      const marketToUse = isAllMarketsView(market)
        ? getAllMarketIds()[0]
        : market;
      params['market[]'] = marketToUse;

      if (filters) {
        Object.keys(filters).forEach((filterGroup) => {
          const filterKey = filterGroup as keyof FiltersType;
          if (filters[filterKey]?.length) {
            params = {
              ...params,
              [filterGroup]: filters[filterKey],
            };
          }
        });
      }

      if (category && category !== 'All') {
        params = {
          ...params,
          category: category.key,
        };
      }

      if (query) {
        params = {
          ...params,
          q: query.toLowerCase(),
        };
      }

      const queryString = convertParamsToQuery(params);
      const resp = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        current: page,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreProductLibraryItems = createAsyncThunk(
  'productLibrary/loadMoreItems',
  async (
    { params, query }: { params: ListProductLibraryParams; query?: string },
    { rejectWithValue }
  ) => {
    try {
      let updatedParams = {
        ...params,
        _offset: params._offset || 0,
        status: 'approved',
      };

      if (query && query.length > 0) {
        updatedParams = {
          ...updatedParams,
          q: query.toLowerCase(),
        };
      }

      const queryString = convertParamsToQuery(updatedParams);
      const response = await ProductLibraryService().searchProductLibrary(
        queryString
      );

      return {
        data: response.data,
        pagination: response.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  'productLibrary/updateProduct',
  async ({ productId, product }: UpdateProductProps, { rejectWithValue }) => {
    try {
      const resp = await ProductLibraryService().updateProduct(
        productId,
        product
      );
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setSelectedMarket = createAsyncThunk(
  'productLibrary/setSelectedMarket',
  async (marketId: number, { dispatch, getState }) => {
    const state = getState() as RootState;
    const {
      searchQuery,
      selectedFilters,
      selectedCategory,
      currentPage,
      pageSize,
    } = state.productLibrary;

    dispatch(updateSelectedMarket(marketId));

    await dispatch(
      paginateProducts({
        page: currentPage,
        pageSize,
        query: searchQuery,
        filters: selectedFilters,
        category: selectedCategory,
        market: marketId,
      })
    );

    return marketId;
  }
);

export const productLibrarySlice = createSlice({
  name: 'productLibrary',
  initialState,
  reducers: {
    setProductItemSearchParams: (
      state,
      action: PayloadAction<ListProductLibraryParams>
    ) => {
      state.productItemSearchParams = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
      state.currentPage = 1;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    updateSelectedMarket: (state, action: PayloadAction<number>) => {
      state.selectedMarket = action.payload;
    },
    removeProductUPC(
      state,
      {
        payload: { fieldKey, action },
      }: PayloadAction<{
        fieldKey: number | string;
        action: 'UNSET' | 'DELETE';
      }>
    ) {
      if (action === 'DELETE') {
        delete state.productUPCs[fieldKey];
      } else {
        state.productUPCs[fieldKey] = undefined;
      }
    },
    resetProductUPCs(state) {
      state.productUPCs = {};
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
      state.pagination = {
        offset: 0,
        limit: state.pagination?.limit ?? 10,
        count: state.pagination?.count ?? 0,
      };
    },
    setSelectedCategory: (
      state,
      action: PayloadAction<SelectDataItem | 'All' | undefined>
    ) => {
      state.selectedCategory = action.payload;
      state.pagination = {
        offset: 0,
        limit: state.pagination?.limit ?? 10,
        count: state.pagination?.count ?? 0,
      };
    },
    setSelectedFilters: (state, action: PayloadAction<SelectedFilters>) => {
      state.selectedFilters = action.payload;
      state.pagination = {
        offset: 0,
        limit: state.pagination?.limit ?? 10,
        count: state.pagination?.count ?? 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductLibrary.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(fetchProductLibrary.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingProductLibraryItems = false;
        state.value = action.payload.data;
        state.allProductLibraryItems = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchProductLibrary.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(searchProductsByName.pending, (state) => {
        state.fetchingProductLibraryItems = true;
        state.searchingProductLibrary = true;
      })
      .addCase(searchProductsByName.fulfilled, (state, action) => {
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(searchProductsByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
      });

    builder
      .addCase(searchProductsByUPC.pending, (state) => {
        state.fetchingProductLibraryItems = true;
        state.searchingProductLibrary = true;
      })
      .addCase(searchProductsByUPC.fulfilled, (state, action) => {
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
        state.productUPCs[action.payload.fieldKey] = action.payload.data[0];
        state.pagination = action.payload.pagination;
      })
      .addCase(searchProductsByUPC.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
        state.searchingProductLibrary = false;
      });

    builder
      .addCase(filterProducts.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(filterProducts.fulfilled, (state, action) => {
        // only update totalCount if not in all markets view
        if (!isAllMarketsView(state.selectedMarket)) {
          state.totalCount = action.payload.pagination.count;
        }
        state.fetchingProductLibraryItems = false;
        state.pagination = action.payload.pagination;
        state.allProductLibraryItems = action.payload.data;
        state.value = action.payload.data;
        if (!action.payload.maintainCurrentPage) {
          state.currentPage = 1;
        }
      })
      .addCase(filterProducts.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(loadMoreProductLibraryItems.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(loadMoreProductLibraryItems.fulfilled, (state, action: any) => {
        state.fetchingProductLibraryItems = false;
        state.value = [...state.value, ...action.payload.data];
        state.pagination = action.payload.pagination;
        state.totalCount = action.payload.pagination.count;
      })
      .addCase(loadMoreProductLibraryItems.rejected, (state) => {
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(paginateProducts.fulfilled, (state, action) => {
        state.fetchingProductLibraryItems = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = action.payload.data;
          state.currentPage = action.payload.current;
        }
      })
      .addCase(paginateProducts.pending, (state) => {
        state.fetchingProductLibraryItems = true;
      })
      .addCase(paginateProducts.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductLibraryItems = false;
      });

    builder
      .addCase(updateProduct.fulfilled, (state) => {
        state.savingProductLibrary = false;
      })
      .addCase(updateProduct.pending, (state) => {
        state.savingProductLibrary = true;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingProductLibrary = false;
      });

    builder.addCase(fetchAllMarketsCount.fulfilled, (state, action) => {
      state.totalCount = action.payload.totalCount;
    });
  },
});

export const getProductLibrary = (state: RootState) =>
  state.productLibrary.value;
export const getProductLibraryUPCs = (state: RootState) =>
  state.productLibrary.productUPCs;
export const getFetchingProductLibrary = (state: RootState) =>
  state.productLibrary.fetchingProductLibraryItems;
export const getFetchingProductLibraryError = (state: RootState) =>
  state.productLibrary.error;
export const getTotalCount = (state: RootState) =>
  state.productLibrary.totalCount;
export const getPagination = (state: RootState) =>
  state.productLibrary.pagination;
export const getSearchingProductLibrary = (state: RootState) =>
  state.productLibrary.searchingProductLibrary;
export const getSavingProductLibrary = (state: RootState) =>
  state.productLibrary.savingProductLibrary;
export const getAllProducts = (state: RootState) =>
  state.productLibrary.allProductLibraryItems;

export const {
  setProductItemSearchParams,
  setPageSize,
  setCurrentPage,
  updateSelectedMarket,
  removeProductUPC,
  resetProductUPCs,
  setSearchQuery,
  setSelectedCategory,
  setSelectedFilters,
} = productLibrarySlice.actions;
export default productLibrarySlice.reducer;
