import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { ApiError, handleError, PaginationModel } from '../../../api/base';
import ManufacturerService from '../../../api/manufacturer';
import { ManufacturerModel } from '../../../models/manufacturer';
import { convertParamsToQuery } from '../../../util';

export interface ManufacturerState {
  value: ManufacturerModel[] | undefined;
  allManufacturers: ManufacturerModel[];
  allManufacturersValue: ManufacturerModel[];
  fetchingManufacturers: boolean;
  searchingManufacturers: boolean;
  savingManufacturer: boolean;
  deletingManufacturer: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  count: number;
  current: number;
  currentPage: number;
  pageSize: number;
}

interface CreateManufacturerProps {
  manufacturer: ManufacturerModel;
}
interface UpdateManufacturerProps {
  manufacturerId: string;
  manufacturer: ManufacturerModel;
}

const initialState: ManufacturerState = {
  value: undefined,
  allManufacturers: [],
  allManufacturersValue: [],
  fetchingManufacturers: false,
  searchingManufacturers: false,
  savingManufacturer: false,
  deletingManufacturer: false,
  error: { code: null, message: null },
  count: 0,
  current: 1,
  currentPage: 1,
  pageSize: 10,
  pagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
};

export interface ManufacturersParams {
  _limit?: number;
  _order_by?: string;
  _offset?: number;
  _columns?: string;
  name?: string;
  q?: string;
}

export const fetchManufacturers = createAsyncThunk(
  'manufacturer/fetchManufacturers',
  async (_arg, { getState, rejectWithValue }) => {
    try {
      const {
        manufacturer: { pagination },
      } = getState() as RootState;

      const nextParams: ManufacturersParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
        _columns: 'brands.*,parent.*',
      };

      const query = convertParamsToQuery(nextParams);
      let resp = await ManufacturerService().listManufacturers(query);

      const { limit, count, offset } = resp.pagination;
      let current = offset / limit + 1;

      if (offset >= count && count > 0) {
        current = current - 1;
        const nextParams = {
          _limit: limit,
          _offset: offset - limit,
          _order_by: 'created_at:desc',
          _columns: 'brands.*,parent.*',
        };

        const query = convertParamsToQuery(nextParams);
        resp = await ManufacturerService().listManufacturers(query);
      }

      return {
        current,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createManufacturer = createAsyncThunk(
  'manufacturer/createManufacturer',
  async (
    { manufacturer }: CreateManufacturerProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ManufacturerService().createManufacturer(manufacturer);

      await dispatch(fetchManufacturers());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateManufacturer = createAsyncThunk(
  'manufacturer/updateManufacturer',
  async (
    { manufacturerId, manufacturer }: UpdateManufacturerProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ManufacturerService().updateManufacturer(
        manufacturerId,
        manufacturer
      );

      await dispatch(fetchManufacturers());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteManufacturer = createAsyncThunk(
  'manufacturer/deleteManufacturer',
  async (manufacturerId: string, { dispatch, rejectWithValue }) => {
    try {
      const resp = await ManufacturerService().deleteManufacturer(
        manufacturerId
      );

      await dispatch(fetchManufacturers());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchManufacturersByName = createAsyncThunk(
  'manufacturer/searchManufacturersByName',
  async ({ query }: { query: string }, { getState, rejectWithValue }) => {
    try {
      const {
        manufacturer: { pagination },
      } = getState() as RootState;

      let params: ManufacturersParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
        _columns: 'brands.*,parent.*',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await ManufacturerService().listManufacturers(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateManufacturers = createAsyncThunk(
  'manufacturer/paginateManufacturers',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: ManufacturersParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
        _columns: 'brands.*,parent.*',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          q: `${queryStr.toLowerCase()}`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await ManufacturerService().listManufacturers(query);

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const manufacturerSlice = createSlice({
  name: 'manufacturer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // FETCH MANUFACTURERS
      .addCase(fetchManufacturers.pending, (state) => {
        state.fetchingManufacturers = true;
      })
      .addCase(fetchManufacturers.fulfilled, (state, action) => {
        state.fetchingManufacturers = false;
        state.value = action.payload.data;
        state.allManufacturers = action.payload.data;
        state.pagination = action.payload.pagination;
        state.current = action.payload.current;
      })
      .addCase(fetchManufacturers.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingManufacturers = false;
        state.searchingManufacturers = false;
      })

      // UPDATE MANUFACTURER
      .addCase(updateManufacturer.pending, (state) => {
        state.savingManufacturer = true;
      })
      .addCase(updateManufacturer.fulfilled, (state) => {
        state.savingManufacturer = false;
      })
      .addCase(updateManufacturer.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingManufacturer = false;
      })

      // DELETE MANUFACTURER
      .addCase(deleteManufacturer.pending, (state) => {
        state.deletingManufacturer = true;
      })
      .addCase(deleteManufacturer.fulfilled, (state) => {
        state.deletingManufacturer = false;
      })
      .addCase(deleteManufacturer.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.deletingManufacturer = false;
      })

      // CREATE MANUFACTURER
      .addCase(createManufacturer.pending, (state) => {
        state.savingManufacturer = true;
      })
      .addCase(createManufacturer.fulfilled, (state) => {
        state.savingManufacturer = false;
      })
      .addCase(createManufacturer.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.savingManufacturer = false;
      })

      // SEARCH MANUFACTURERS BY NAME
      .addCase(searchManufacturersByName.pending, (state) => {
        state.searchingManufacturers = true;
      })
      .addCase(searchManufacturersByName.fulfilled, (state, action) => {
        state.count = action.payload.pagination.count;
        state.fetchingManufacturers = false;
        state.searchingManufacturers = false;
        state.value = action.payload.data;
        state.allManufacturersValue = action.payload.data;
        state.pagination = action.payload.pagination;
        state.current = 1;
        state.currentPage = 1;
        state.pageSize = action.payload.pagination.limit;
      })
      .addCase(searchManufacturersByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingManufacturers = false;
        state.searchingManufacturers = false;
      })

      // PAGINATE MANUFACTURERS
      .addCase(paginateManufacturers.pending, (state) => {
        state.fetchingManufacturers = true;
      })
      .addCase(paginateManufacturers.fulfilled, (state, action) => {
        state.fetchingManufacturers = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...action.payload.data];
          state.allManufacturersValue = [...action.payload.data];
          state.fetchingManufacturers = false;
          state.current = action.payload.current;
          state.currentPage = action.meta.arg.page;
          state.pageSize = action.meta.arg.pageSize;
        }
      })
      .addCase(paginateManufacturers.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingManufacturers = false;
      });
  },
});

export default manufacturerSlice.reducer;
