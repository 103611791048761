import { useCallback, useEffect } from 'react';

export interface ProductSpawnData {
  model_id: string;
  name: string;
  height: string;
  width: string;
  depth: string;
  mouseX: number;
  mouseY: number;
}

interface PanelPosition {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

interface UnityBridgeCallbacksProps {
  onShowProductLibrary?: (position: PanelPosition) => void;
  onHideProductLibrary?: () => void;
  onProductPlaced?: (
    productId: string,
    position: { x: number; y: number; z: number }
  ) => void;
  onProductDeleted?: (productId: string) => void;
  onExportImage?: (
    imageBytes: Uint8Array,
    fileName: string,
    extension: string
  ) => void;
  onExportCSV?: (
    csvString: string,
    fileName: string,
    extension: string
  ) => void;
  onExportSegment?: (segmentFile: Uint8Array, segmentName: string) => void;
  onExportZip?: (
    zipFile: Uint8Array,
    fileName: string,
    extension: string
  ) => void;
  onExportPDF?: (
    pdfBytes: Uint8Array,
    fileName: string,
    extension: string
  ) => void;
  onAuthReady?: () => void;
}

export const useUnityBridge = (callbacks: UnityBridgeCallbacksProps) => {
  useEffect(() => {
    const handleUnityMessage = (event: MessageEvent) => {
      switch (event.data.type) {
        case 'SHOW_PRODUCT_LIBRARY':
          callbacks.onShowProductLibrary?.(event.data.position);
          break;
        case 'HIDE_PRODUCT_LIBRARY':
          callbacks.onHideProductLibrary?.();
          break;
        case 'EXPORT_IMAGE':
          callbacks.onExportImage?.(
            event.data.imageData.imageBytes,
            event.data.imageData.fileName,
            event.data.imageData.extension
          );
          break;
        case 'EXPORT_CSV':
          callbacks.onExportCSV?.(
            event.data.csvData.csvString,
            event.data.csvData.fileName,
            event.data.csvData.extension
          );
          break;
        case 'EXPORT_ZIP':
          callbacks.onExportZip?.(
            event.data.zipData.zipFile,
            event.data.zipData.fileName,
            event.data.zipData.extension
          );
          break;
        case 'EXPORT_PDF':
          callbacks.onExportPDF?.(
            event.data.pdfData.pdfBytes,
            event.data.pdfData.fileName,
            event.data.pdfData.extension
          );
          break;
        case 'AUTH_READY':
          callbacks.onAuthReady?.();
          break;
      }
    };

    window.addEventListener('message', handleUnityMessage);
    return () => window.removeEventListener('message', handleUnityMessage);
  }, [callbacks]);

  const hideProductLibrary = useCallback(() => {
    const unityFrame = document.querySelector('iframe');
    if (unityFrame?.contentWindow) {
      (unityFrame.contentWindow as any).HideProductLibrary();
    }
  }, []);

  const spawnProduct = useCallback((productData: ProductSpawnData) => {
    const jsonString = JSON.stringify(productData);
    const unityFrame = document.querySelector('iframe');

    if (unityFrame?.contentWindow) {
      (unityFrame.contentWindow as any).SpawnProduct(jsonString);
    } else if ((window as any).unityInstance) {
      (window as any).unityInstance.SendMessage(
        'JSProductSpawner',
        'SpawnProduct',
        jsonString
      );
    }
  }, []);

  const exportImage = useCallback(
    async (
      imageBytes: number[] | Uint8Array,
      fileName: string,
      extension = 'png'
    ) => {
      try {
        console.log('Raw image data:', imageBytes);
        console.log('Image data type:', typeof imageBytes);

        const bytes =
          imageBytes instanceof Uint8Array
            ? imageBytes
            : new Uint8Array(imageBytes);

        const blob = new Blob([bytes], { type: `image/${extension}` });

        try {
          const handle = await (window as any).showSaveFilePicker({
            suggestedName: `${fileName}.${extension}`,
            types: [
              {
                description: 'Image File',
                accept: {
                  'image/*': [`.${extension}`],
                },
              },
            ],
          });

          const writable = await handle.createWritable();
          await writable.write(blob);
          await writable.close();
        } catch (pickerError) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${fileName}.${extension}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }

        console.log('Image exported successfully:', fileName);
      } catch (error) {
        if ((error as any).name !== 'AbortError') {
          console.error('Error exporting image:', error);
        }
      }
    },
    []
  );

  const exportCSV = useCallback(
    async (csvString: string, fileName: string, extension = 'csv') => {
      try {
        const blob = new Blob([csvString], { type: 'text/csv' });

        const handle = await (window as any).showSaveFilePicker({
          suggestedName: `${fileName}.${extension}`,
          types: [
            {
              description: 'CSV File',
              accept: {
                'text/csv': [`.${extension}`],
              },
            },
          ],
        });

        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();

        console.log('CSV exported successfully:', fileName);
      } catch (error) {
        if ((error as any).name !== 'AbortError') {
          console.error('Error exporting CSV:', error);
        }
      }
    },
    []
  );

  const exportZip = useCallback(
    async (zipFile: Uint8Array, fileName: string, extension = 'zip') => {
      try {
        const blob = new Blob([zipFile], { type: 'application/zip' });

        const handle = await (window as any).showSaveFilePicker({
          suggestedName: `${fileName}.${extension}`,
          types: [
            {
              description: 'ZIP Archive',
              accept: {
                'application/zip': [`.${extension}`],
              },
            },
          ],
        });

        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();

        console.log('ZIP exported successfully:', fileName);
      } catch (error) {
        if ((error as any).name !== 'AbortError') {
          console.error('Error exporting ZIP:', error);
        }
      }
    },
    []
  );

  const exportPDF = useCallback(
    async (pdfBytes: Uint8Array, fileName: string, extension = 'pdf') => {
      try {
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });

        const handle = await (window as any).showSaveFilePicker({
          suggestedName: `${fileName}.${extension}`,
          types: [
            {
              description: 'PDF Document',
              accept: {
                'application/pdf': [`.${extension}`],
              },
            },
          ],
        });

        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();

        console.log('PDF exported successfully:', fileName);
      } catch (error) {
        if ((error as any).name !== 'AbortError') {
          console.error('Error exporting PDF:', error);
        }
      }
    },
    []
  );

  const sendAuthToken = useCallback(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const unityFrame = document.querySelector('iframe');

      if (unityFrame?.contentWindow) {
        unityFrame.contentWindow.postMessage(
          {
            type: 'RECEIVE_AUTH_TOKEN',
            token: token,
          },
          '*'
        );
      } else if ((window as any).unityInstance) {
        (window as any).unityInstance.SendMessage(
          'WebPortalBridge',
          'ReceiveAuthToken',
          token
        );
      }

      return true;
    }

    return false;
  }, []);

  return {
    hideProductLibrary,
    spawnProduct,
    exportImage,
    exportCSV,
    exportZip,
    exportPDF,
    sendAuthToken,
  };
};
