import React, { memo, useCallback, useMemo, useState } from 'react';

import { Badge, Button, ConfigProvider, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useLocation, withRouter } from 'react-router-dom';
import { useProductListOperations } from '../../../../../../../hooks/product-library/useProductListOperations';

import { ListProductLibraryParams } from '../../../../../../../hooks/product-library';
import { ListProductLibraryModel } from '../../../../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../../../../store';
import {
  paginateProducts,
  setCurrentPage,
  setPageSize,
} from '../../../../../../../store/features/productLibrary/productLibrarySlice';
import { setSelectedProducts } from '../../../../../../../store/features/productList/productListSlice';
import { propsAreEqual } from '../../../../../../../util';
import { customizeRenderEmpty } from '../../../../../../elements/CustomizeEmptyRender/customizeRenderEmpty';
import './ProductLibraryTable.less';

const { Title } = Typography;

interface ProductsTableProps {
  dataSource: ListProductLibraryModel[];
  headerTitle: string;
  columns: ColumnsType<ListProductLibraryModel>;
  noDataDescription?: string;
  tableHeight?: string;
  buildFilterParams: () => ListProductLibraryParams;
}

const ProductLibraryTable = ({
  dataSource,
  headerTitle,
  columns,
  noDataDescription,
  tableHeight,
}: ProductsTableProps) => {
  const dispatch = useAppDispatch();
  const { selectedProducts } = useAppSelector((state) => state.productList);
  const {
    selectedCategory,
    selectedFilters,
    searchQuery,
    pageSize,
    currentPage,
    fetchingProductLibraryItems,
    totalCount,
    selectedMarket,
  } = useAppSelector((state) => state.productLibrary);

  const {
    isAddingProducts,
    isRemovingProducts,
    handleAddMultipleProducts,
    handleRemoveMultipleProducts,
  } = useProductListOperations();

  const [selectedProductRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    []
  );

  const location = useLocation();
  const isProductListRoute = location.pathname === '/product-list';
  const isProductLibraryRoute = location.pathname === '/product-library';

  const hasSelected = selectedProductRowKeys.length > 0;
  const showRemoveButton =
    isProductListRoute ||
    (isProductLibraryRoute &&
      selectedProducts.every((product) => product.in_list === 1));

  const header = useMemo(
    () =>
      headerTitle ? (
        <div className="products-table-header">
          <Title level={3}>{headerTitle}</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
          />
        </div>
      ) : null,
    [headerTitle, totalCount]
  );

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      dispatch(setCurrentPage(page));
      dispatch(setPageSize(pageSize));
      dispatch(
        paginateProducts({
          page,
          pageSize,
          query: searchQuery,
          filters: selectedFilters,
          category: selectedCategory,
          market: selectedMarket,
        })
      );
    },
    [dispatch, searchQuery, selectedFilters, selectedCategory, selectedMarket]
  );

  const paginationSettings = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: totalCount,
    current: currentPage,
    pageSize: pageSize,
  };

  const handleClearSelection = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const handleOnChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: ListProductLibraryModel[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedProducts(selectedRows));
  };

  const rowSelection: TableRowSelection<ListProductLibraryModel> = {
    selectedRowKeys: selectedProductRowKeys,
    onChange: handleOnChange,
    preserveSelectedRowKeys: true,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const tableFooter = () => (
    <>
      <div className="table-footer-wrapper">
        <div className="table-footer-left">
          <span>
            {hasSelected
              ? `${selectedProductRowKeys.length} ${
                  selectedProductRowKeys.length === 1 ? 'Item' : 'Items'
                } selected: `
              : ''}
          </span>
          <Space size="middle">
            {showRemoveButton && (
              <Button
                type="primary"
                onClick={() => handleRemoveMultipleProducts(selectedProducts)}
                loading={isRemovingProducts}
                style={{ minWidth: '140px' }}
              >
                {isRemovingProducts ? '' : 'Remove from List'}
              </Button>
            )}

            {selectedProducts.every((product) => product.in_list === 0) && (
              <Button
                type="primary"
                onClick={() => handleAddMultipleProducts(selectedProducts)}
                loading={isAddingProducts}
                style={{ minWidth: '140px' }}
              >
                {isAddingProducts ? '' : 'Add to Product List'}
              </Button>
            )}
          </Space>
        </div>
        <div className="table-footer-right">
          <Button
            type="link"
            onClick={handleClearSelection}
            className="link-button"
          >
            Clear Selection
          </Button>
        </div>
      </div>
    </>
  );

  const footerContent = hasSelected ? tableFooter : undefined;

  return (
    <div className="products-table-list-wrapper">
      {header}
      <ConfigProvider
        renderEmpty={() => customizeRenderEmpty(noDataDescription)}
      >
        <Table
          className="products-table-list"
          footer={footerContent}
          scroll={{
            y: tableHeight || 240,
            scrollToFirstRowOnChange: false,
          }}
          rowSelection={rowSelection}
          loading={
            fetchingProductLibraryItems ||
            isAddingProducts ||
            isRemovingProducts
          }
          rowKey={(product: ListProductLibraryModel) =>
            `${product.uuid}-row-key`
          }
          columns={columns}
          dataSource={dataSource}
          pagination={paginationSettings}
          locale={{ emptyText: customizeRenderEmpty(noDataDescription) }}
        />
      </ConfigProvider>
    </div>
  );
};

export default withRouter<any, any>(memo(ProductLibraryTable, propsAreEqual));
