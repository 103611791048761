import { Button, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useDebounce from '../../../../../hooks/useDebounce';
import { FormatModel } from '../../../../../models/format';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateFormats,
  searchFormatsByName,
} from '../../../../../store/features/metaData/metaDataSlice';
import { UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';
import { RoutePath } from '../../../AppRoot/types';

import { SortOrder } from 'antd/es/table/interface';

import { useAuth } from '../../../../../hooks/useAuth';
import { ProductStatusEnum } from '../../../../../models/product-library';
import SearchBar from '../../../../elements/SearchBar/SearchBar';

const { Text } = Typography;

const FormatsTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    allFormats,
    fetchingFormats,
    searchingFormats,
    count,
    currentPage,
    pageSize,
  } = useAppSelector((state) => state.metaData);

  const { hasPermission } = useAuth();

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingFormats && !!searchQuery && allFormats.length === 0,
    [fetchingFormats, searchQuery, allFormats.length]
  );

  const hasFormats = !(!fetchingFormats && allFormats.length === 0);

  const columns: ColumnsType<FormatModel> = useMemo(
    () =>
      getColumns(history, hasPermission).map((column) => ({
        ...column,
        defaultSortOrder: column.defaultSortOrder as SortOrder | undefined,
      })),
    [history, hasPermission]
  );

  useEffect(() => {
    dispatch(
      searchFormatsByName({
        query: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      dispatch(
        paginateFormats({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      );
    },
    [debouncedSearchQuery, dispatch]
  );

  const paginationSettings = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current: currentPage,
    pageSize: pageSize,
  };

  return (
    <div>
      <SearchBar
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        isSearching={searchingFormats}
        canCreate={hasPermission(UserPermissions.MetaDataCreate)}
        createLink={DrawerHashRoute.FormatForm}
        createButtonText="Add New Format"
        placeholder="Search Formats"
      />
      {hasFormats && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={allFormats}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={paginationSettings}
          loading={fetchingFormats || searchingFormats}
        />
      )}
    </div>
  );
};

const getColumns = (
  history: any,
  hasPermission: (permission: UserPermissions | UserPermissions[]) => boolean
) => [
  {
    title: 'Format',
    dataIndex: 'name',
    width: '35%',
    sorter: (a: FormatModel, b: FormatModel) => (a.name > b.name ? 1 : -1),
  },
  {
    title: '# in Processing',
    dataIndex: 'in_processing',
    defaultSortOrder: 'descend',
    sorter: (a: FormatModel, b: FormatModel) =>
      (a.in_processing || 0) - (b.in_processing || 0),
  },
  {
    title: '# in Library',
    dataIndex: 'in_library',
    defaultSortOrder: 'descend',
    sorter: (a: FormatModel, b: FormatModel) =>
      (a.in_library || 0) - (b.in_library || 0),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    defaultSortOrder: 'descend',
    sorter: (a: FormatModel, b: FormatModel) =>
      a.status && b.status ? (a.status > b.status ? 1 : -1) : 0,
    render: (status: ProductStatusEnum) => (
      <Text
        type={
          status === ProductStatusEnum.needs_approval ? 'danger' : undefined
        }
      >
        {status === ProductStatusEnum.approved ? status : 'Needs Approval'}
      </Text>
    ),
  },
  {
    title: 'Actions',
    width: 100,
    render: (format: FormatModel) => (
      <Space direction="horizontal" size="middle">
        {hasPermission(UserPermissions.MetaDataEdit) && (
          <Button
            type="link"
            className="link-button"
            onClick={() =>
              history.push({
                hash: DrawerHashRoute.FormatForm,
                state: { data: format },
              })
            }
          >
            Edit
          </Button>
        )}
        <Button
          className="link-button"
          type="link"
          onClick={() =>
            history.push({
              state: { format },
              pathname: RoutePath.ProductLibrary,
            })
          }
        >
          View in Library
        </Button>
      </Space>
    ),
  },
];

export default FormatsTab;
