import { DndContext, DragOverlay, DragStartEvent } from '@dnd-kit/core';
import { Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { WBMLProduct as WBMLProductAPI } from '../../../api/wbml';
import { useUnityBridge } from '../../../hooks/useUnityBridge';
import { WBMLProduct } from '../../../models/wbml';
import { useAppDispatch } from '../../../store';
import { setUnityPosition } from '../../../store/features/wbml/wbmlSlice';
import { AppLayoutContext } from '../AppRoot/AppRoot';
import DraggableProductItem from './DraggableProductItem';
import DroppableUnityPlayer from './DroppableUnityPlayer';
import ProductsPanel from './ProductsPanel';
import { convertToUnityCoordinates, getMousePosition } from './helpers';

const PlanogramView = () => {
  const dispatch = useAppDispatch();
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeProduct, setActiveProduct] = useState<WBMLProduct | null>(null);
  const [showProductsPanel, setShowProductsPanel] = useState(true);

  const [isOverUnityPlayer, setIsOverUnityPlayer] = useState(false);
  const [currentUnityPosition, setCurrentUnityPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const {
    hideProductLibrary,
    spawnProduct,
    exportImage,
    exportCSV,
    exportZip,
    exportPDF,
    sendAuthToken,
  } = useUnityBridge({
    onShowProductLibrary: (position) => {
      console.log('Message received from Unity: Showing products panel');
      setShowProductsPanel(true);
      dispatch(setUnityPosition(position));
    },
    onHideProductLibrary: () => {
      console.log('Message received from Unity: Hiding products panel');
      setShowProductsPanel(false);
      dispatch(setUnityPosition(null));
    },
    onExportImage: (
      imageBytes: Uint8Array,
      fileName: string,
      extension: string
    ) => {
      console.log(
        'Message received from Unity: Exporting image ',
        imageBytes,
        fileName,
        extension
      );
      exportImage(imageBytes, fileName, extension);
    },
    onExportCSV: (csvString: string, fileName: string, extension: string) => {
      console.log(
        'Message received from Unity: Exporting CSV ',
        csvString,
        fileName,
        extension
      );
      exportCSV(csvString, fileName, extension);
    },
    onExportZip: (zipFile: Uint8Array, fileName: string, extension: string) => {
      console.log(
        'Message received from Unity: Exporting ZIP ',
        zipFile,
        fileName,
        extension
      );
      exportZip(zipFile, fileName, extension);
    },
    onExportPDF: (
      pdfBytes: Uint8Array,
      fileName: string,
      extension: string
    ) => {
      console.log(
        'Message received from Unity: Exporting PDF ',
        pdfBytes,
        fileName,
        extension
      );
      exportPDF(pdfBytes, fileName, extension);
    },
    onAuthReady: () => {
      console.log('Message received from Unity: Auth ready');
      sendAuthToken();
    },
  });

  const { sidebarCollapsed } = useContext(AppLayoutContext);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
    setActiveProduct(event.active.data.current as WBMLProduct);
  };

  const handleDragEnd = () => {
    if (activeProduct && currentUnityPosition) {
      const spawnData = {
        model_id: activeProduct.id,
        name: activeProduct.name || '',
        height: String(activeProduct?.height || 0),
        width: String(activeProduct?.width || 0),
        depth: String(activeProduct?.depth || 0),
        mouseX: currentUnityPosition.x,
        mouseY: currentUnityPosition.y,
      };

      spawnProduct(spawnData);
      console.log('Spawning product with data:', spawnData);
    }

    setActiveId(null);
  };

  const handleCloseProductsPanel = () => {
    setShowProductsPanel(false);
    hideProductLibrary();
    if ((window as any).unityInstance?.canvas) {
      (window as any).unityInstance.canvas.focus();
    }
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!isOverUnityPlayer) return;
      const position = getMousePosition(event, sidebarCollapsed!);

      const unityPosition = convertToUnityCoordinates(
        position.x,
        position.y,
        window.innerHeight
      );

      setCurrentUnityPosition(unityPosition);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [sidebarCollapsed, isOverUnityPlayer]);

  useEffect(() => {
    console.log('Sending authentication token to Unity');
    sendAuthToken();
  }, [sendAuthToken]);

  return (
    <>
      <Button
        type="primary"
        style={{
          position: 'absolute',
          top: 10,
          left: 250,
          background: 'lime',
          color: 'black',
          zIndex: 1000,
        }}
        onClick={() =>
          setShowProductsPanel((prev) => {
            console.log('prev', prev);
            return !prev;
          })
        }
      >
        {showProductsPanel ? 'Hide Products' : 'Show Products'}
      </Button>

      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <DroppableUnityPlayer onIsOverChange={setIsOverUnityPlayer} />
        {showProductsPanel && (
          <ProductsPanel onClose={handleCloseProductsPanel} />
        )}
        <DragOverlay>
          {activeId && activeProduct ? (
            <DraggableProductItem
              product={activeProduct as WBMLProductAPI}
              index={activeProduct.index || 0}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default PlanogramView;
