import { FC, memo, useCallback, useMemo } from 'react';

import { Button, Form, Input, message, Space } from 'antd';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { useAuth } from '../../../../hooks/useAuth';
import { ClientModel } from '../../../../models/client';
import { EmailNotificationModel } from '../../../../models/emailNotification';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { createClientEmailNotification } from '../../../../store/features/clients/clientsSlice';
import { propsAreEqual, UserPermissions } from '../../../../util';

import './EmailNotificationForm.less';

interface EmailNotificationFormProps {
  history: History;
  clientId: string;
  data?: ClientModel;
  closeModal: () => void;
}

interface EmailNotificationProps {
  id?: string;
  email: string;
  name: string;
}

const EmailNotificationForm: FC<EmailNotificationFormProps> = ({
  clientId,
  data,
  closeModal,
}: EmailNotificationFormProps) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  const fields = useMemo(() => Object.keys(!data), [data]);

  const { hasPermission } = useAuth();

  const { fetchingEmailNotifications } = useAppSelector(
    (state) => state.clients
  );

  const onSuccess = useCallback(() => {
    message.success('Email added.');
    closeModal();
    form.resetFields();
  }, [closeModal, form]);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const addEmail = useCallback(
    async (emailFormValues: EmailNotificationProps) => {
      try {
        const short_name = data?.short_name;
        const emailNotificationField = {
          short_name,
          fields: {
            email_notify_list: [emailFormValues],
          },
        };

        await dispatch(
          createClientEmailNotification({
            clientId,
            emailNotificationField,
          })
        );
        onSuccess();
      } catch (error) {
        onError(error as ApiError);
      }
    },
    [clientId, data?.short_name, dispatch, onError, onSuccess]
  );

  const onSubmit = useCallback(
    (values: EmailNotificationModel) => {
      form.validateFields(fields).then(() => addEmail(values));
    },
    [fields, form, addEmail]
  );

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Email is required.' }]}
      >
        <Input data-cy="email-notification-form-input" placeholder="Email" />
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Name is required.' }]}
      >
        <Input data-cy="email-notification-form-input" placeholder="Name" />
      </Form.Item>

      <Form.Item>
        <Space style={{ float: 'right' }}>
          <Button onClick={closeModal}>Cancel</Button>
          {hasPermission(UserPermissions.ClientsEdit) ? (
            <Button
              data-cy="email-notification-form-submit-btn"
              htmlType="submit"
              type="primary"
              loading={fetchingEmailNotifications}
              disabled={fetchingEmailNotifications}
            >
              Add
            </Button>
          ) : null}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default withRouter<any, any>(memo(EmailNotificationForm, propsAreEqual));
