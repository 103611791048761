export enum UserPermissions {
  ClientsView = 'Clients:View',
  ClientsCreate = 'Clients:Create',
  ClientsEdit = 'Clients:Edit',
  ClientsDelete = 'Clients:Delete',
  FebDesignsView = 'FebDesigns:View',
  FebDesignsCreate = 'FebDesigns:Create',
  FebDesignsEdit = 'FebDesigns:Edit',
  FebDesignsDelete = 'FebDesigns:Delete',
  OvrProjectsView = 'OvrProjects:View',
  OvrProjectsCreate = 'OvrProjects:Create',
  OvrProjectsTest = 'OvrProjects:Test',
  OvrProjectsEdit = 'OvrProjects:Edit',
  OvrProjectsDelete = 'OvrProjects:Delete',
  OvrProjectsDataExport = 'OvrProjects:DataExport',
  UsersView = 'Users:View',
  UsersCreate = 'Users:Create',
  UsersEdit = 'Users:Edit',
  UsersDelete = 'Users:Delete',
  UserSwitchClients = 'User:SwitchClients',
  RolesView = 'Roles:View',
  RolesCreate = 'Roles:Create',
  RolesEdit = 'Roles:Edit',
  RolesDelete = 'Roles:Delete',
  CustomFieldsView = 'CustomFields:View',
  CustomFieldsCreate = 'CustomFields:Create',
  CustomFieldsEdit = 'CustomFields:Edit',
  CustomFieldsDelete = 'CustomFields:Delete',
  SystemConfigurationView = 'SystemConfiguration:View',
  SystemConfigurationCreate = 'SystemConfiguration:Create',
  SystemConfigurationEdit = 'SystemConfiguration:Edit',
  SystemConfigurationDelete = 'SystemConfiguration:Delete',
  ModelView = 'Model:View',
  ModelViewPrivilageMetaData = 'Model:ViewPrivilageMetaData',
  ModelCreate = 'Model:Create',
  ModelUpload = 'Model:Upload',
  ModelEdit = 'Model:Edit',
  ModelViewUnapproved = 'Model:ViewUnapproved',
  ModelApprove = 'Model:Approve',
  ModelDownloadFrontPng = 'Model:DownloadFrontPng',
  ModelDownloadAllPng = 'Model:DownloadAllPng',
  ModelDownloadCsv = 'Model:DownloadCsv',
  ModelDownloadRsg = 'Model:DownloadRsg',
  ModelDownloadC4d = 'Model:DownloadC4d',
  ModelDelete = 'Model:Delete',
  ModelViewLibraryStatistics = 'Model:ViewLibraryStatistics',
  ModelViewProductModel = 'Model:ViewProductModel',
  PogCheckerView = 'PogChecker:View',
  MetaDataView = 'MetaData:View',
  MetaDataCreate = 'MetaData:Create',
  MetaDataEdit = 'MetaData:Edit',
  MetaDataDelete = 'MetaData:Delete',
  MetaDataApprove = 'MetaData:Approve',
  CustomtDataView = 'CustomtData:View',
  CustomDataCreate = 'CustomData:Create',
  CustomDataEdit = 'CustomData:Edit',
  CustomDataDelete = 'CustomData:Delete',
  CustomDataApprove = 'CustomData:Approve',
  OvrFileManagementView = 'OvrFileManagement:View',
  OvrFileManagementUpload = 'OvrFileManagement:Upload',
  OvrFileManagementEdit = 'OvrFileManagement:Edit',
  OvrFileManagementDelete = 'OvrFileManagement:Delete',
  ModelDownloadObj = 'Model:DownloadObj',
  PortalFileManagementView = 'PortalFileManagement:View',
  PortalFileManagementUpload = 'PortalFileManagement:Upload',
  PortalFileManagementEdit = 'PortalFileManagement:Edit',
  PortalFileManagementDelete = 'PortalFileManagement:Delete',
  ApiHealthCheck = 'API:HealthCheck',
}
