import { DownOutlined } from '@ant-design/icons';
import { Alert, Dropdown, Menu, MenuProps, Space } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AuthService from '../../../api/auth';
import { BASE_API_URL } from '../../../config';
import { useAuth } from '../../../hooks/useAuth';
import { ListProductLibraryModel } from '../../../models/product-library/index';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  createCronJob,
  fetchCronJobs,
} from '../../../store/features/modelDownload/modelDownloadSlice';
import { convertParamsToQuery, UserPermissions } from '../../../util';
import Button from '../../elements/Button';
import Spinner from '../../elements/Spinner/Spinner';
import DownloadItem from '../DownloadItem';
import './DropdownMenu.less';

interface DropdownMenuProps {
  requireSelectedProduct: boolean;
  productItem?: ListProductLibraryModel;
}

const DropdownMenu = ({
  requireSelectedProduct,
  productItem,
}: DropdownMenuProps) => {
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuth();
  const { selectedProducts } = useAppSelector((state) => state.productList);
  const { cronJobList } = useAppSelector((state) => state.modelDownload);
  const [disabled, setDisabled] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const loadingRef = useRef(false);

  useEffect(() => {
    setDisabled(selectedProducts.length === 0);
  }, [selectedProducts]);

  const startCronJobFetching = useCallback(() => {
    setIsFetching(true);
    dispatch(fetchCronJobs())
      .unwrap()
      .then(() => {
        setIsFetching(false);
      })
      .catch((error: any) => {
        setIsFetching(false);
        setFetchError(error.message);
      });
  }, [dispatch]);

  useEffect(() => {
    if (dropdownVisible) {
      startCronJobFetching();
    }
  }, [dropdownVisible, startCronJobFetching]);

  useEffect(() => {
    const allJobsCompleted = cronJobList?.cronJobs?.every(
      (cronJob) => cronJob?.status !== 'processing'
    );

    if (allJobsCompleted) {
      loadingRef.current = false;
    }
  }, [cronJobList]);

  const handleMenuClick: MenuProps['onClick'] = useMemo(
    () => (e: any) => {
      loadingRef.current = true;
      const queryStr = convertParamsToQuery({
        _token: AuthService().getAuthToken(),
      });
      const products = productItem
        ? [productItem.uuid]
        : selectedProducts.map((product) => product.uuid);

      switch (e.key) {
        case '1':
          if (hasPermission(UserPermissions.ModelDownloadCsv)) {
            const downloadLinkEl = document.createElement('a');
            downloadLinkEl.href = `${BASE_API_URL()}/product-list/download/csv${queryStr}`;
            downloadLinkEl.click();
            downloadLinkEl.remove();
          }
          break;
        case '2':
          if (hasPermission(UserPermissions.ModelDownloadRsg)) {
            dispatch(createCronJob({ fileType: 'obj', products }));
          }
          break;
        case '3':
          if (hasPermission(UserPermissions.ModelDownloadC4d)) {
            dispatch(createCronJob({ fileType: 'c4d', products }));
          }
          break;
        case '4':
          if (hasPermission(UserPermissions.ModelDownloadAllPng)) {
            dispatch(createCronJob({ fileType: 'png', products }));
          }
          break;
        case '5':
          if (hasPermission(UserPermissions.ModelDownloadFrontPng)) {
            dispatch(createCronJob({ fileType: 'front_png', products }));
          }
          break;
        default:
          break;
      }
    },
    [dispatch, productItem, selectedProducts, hasPermission]
  );

  const renderMenuItems = useCallback(() => {
    const items = [];

    if (hasPermission(UserPermissions.ModelDownloadCsv)) {
      items.push(<Menu.Item key="1">Download CSV</Menu.Item>);
    }

    if (hasPermission(UserPermissions.ModelDownloadRsg)) {
      items.push(
        <Menu.Item key="2" disabled={disabled && requireSelectedProduct}>
          Download RSG
        </Menu.Item>
      );
    }

    if (hasPermission(UserPermissions.ModelDownloadC4d)) {
      items.push(
        <Menu.Item key="3" disabled={disabled && requireSelectedProduct}>
          Download C4D
        </Menu.Item>
      );
    }

    if (hasPermission(UserPermissions.ModelDownloadAllPng)) {
      items.push(
        <Menu.Item key="4" disabled={disabled && requireSelectedProduct}>
          Download All PNG
        </Menu.Item>
      );
    }

    if (hasPermission(UserPermissions.ModelDownloadFrontPng)) {
      items.push(
        <Menu.Item key="5" disabled={disabled && requireSelectedProduct}>
          Download Front PNG
        </Menu.Item>
      );
    }

    if (items.length > 0) {
      items.push(
        <Menu.Item className="downloads-dropdown-menu-item" key="cron-jobs">
          {fetchError && <Alert message={fetchError} type="error" />}
          {cronJobList?.cronJobs
            ?.filter((cronJob) => cronJob.status !== 'processing')
            .map((cronJob) => (
              <DownloadItem key={cronJob.job_id} cronJob={cronJob} />
            ))}
          {isFetching ? (
            <div className="loading-container">
              <Spinner /> <p className="loading-text">Loading jobs...</p>
            </div>
          ) : loadingRef.current ? (
            <div className="loading-container">
              <Spinner /> <p className="loading-text">Processing download...</p>
            </div>
          ) : null}
        </Menu.Item>
      );
    }

    return items;
  }, [
    hasPermission,
    disabled,
    requireSelectedProduct,
    cronJobList,
    isFetching,
    fetchError,
  ]);

  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick} className="downloads-dropdown-menu">
        {renderMenuItems()}
      </Menu>
    ),
    [handleMenuClick, renderMenuItems]
  );

  const hasAnyDownloadPermission = [
    UserPermissions.ModelDownloadCsv,
    UserPermissions.ModelDownloadRsg,
    UserPermissions.ModelDownloadC4d,
    UserPermissions.ModelDownloadAllPng,
    UserPermissions.ModelDownloadFrontPng,
  ].some((permission) => hasPermission(permission));

  if (!hasAnyDownloadPermission) {
    return null;
  }

  return (
    <Dropdown
      overlay={menu}
      overlayClassName="downloads-dropdown"
      trigger={['click']}
      onVisibleChange={setDropdownVisible}
    >
      <Button type="primary">
        <Space>
          Download
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DropdownMenu;
