import { memo, useCallback, useEffect, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Tooltip } from 'antd';
import { History } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../../api/base';
import { ListProductLibraryModel } from '../../../../../models/product-library';
import { deleteProductItem } from '../../../../../store/features/productItem/productItemSlice';
import {
  addProductItemToList,
  removeProductItemFromList,
  searchProductsByName,
} from '../../../../../store/features/productList/productListSlice';
import { useAppDispatch } from '../../../../../store/index';
import { propsAreEqual, UserPermissions } from '../../../../../util';

import { useAuth } from '../../../../../hooks/useAuth';
import DropdownMenu from '../../../../containers/DropdownMenu';
import './HeaderSectionRight.less';

interface HeaderSectionRightProps {
  productItem: ListProductLibraryModel;
  displayEditableForm: boolean;
  history: History;
  canEdit: boolean;
  setDisplayEditableForm: (
    value: boolean | ((prevState: boolean) => boolean)
  ) => void;
}

const HeaderSectionRight = ({
  productItem: initialProductItem,
  displayEditableForm,
  history,
  canEdit,
  setDisplayEditableForm,
}: HeaderSectionRightProps) => {
  const dispatch = useAppDispatch();
  const [isAddingToList, setIsAddingToList] = useState(false);
  const [isRemovingFromList, setIsRemovingFromList] = useState(false);
  const [productItem, setProductItem] = useState(initialProductItem);

  const { userRoles, hasPermission } = useAuth();

  const hasClientRole = userRoles.some(
    (role) => role.name.toLowerCase() === 'client'
  );

  const canDeleteModels = hasPermission(UserPermissions.ModelDelete);

  useEffect(() => {
    setProductItem(initialProductItem);
  }, [initialProductItem]);

  const onError = useCallback((err: ApiError) => {
    message.destroy('deleting-product-item-message');
    handleError(err);
  }, []);

  const handleDeleteProductItem = useCallback(
    async (productItem: ListProductLibraryModel) => {
      await message.loading(
        {
          content: 'Deleting product..',
          key: 'deleting-productItem-message',
        },
        0
      );

      const resp: any = await dispatch(deleteProductItem(productItem.uuid));
      if (resp.error) {
        onError({ error: resp.payload.error });
      } else {
        message.destroy('deleting-product-item-message');
        history.push('/product-library');
        message.success(`product '${productItem.name}' deleted.`);
      }
    },
    [dispatch, onError, history]
  );

  const handleConfirmDeleteProductItem = useCallback(
    (
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      ProductItem: ListProductLibraryModel
    ) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete product '${ProductItem.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover this product.',
        okText: 'Delete',
        onOk: () => handleDeleteProductItem(productItem),
      });
    },
    [handleDeleteProductItem, productItem]
  );

  const handleEditOrDelete = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    productItem: ListProductLibraryModel
  ) => {
    if (!displayEditableForm || process.env.NODE_ENV !== 'development') {
      setDisplayEditableForm(true);
    } else {
      handleConfirmDeleteProductItem(e, productItem);
    }
  };

  const handleAddToList = useCallback(async () => {
    setIsAddingToList(true);
    try {
      await dispatch(addProductItemToList(productItem));
      await dispatch(searchProductsByName(''));
      setProductItem((prevItem) => ({ ...prevItem, in_list: 1 }));
      message.success('Product added to list successfully');
    } catch (error) {
      handleError(error as ApiError);
    } finally {
      setIsAddingToList(false);
    }
  }, [dispatch, productItem]);

  const handleRemoveFromList = useCallback(async () => {
    setIsRemovingFromList(true);
    try {
      await dispatch(removeProductItemFromList(productItem.uuid));
      await dispatch(searchProductsByName(''));
      setProductItem((prevItem) => ({ ...prevItem, in_list: 0 }));
      message.success('Product removed from list successfully');
    } catch (error) {
      handleError(error as ApiError);
    } finally {
      setIsRemovingFromList(false);
    }
  }, [dispatch, productItem]);

  return (
    <Space size="middle">
      {canEdit && (
        <Button
          type="primary"
          danger={
            canDeleteModels &&
            process.env.NODE_ENV === 'development' &&
            displayEditableForm
          }
          style={{ minWidth: '90px' }}
          onClick={(e) => handleEditOrDelete(e, productItem)}
        >
          {canDeleteModels &&
          process.env.NODE_ENV === 'development' &&
          displayEditableForm
            ? 'Delete'
            : 'Edit'}
        </Button>
      )}

      {!hasClientRole ? (
        <DropdownMenu
          requireSelectedProduct={false}
          productItem={productItem}
        />
      ) : null}

      {productItem && productItem?.in_list === 1 ? (
        <Tooltip title="Remove from Product List">
          <Button onClick={handleRemoveFromList} loading={isRemovingFromList}>
            Remove from List
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Add to Product List">
          <Button
            type="primary"
            onClick={handleAddToList}
            loading={isAddingToList}
          >
            Add to List
          </Button>
        </Tooltip>
      )}
    </Space>
  );
};

export default withRouter<any, any>(memo(HeaderSectionRight, propsAreEqual));
