import { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../../../../hooks/useAuth';
import useDebounce from '../../../../../hooks/useDebounce';
import { ParentCompanyModel } from '../../../../../models/parent-company';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateParentCompanies,
  searchParentCompaniesByName,
} from '../../../../../store/features/parentCompany/parentCompanySlice';
import { UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';
import SearchBar from '../../../../elements/SearchBar/SearchBar';
import { RoutePath } from '../../../AppRoot/types';

const { Text } = Typography;

const ParentCompanyTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { hasPermission } = useAuth();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    value: parentCompanies,
    allParentCompaniesValue,
    fetchingParentCompanies,
    searchingParentCompanies,
    count,
    currentPage,
    pageSize,
  } = useAppSelector((state) => state.parentCompany);

  const hasNoSearchResultsFound = useMemo(
    () =>
      !fetchingParentCompanies &&
      !!searchQuery &&
      allParentCompaniesValue.length === 0,
    [fetchingParentCompanies, searchQuery, allParentCompaniesValue.length]
  );

  const hasParentCompanies = !(
    !fetchingParentCompanies && parentCompanies?.length === 0
  );

  const columns: ColumnsType<ParentCompanyModel> = useMemo(
    () => getColumns(history, hasPermission),
    [history, hasPermission]
  );

  useEffect(() => {
    dispatch(
      searchParentCompaniesByName({
        query: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      dispatch(
        paginateParentCompanies({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      );
    },
    [debouncedSearchQuery, dispatch]
  );

  const paginationSettings = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current: currentPage,
    pageSize: pageSize,
  };

  return (
    <div>
      <SearchBar
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        isSearching={searchingParentCompanies}
        canCreate={hasPermission(UserPermissions.MetaDataCreate)}
        createLink={DrawerHashRoute.ParentCompanyForm}
        createButtonText="Add New Parent Company"
        placeholder="Search Parent Companies"
      />
      {hasParentCompanies && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={parentCompanies}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={paginationSettings}
          loading={fetchingParentCompanies || searchingParentCompanies}
          expandable={{
            expandIcon: ({ expandable, expanded, onExpand, record }) =>
              expandable ? (
                <span className="anticon " onClick={(e) => onExpand(record, e)}>
                  {expanded ? (
                    <DownOutlined
                      style={{
                        fontSize: '10px',
                        marginRight: '18px',
                      }}
                    />
                  ) : (
                    <RightOutlined
                      style={{ fontSize: '10px', marginRight: '18px' }}
                    />
                  )}
                </span>
              ) : null,
            expandedRowRender: (record) => (
              <div style={{ margin: 0 }}>
                <Text>Manufacturer Associations:</Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '8px',
                  }}
                >
                  {record.manufacturers?.map((m) => (
                    <Link
                      key={m.uuid}
                      to={{
                        pathname: RoutePath.ProductLibrary,
                        state: { manufacturer: m },
                      }}
                    >
                      <Button
                        className="link-button"
                        type="link"
                        style={{ padding: '4px 0' }}
                      >
                        {m.name}
                      </Button>
                    </Link>
                  ))}
                </div>
              </div>
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
        />
      )}
    </div>
  );
};

const getColumns = (
  history: any,
  hasPermission: (permission: UserPermissions | UserPermissions[]) => boolean
): ColumnsType<ParentCompanyModel> => [
  {
    title: 'Parent Company',
    dataIndex: 'name',
    width: '35%',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
  },
  {
    title: '# in Processing',
    dataIndex: 'in_processing',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.in_processing - b.in_processing,
  },
  {
    title: '# in Library',
    dataIndex: 'in_library',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.in_library - b.in_library,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.status && b.status) {
        return a.status > b.status ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (status: string) => {
      if (status === 'approved') {
        return <Text>{status}</Text>;
      } else {
        return <Text type="danger">Needs Approval</Text>;
      }
    },
  },
  {
    title: 'Actions',
    width: 100,
    render: (parentco: ParentCompanyModel) => (
      <Space direction="horizontal" size="middle">
        {hasPermission(UserPermissions.MetaDataEdit) && (
          <Button
            type="link"
            className="link-button"
            onClick={() =>
              history.push({
                hash: DrawerHashRoute.ParentCompanyForm,
                state: { data: parentco },
              })
            }
          >
            Edit
          </Button>
        )}
        <Button
          className="link-button"
          type="link"
          onClick={() =>
            history.push({
              state: { parentco },
              pathname: RoutePath.ProductLibrary,
            })
          }
        >
          View in Library
        </Button>
      </Space>
    ),
  },
];

export default ParentCompanyTab;
