import { useEffect } from 'react';

import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { EmailNotificationModel } from '../../../../models/emailNotification';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getClientEmailNotification } from '../../../../store/features/clients/clientsSlice';

const { Title } = Typography;

type SeatConcurrentUserTableProps = {
  clientId: string;
};

const SeatConcurrentUserTable = ({
  clientId,
}: SeatConcurrentUserTableProps) => {
  const dispatch = useAppDispatch();

  const { emailNotifications } = useAppSelector((state) => state.clients);

  useEffect(() => {
    dispatch(getClientEmailNotification({ clientId }));
  }, [clientId, dispatch]);

  const columns: ColumnsType<any> = [
    {
      title: 'Seat',
      dataIndex: 'seat',
      width: '20%',
      sorter: (a, b) => (a.seat > b.seat ? 1 : -1),
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      width: '20%',
      sorter: (a, b) => (a.userName > b.userName ? 1 : -1),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
    },
    {
      title: 'Login Timestamp',
      dataIndex: 'loginTimestamp',
      width: '15%',
      sorter: (a, b) => (a.loginTimestamp > b.loginTimestamp ? 1 : -1),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: '1rem 0',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Title level={5}>Seat Management/Concurrent Users</Title>
        </div>
      </div>

      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        rowKey={(emailNotification: EmailNotificationModel) =>
          `${emailNotification?.id}-row-key`
        }
        dataSource={emailNotifications}
      />
    </div>
  );
};

export default SeatConcurrentUserTable;
