import { LoadingOutlined } from '@ant-design/icons';
import { memo, useMemo } from 'react';

import { Button, Image, Space, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import imagePlaceholder from '../../../../../assets/images/product-image-placeholder.png';
import { ListProductLibraryModel } from '../../../../../models/product-library';
import { formatDate, propsAreEqual } from '../../../../../util';

import { ListProductLibraryParams } from '../../../../../hooks/product-library/list-product-library';
import { useProductListOperations } from '../../../../../hooks/product-library/useProductListOperations';
import './ProductLibraryItemsList.less';
import ProductLibraryGridList from './components/ProductLibraryGridList';
import ProductLibraryTable from './components/ProductLibrayTable/ProductLibraryTable';

const { Text } = Typography;

interface ProductLibraryItemsListProps {
  skeletonItemLength?: number;
  productLibrary: ListProductLibraryModel[];
  loading: boolean;
  hasMore: boolean;
  viewType: ProductLibraryListViewType;
  buildFilterParams: () => ListProductLibraryParams;
}

export enum ProductLibraryListViewType {
  Grid = 'grid',
  Table = 'table',
}

const ProductLibraryItemsList = ({
  productLibrary,
  loading,
  hasMore,
  viewType,
  buildFilterParams,
}: ProductLibraryItemsListProps) => {
  const { loadingItems, handleAddSingleProduct, handleRemoveSingleProduct } =
    useProductListOperations();

  const columns: ColumnsType<ListProductLibraryModel> = useMemo(
    () => [
      {
        title: 'Model',
        dataIndex: 'data',
        key: 'model',
        fixed: 'left',
        width: 100,
        render: (_, productItem) => {
          return (
            <Link
              to={{
                pathname: `/product-library/${productItem.uuid}`,
                state: {
                  productItem,
                },
              }}
            >
              <Image
                style={{
                  width: '100%',
                  maxHeight: '130px',
                  objectFit: 'cover',
                }}
                src={
                  productItem.image_url
                    ? productItem.image_url
                    : imagePlaceholder
                }
                alt={`${productItem.name}`}
                preview={false}
              />
            </Link>
          );
        },
      },
      {
        title: 'UPC',
        dataIndex: 'upc',
        key: 'upc',
        width: 150,
        render: (upc: string) =>
          !!upc ? (
            <Tooltip title={upc} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {upc}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              N/A
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          Number(`${a?.upc}`?.replace(/\D/g, '')) -
          Number(`${b?.upc}`?.replace(/\D/g, '')),
      },
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        render: (productName: string) =>
          !!productName ? (
            <Tooltip title={productName} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{productName}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No product name.
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a?.name?.localeCompare(b?.name),
      },
      {
        title: 'Brand',
        dataIndex: 'brand_name',
        key: 'brand',
        width: 100,
        render: (brand: string) =>
          !!brand ? (
            <Tooltip title={brand} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{brand}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No brand.
            </Text>
          ),

        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a.brand?.name.localeCompare(b.brand?.name),
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: 150,
        render: (manufacturer: string) =>
          !!manufacturer ? (
            <Tooltip title={manufacturer} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {manufacturer}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No manufacturer.
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          a.brand?.manufacturer?.name.localeCompare(
            b.brand?.manufacturer?.name
          ),
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        width: 100,
        render: (size: number) =>
          !!size ? (
            <Tooltip title={size} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{size}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No size.
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
          const aSize = `${a.size}`?.split(' ')?.[0];
          const bSize = `${b.size}`?.split(' ')?.[0];
          if (aSize && bSize) {
            return Number(aSize) - Number(bSize);
          } else if (aSize) {
            return 1;
          } else if (bSize) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        width: 100,
        render: (count: string) =>
          !!count ? (
            <Tooltip title={count} mouseEnterDelay={1}>
              <Text style={{ maxWidth: '150px' }}>{count}</Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No count.
            </Text>
          ),
        defaultSortOrder: 'descend',
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
          // For product items that are null/undefined
          const aCount = a.count?.split(' ')?.[0];
          const bCount = b.count?.split(' ')?.[0];
          if (aCount && bCount) {
            return Number(aCount) - Number(bCount);
          } else if (aCount) {
            return 1;
          } else if (bCount) {
            return -1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Modeled Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 150,
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
          new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime(),
      },
      {
        title: 'Actions',
        dataIndex: 'data',
        key: 'actions',
        width: 100,
        render: (_, productItem: ListProductLibraryModel) => {
          const isLoading = loadingItems[productItem.uuid];
          return (
            <Space direction="horizontal" size="small">
              {isLoading ? (
                <LoadingOutlined />
              ) : productItem.in_list === 1 ? (
                <Tooltip title="Remove from Product List">
                  <Button
                    className="link-button"
                    type="link"
                    onClick={() => handleRemoveSingleProduct(productItem)}
                  >
                    Remove
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Add to Product List">
                  <Button
                    className="link-button"
                    type="link"
                    onClick={() => handleAddSingleProduct(productItem)}
                  >
                    Add
                  </Button>
                </Tooltip>
              )}
            </Space>
          );
        },
      },
    ],
    [loadingItems, handleAddSingleProduct, handleRemoveSingleProduct]
  );

  return viewType === ProductLibraryListViewType.Grid ? (
    <ProductLibraryGridList
      buildFilterParams={buildFilterParams}
      loading={loading}
      productLibrary={productLibrary}
      hasMore={hasMore}
    />
  ) : (
    <ProductLibraryTable
      dataSource={productLibrary}
      headerTitle="Recently Added"
      columns={columns}
      tableHeight="calc(100vh - 250px)"
      buildFilterParams={buildFilterParams}
    />
  );
};

export default memo(ProductLibraryItemsList, propsAreEqual);
