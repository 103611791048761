import { store } from '../../store';

export type ProductType = 'upc' | 'ean' | 'rs' | 'customer' | 'uuid';
export type ProductPrimary = '0' | '1';
export type ProductStatus =
  | 'approved'
  | 'needs_approval'
  | 'rejected'
  | 'unknown';

export type ProductModelStatus =
  | 'active'
  | 'inactive'
  | 'deleted'
  | 'uploaded'
  | 'processing'
  | 'failed'
  | 'finished';

export enum ProductMarketEnum {
  'United States' = 66,
  'Canada' = 230,
}

export const isAllMarketsView = (marketId: number): boolean => {
  return marketId === 74; // all markets
};

export const getAllMarketIds = (): number[] => {
  const state = store.getState();
  const markets = state.customFields.customField?.options || [];

  return markets
    .filter((market) => market.id !== 74 && market.name !== 'Undefined')
    .map((market) => market.id!)
    .filter((id): id is number => id !== undefined);
};

export enum ProductFormatEnum {
  Bag = 111,
  Bar,
  Bottle,
  Box,
  Can,
  Canister,
  Carton,
  Clamshell,
  Cup,
  Envelope,
  Fixture,
  Jar,
  Merchandise,
  Novelty,
  Pouch,
  'Price Tag' = 126,
  RFT,
  Shrink,
  Sign,
  Signage,
  Tray,
  Tub,
  Tube,
  Undefined,
  Wrapper,
}

export enum ProductStatusEnum {
  approved = 'approved',
  needs_approval = 'needs_approval',
  rejected = 'rejected',
  unknown = 'unknown',
}

export interface ProductCategoryModel {
  count: number;
  created_at: string | null;
  created_by: string | null;
  deleted_at: string | null;
  display_name: string;
  name: string;
  key: string;
  level: number;
  parent_id: string;
  status: string;
  updated_at: string | null;
  uuid: string;
}

export interface ProductCustomValueModel {
  attached_id: number;
  attached_type: string | null;
  created_at: string | null;
  created_by: string | null;
  custom_field_id: number;
  id: number;
  updated_at: string;
  value: string | null;
  value_float: null;
  value_int: null;
  value_string: string | null;
}

export interface ProductBrandModel {
  key?: string;
  name: string;
  manufacturer: ProductsManufacturerModel;
  manufacturer_id: string;
  status: ProductStatus;
  created_at: string;
  updated_at: string;
  uuid: string;
  fields?: ProductFieldsModel;
  customValues?: ProductCustomValueModel[];
}

export interface ProductsManufacturerModel {
  name: string;
  status: ProductStatus;
  created_at: string;
  updated_at: string;
  active: number;
  parent_id: string | null;
  short_name?: string;
  uuid: string;
}

export interface ProductIdentifierModel {
  id: string;
  item_id: number;
  identifier: string;
  type: ProductType;
  primary: ProductPrimary;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface ProductTagModel {
  name: string;
  value: string;
  set: number;
  tagid: number;
}

export interface ProductFieldOptionModel {
  id: number;
  custom_field_id: number;
  name: string;
  option_key: string;
  created_at: string;
  updated_at: string;
  visible: number;
  status: string;
  parent_id: string;
  deleted_at: string;
  media_files: [];
}

export interface ProductFieldsModel {
  field_id: number;
  field_key: string;
  value: string | number;
  name: string;
  format: number;
  options: ProductFieldOptionModel[];
}

export interface ProductMediaFileModel {
  status: 'active' | 'uploading' | 'processing';
  filename: string;
  type: string;
  // eslint-disable-next-line prettier/prettier
  metadata: { _extension: string };
  created_by: string;
  updated_at: string;
  created_at: string;
  media_version_id: string;
  storage_path: string;
  content_type: string;
  size: number;
  media_url: string;
  id: string;
}

export type DrcType = {
  item_id: string;
  drc_item_id: string;
  drc_name: string;
};
export interface ListProductLibraryModel {
  [key: string]: any;
  name: string;
  created_at: Date;
  updated_at: Date;
  active: number;
  created_by: string;
  customValues: ProductCustomValueModel[];
  brand: ProductBrandModel;
  brand_name: string;
  brand_id: string;
  categories: ProductCategoryModel[];
  status: ProductStatus;
  deleted_at: Date;
  uuid: string;
  image_url: string;
  identifiers: ProductIdentifierModel[];
  tags: ProductTagModel[];
  fields: ProductFieldsModel[];
  private: boolean;
  flex: boolean;
  upc: string;
  old_drc: boolean;
  drc: DrcType[] | [];
  old_flex: boolean;
  old_private: boolean;
  manufacturer: string;
  in_list: number | null;
  size: string;
  count: string;
  media_files?: ProductMediaFileModel[];
}
