import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../../../../hooks/useAuth';
import useDebounce from '../../../../../hooks/useDebounce';
import { ManufacturerModel } from '../../../../../models/manufacturer';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateManufacturers,
  searchManufacturersByName,
} from '../../../../../store/features/manufacturers/manufacturerSlice';
import { UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';
import SearchBar from '../../../../elements/SearchBar/SearchBar';
import { RoutePath } from '../../../AppRoot/types';

const { Text } = Typography;

const ManufacturersTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { hasPermission } = useAuth();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    value: manufacturers,
    fetchingManufacturers,
    searchingManufacturers,
    count,
    currentPage,
    pageSize,
  } = useAppSelector((state) => state.manufacturer);

  const hasNoSearchResultsFound = useMemo(
    () =>
      !fetchingManufacturers && !!searchQuery && manufacturers?.length === 0,
    [fetchingManufacturers, searchQuery, manufacturers?.length]
  );

  const hasManufacturers = !(
    !fetchingManufacturers && manufacturers?.length === 0
  );

  const columns: ColumnsType<ManufacturerModel> = useMemo(
    () => getColumns(history, hasPermission),
    [history, hasPermission]
  );

  useEffect(() => {
    dispatch(
      searchManufacturersByName({
        query: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      dispatch(
        paginateManufacturers({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      );
    },
    [debouncedSearchQuery, dispatch]
  );

  const paginationSettings = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current: currentPage,
    pageSize: pageSize,
  };

  return (
    <div>
      <SearchBar
        searchQuery={searchQuery}
        updateSearchQuery={updateSearchQuery}
        isSearching={searchingManufacturers}
        canCreate={hasPermission(UserPermissions.MetaDataCreate)}
        createLink={DrawerHashRoute.ManufacturerForm}
        createButtonText="Add New Manufacturer"
        placeholder="Search Manufacturers"
      />
      {hasManufacturers && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={manufacturers}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={paginationSettings}
          loading={fetchingManufacturers || searchingManufacturers}
          expandable={{
            expandIcon: ({ expandable, expanded, onExpand, record }) =>
              expandable ? (
                <span className="anticon " onClick={(e) => onExpand(record, e)}>
                  {expanded ? (
                    <DownOutlined
                      style={{
                        fontSize: '10px',
                        marginRight: '18px',
                      }}
                    />
                  ) : (
                    <RightOutlined
                      style={{ fontSize: '10px', marginRight: '18px' }}
                    />
                  )}
                </span>
              ) : null,
            expandedRowRender: (record) => (
              <div style={{ margin: 0 }}>
                <Text>Brand Associations:</Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '8px',
                  }}
                >
                  {record.brands
                    ? record.brands.map((brand) => (
                        <Link
                          key={brand.uuid}
                          to={{
                            pathname: RoutePath.ProductLibrary,
                            state: { brand: brand },
                          }}
                        >
                          <Button
                            className="link-button"
                            type="link"
                            style={{ padding: '4px 0' }}
                          >
                            {brand.name}
                          </Button>
                        </Link>
                      ))
                    : null}
                </div>
              </div>
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
        />
      )}
    </div>
  );
};

const getColumns = (
  history: any,
  hasPermission: (permission: UserPermissions | UserPermissions[]) => boolean
): ColumnsType<ManufacturerModel> => [
  {
    title: 'Manufacturer',
    dataIndex: 'name',
    width: '35%',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
  },
  {
    title: '# in Processing',
    dataIndex: 'in_processing',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.in_processing - b.in_processing,
  },
  {
    title: '# in Library',
    dataIndex: 'in_library',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.in_library - b.in_library,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.status && b.status) {
        return a.status > b.status ? 1 : -1;
      } else {
        return 0;
      }
    },
    render: (status: string) => {
      if (status === 'approved') {
        return <Text>{status}</Text>;
      } else {
        return <Text type="danger">Needs Approval</Text>;
      }
    },
  },
  {
    title: 'Actions',
    width: 100,
    render: (manufacturer: ManufacturerModel) => (
      <Space direction="horizontal" size="middle">
        {hasPermission(UserPermissions.MetaDataEdit) && (
          <Button
            type="link"
            className="link-button"
            onClick={() =>
              history.push({
                hash: DrawerHashRoute.ManufacturerForm,
                state: { data: manufacturer },
              })
            }
          >
            Edit
          </Button>
        )}
        <Button
          className="link-button"
          type="link"
          onClick={() =>
            history.push({
              state: { manufacturer },
              pathname: RoutePath.ProductLibrary,
            })
          }
        >
          View in Library
        </Button>
      </Space>
    ),
  },
];

export default ManufacturersTab;
