import { Image } from 'antd';
import heroImageLarge from '../../../../../assets/images/product-library-hero-image-large.png';
import heroImage from '../../../../../assets/images/product-library-hero-image.png';
import { isTabletScreenSizeOrSmaller } from '../../../../../util';

const HeroImage = () => {
  return (
    <div style={{ width: '100%' }}>
      <Image
        preview={false}
        width={'100%'}
        placeholder
        alt="brand hero banner"
        src={isTabletScreenSizeOrSmaller() ? heroImage : heroImageLarge}
        style={{
          minHeight: '200px',
          objectFit: 'fill',
          borderRadius: '6px',
        }}
      />
    </div>
  );
};

export default HeroImage;
