import { FC, memo, useCallback, useEffect, useMemo } from 'react';

import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { useAuth } from '../../../../hooks/useAuth';
import { BrandModel, initialNewBrandModel } from '../../../../models/brand';
import { ManufacturerModel } from '../../../../models/manufacturer';
import { ProductStatusEnum } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createBrand,
  deleteBrand,
  updateBrand,
} from '../../../../store/features/brand/brandSlice';
import { searchManufacturersByName } from '../../../../store/features/manufacturers/manufacturerSlice';
import { propsAreEqual, UserPermissions } from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';
import { BrandFormLocationState } from './types';

import './BrandForm.less';

const { Option } = Select;

interface BrandFormProps {
  history: History;
  location: Location<BrandFormLocationState>;
}

interface BrandProps {
  name: string;
  status: string;
  uuid: string;
  in_library: number;
  in_processing: number;
  manufacturer_set?: string[] | null;
  manufacturer_delete?: string[] | null;
}

const BrandForm: FC<BrandFormProps> = (props) => {
  const { history, location } = props;

  const dispatch = useAppDispatch();
  const { deletingBrand, savingBrand } = useAppSelector((state) => state.brand);
  const { value: manufacturersSelectOptions } = useAppSelector(
    (state) => state.manufacturer
  );
  const { hasPermission } = useAuth();

  const [form] = Form.useForm();
  const isNewBrand = useMemo(() => !location.state, [location.state]);
  const { in_library, in_processing, in_use, manufacturers } = {
    ...location?.state?.data,
  };
  const { data: brand } = { ...location?.state };

  const fields = useMemo(
    () => Object.keys(isNewBrand ? initialNewBrandModel : location.state?.data),
    [isNewBrand, location.state?.data]
  );

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const handleDeleteBrand = useCallback(
    async (brand: BrandModel) => {
      message.loading(
        {
          content: 'Deleting brand...',
          key: 'deleting-brand-message',
        },
        0
      );

      const res: any = await dispatch(
        deleteBrand({
          brandId: brand.uuid,
        })
      );
      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-brand-message');
        message.success(`Brand '${brand.name}' deleted.`);
        history.goBack();
      }
    },
    [dispatch, onError, history]
  );

  const handleConfirmDeleteBrand = useCallback(
    (e: MouseEvent, brand: BrandModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the brand "${brand.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the Brand "${brand.name}" after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteBrand(brand),
      });
    },
    [handleDeleteBrand]
  );

  const saveBrand = useCallback(
    async (brand: BrandModel) => {
      console.log('brand ', brand);
      const { name, statusCheckValue, manufacturer_set } = brand;
      let status: string;
      const manufacturer_delete: string[] = [];

      let newBrand: BrandProps = {
        name: '',
        status: '',
        uuid: '',
        in_library: 0,
        in_processing: 0,
        manufacturer_set: [],
        manufacturer_delete: [],
      };

      if (manufacturers) {
        manufacturers.map((manufacturer) => {
          const item = manufacturer_set?.find(
            (uuid) => manufacturer.uuid === uuid
          );
          if (!item) {
            manufacturer_delete.push(manufacturer.uuid);
          }
        });
      }

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newBrand = {
        name,
        status,
        uuid: location.state?.data.uuid,
        in_library: location.state?.data.in_library,
        in_processing: location.state?.data.in_processing,
        manufacturer_set: manufacturer_set && manufacturer_set,
        manufacturer_delete: manufacturer_delete && manufacturer_delete,
      };

      const res: any = isNewBrand
        ? await dispatch(createBrand({ brand: newBrand }))
        : await dispatch(
            updateBrand({
              brandId: location.state?.data.uuid,
              brand: newBrand,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Brand saved.');
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewBrand,
      location.state?.data,
      manufacturers,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: BrandModel) => {
      form.validateFields(fields).then(() => saveBrand(values));
    },
    [fields, form, saveBrand]
  );

  const getInitialValues = () => {
    if (isNewBrand) {
      return { ...initialNewBrandModel };
    }

    const { manufacturers, status } = location.state.data;
    return {
      ...location.state.data,
      manufacturer_set: manufacturers?.map((m) => m.name),
      statusCheckValue: status === ProductStatusEnum.approved,
    };
  };

  useEffect(() => {
    dispatch(
      searchManufacturersByName({
        query: '',
      })
    );
  }, [dispatch]);

  const handleSearch = (query: string) => {
    dispatch(
      searchManufacturersByName({
        query,
      })
    );
  };

  return (
    <FormWrapper
      title={`${isNewBrand ? 'Create new' : 'Edit'} brand`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Brand"
          name="name"
          rules={[{ required: true, message: 'Brand name is required.' }]}
        >
          <Input data-cy="brand-form-input" placeholder="Brand name" />
        </Form.Item>
        <Form.List name="manufacturer_set">
          {(fields, { add, remove }, { errors }) => (
            <div>
              {fields.map((field) => (
                <Form.Item required={false} key={field.key}>
                  <div className="form-item-manufacturers-wrapper">
                    <Form.Item label="Select Manufacturer" {...field}>
                      <Select
                        placeholder="Select a manufacturer"
                        allowClear
                        showSearch
                        onSearch={handleSearch}
                        filterOption={false}
                      >
                        {manufacturersSelectOptions?.map(
                          (manufacturer: ManufacturerModel) => (
                            <Option value={manufacturer.uuid}>
                              {manufacturer.name}
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  className="link-button"
                  type="link"
                  onClick={() => add()}
                  disabled={!hasPermission(UserPermissions.MetaDataCreate)}
                >
                  Add another manufacturer
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </div>
          )}
        </Form.List>

        <Form.Item label="Status">
          <div className="brand-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue" valuePropName="checked">
              <Switch
                disabled={!hasPermission(UserPermissions.MetaDataApprove)}
              />
            </Form.Item>
          </div>
        </Form.Item>

        {!isNewBrand ? (
          <Form.Item label="Details">
            <div className="brand-form-details">
              <Typography>Number of models in library</Typography>
              <Typography>{in_library}</Typography>
            </div>
            <div className="brand-form-details">
              <Typography>
                Number of models in processing / master librarian list
              </Typography>
              <Typography>{in_processing}</Typography>
            </div>
            <Button
              className="link-button"
              type="link"
              onClick={() => {
                history.push({
                  state: { brand },
                  pathname: RoutePath.ProductLibrary,
                });
              }}
            >
              View in Library
            </Button>
          </Form.Item>
        ) : null}
        <Form.Item>
          {isNewBrand ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingBrand}
                data-cy="brand-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={!hasPermission(UserPermissions.MetaDataCreate)}
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!in_use ? (
                <Button
                  loading={deletingBrand}
                  danger
                  type="primary"
                  onClick={(e: any) => handleConfirmDeleteBrand(e, brand)}
                  disabled={!hasPermission(UserPermissions.MetaDataDelete)}
                >
                  Delete
                </Button>
              ) : null}
              <Button
                type="primary"
                loading={savingBrand}
                htmlType="submit"
                disabled={!hasPermission(UserPermissions.MetaDataEdit)}
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(BrandForm, propsAreEqual));
