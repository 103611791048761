import { FC, memo, useCallback, useMemo } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Switch,
  Typography,
} from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { useAuth } from '../../../../hooks/useAuth';
import { ProductStatusEnum } from '../../../../models/product-library';
import {
  initialNewSizeUnitModel,
  SizeUnitModel,
} from '../../../../models/size-unit';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createCustomFieldOption,
  deleteCustomFieldOption,
  fetchSizeUnits,
  updateCustomFieldOption,
} from '../../../../store/features/metaData/metaDataSlice';
import { propsAreEqual, UserPermissions } from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';
import './SizeUnitForm.less';
import { SizeUnitFormLocationState } from './types';

interface SizeUnitFormProps {
  history: History;
  location: Location<SizeUnitFormLocationState>;
}

interface SizeUnitProps {
  name: string;
  status: string;
  in_library: number;
  in_processing: number;
}

const SizeUnitForm: FC<SizeUnitFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const { hasPermission } = useAuth();
  const { deletingCustomFieldOption, savingCustomFieldOption } = useAppSelector(
    (state) => state.metaData
  );

  const [form] = Form.useForm();
  const isNewSizeUnit = useMemo(() => !location.state, [location.state]);
  const { in_library, in_processing, in_use } = {
    ...location?.state?.data,
  };
  const { data: sizeUnit } = { ...location?.state };

  const fields = useMemo(
    () =>
      Object.keys(
        isNewSizeUnit ? initialNewSizeUnitModel : location.state?.data
      ),
    [isNewSizeUnit, location.state?.data]
  );

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const handleDeleteSizeUnit = useCallback(
    async (sizeUnit: SizeUnitModel) => {
      message.loading(
        {
          content: 'Deleting size unit...',
          key: 'deleting-size-unit-message',
        },
        0
      );

      const res: any = await dispatch(
        deleteCustomFieldOption({
          customFieldKey: 'size_unit',
          optionId: location.state?.data.id,
        })
      );
      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-size-unit-message');
        await dispatch(fetchSizeUnits());
        message.success(`Size unit '${sizeUnit.name}' deleted.`);
        history.goBack();
      }
    },
    [dispatch, location.state?.data.id, onError, history]
  );

  const handleConfirmDeleteSizeUnit = useCallback(
    (e: MouseEvent, sizeUnit: SizeUnitModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the size unit "${sizeUnit.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the Size Unit "${sizeUnit.name}" after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteSizeUnit(sizeUnit),
      });
    },
    [handleDeleteSizeUnit]
  );

  const saveSizeUnit = useCallback(
    async (sizeUnit: SizeUnitModel) => {
      const { name, statusCheckValue } = sizeUnit;

      let status: string;
      let newSizeUnit: SizeUnitProps = {
        name: '',
        status: '',
        in_library: 0,
        in_processing: 0,
      };

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newSizeUnit = {
        name,
        status,
        in_library: location.state?.data.in_library,
        in_processing: location.state?.data.in_processing,
      };

      const res: any = isNewSizeUnit
        ? await dispatch(
            createCustomFieldOption({
              customFieldKey: 'size_unit',
              customFieldOption: newSizeUnit,
            })
          )
        : await dispatch(
            updateCustomFieldOption({
              customFieldKey: location.state?.data
                .custom_field_id as unknown as string,
              optionId: location.state?.data.id,
              customFieldOption: newSizeUnit,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Size Unit saved.');
        await dispatch(fetchSizeUnits());
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewSizeUnit,
      location.state?.data.custom_field_id,
      location.state?.data.id,
      location.state?.data.in_library,
      location.state?.data.in_processing,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: SizeUnitModel) => {
      form.validateFields(fields).then(() => saveSizeUnit(values));
    },
    [fields, form, saveSizeUnit]
  );

  const getInitialValues = () => {
    if (isNewSizeUnit) {
      return { ...initialNewSizeUnitModel };
    }
    const { status } = location.state.data;
    return {
      ...location.state.data,
      statusCheckValue: status === ProductStatusEnum.approved,
    };
  };

  return (
    <FormWrapper
      title={`${isNewSizeUnit ? 'Create new' : 'Edit'} size unit`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Size Unit of Measurement"
          name="name"
          rules={[
            {
              required: true,
              message: 'Size Unit of Measurement name is required.',
            },
          ]}
        >
          <Input
            data-cy="size-unit-form-input"
            placeholder="Size Unit of Measurement name"
          />
        </Form.Item>
        <Form.Item label="Status">
          <div className="size-unit-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue" valuePropName="checked">
              <Switch
                disabled={!hasPermission(UserPermissions.MetaDataApprove)}
              />
            </Form.Item>
          </div>
        </Form.Item>
        {!isNewSizeUnit ? (
          <Form.Item label="Details">
            <div className="size-unit-form-details">
              <Typography>Number of models in library</Typography>
              <Typography>{in_library}</Typography>
            </div>
            <div className="size-unit-form-details">
              <Typography>
                Number of models in processing / master librarian list
              </Typography>
              <Typography>{in_processing}</Typography>
            </div>
          </Form.Item>
        ) : null}
        <Form.Item>
          {isNewSizeUnit ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingCustomFieldOption}
                data-cy="size-unit-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={!hasPermission(UserPermissions.MetaDataCreate)}
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!in_use ? (
                <Button
                  danger
                  loading={deletingCustomFieldOption}
                  type="primary"
                  onClick={(e: any) =>
                    handleConfirmDeleteSizeUnit(e, sizeUnit as SizeUnitModel)
                  }
                  disabled={!hasPermission(UserPermissions.MetaDataDelete)}
                >
                  Delete
                </Button>
              ) : null}
              <Button
                loading={savingCustomFieldOption}
                type="primary"
                htmlType="submit"
                disabled={!hasPermission(UserPermissions.MetaDataEdit)}
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(SizeUnitForm, propsAreEqual));
